// DynamicForm.js
import React, { useState } from 'react';
import {
  VStack,
  Select,
  Input,
  Textarea,
  Box,
  Grid,
  GridItem,
  FormLabel
} from '@chakra-ui/react';

const Step1 = ({biodata, formData, setFormData}) => {
 
  const stateLocalGovernments = {
    Abia: [
      "Aba North",
      "Aba South",
      "Arochukwu",
      "Bende",
      "Ikwuano",
      "Isiala Ngwa North",
      "Isiala Ngwa South",
      "Isuikwuato",
      "Obi Ngwa",
      "Ohafia",
      "Osisioma Ngwa",
      "Ugwunagbo",
      "Ukwa East",
      "Ukwa West",
      "Umuahia North",
      "Umuahia South",
      "Umu Nneochi",
    ],
    Adamawa: [
      "Demsa",
      "Fufore",
      "Ganye",
      "Girei",
      "Gombi",
      "Guyuk",
      "Hong",
      "Jada",
      "Lamurde",
      "Madagali",
      "Maiha",
      "Mayo-Belwa",
      "Michika",
      "Mubi North",
      "Mubi South",
      "Numan",
      "Shelleng",
      "Song",
      "Toungo",
      "Yola North",
      "Yola South",
    ],
    AkwaIbom: [
      "Abak",
      "Eastern Obolo",
      "Eket",
      "Esit Eket",
      "Essien Udim",
      "Etim Ekpo",
      "Etinan",
      "Ibeno",
      "Ibesikpo Asutan",
      "Ibiono Ibom",
      "Ika",
      "Ikono",
      "Ikot Abasi",
      "Ikot Ekpene",
      "Ini",
      "Itu",
      "Mbo",
      "Mkpat Enin",
      "Nsit Atai",
      "Nsit Ibom",
      "Nsit Ubium",
      "Obot Akara",
      "Okobo",
      "Onna",
      "Oron",
      "Oruk Anam",
      "Udung Uko",
      "Ukanafun",
      "Uruan",
      "Urue-Offong/Oruko",
      "Uyo",
    ],
    Anambra: [
      "Aguata",
      "Anambra East",
      "Anambra West",
      "Anaocha",
      "Awka North",
      "Awka South",
      "Ayamelum",
      "Dunukofia",
      "Ekwusigo",
      "Idemili North",
      "Idemili South",
      "Ihiala",
      "Njikoka",
      "Nnewi North",
      "Nnewi South",
      "Ogbaru",
      "Onitsha North",
      "Onitsha South",
      "Orumba North",
      "Orumba South",
      "Oyi",
    ],
    Bauchi: [
      "Alkaleri",
      "Bauchi",
      "Bogoro",
      "Damban",
      "Darazo",
      "Dass",
      "Gamawa",
      "Ganjuwa",
      "Giade",
      "Itas/Gadau",
      "Jama'are",
      "Katagum",
      "Kirfi",
      "Misau",
      "Ningi",
      "Shira",
      "Tafawa Balewa",
      "Toro",
      "Warji",
      "Zaki",
    ],
    Bayelsa: [
      "Brass",
      "Ekeremor",
      "Kolokuma/Opokuma",
      "Nembe",
      "Ogbia",
      "Sagbama",
      "Southern Ijaw",
      "Yenagoa",
    ],
    Benue: [
      "Ado",
      "Agatu",
      "Apa",
      "Buruku",
      "Gboko",
      "Guma",
      "Gwer East",
      "Gwer West",
      "Katsina-Ala",
      "Konshisha",
      "Kwande",
      "Logo",
      "Makurdi",
      "Obi",
      "Ogbadibo",
      "Ohimini",
      "Oju",
      "Okpokwu",
      "Otukpo",
      "Tarka",
      "Ukum",
      "Ushongo",
      "Vandeikya",
    ],
    Borno: [
      "Abadam",
      "Askira/Uba",
      "Bama",
      "Bayo",
      "Biu",
      "Chibok",
      "Damboa",
      "Dikwa",
      "Gubio",
      "Guzamala",
      "Gwoza",
      "Hawul",
      "Jere",
      "Kaga",
      "Kala/Balge",
      "Konduga",
      "Kukawa",
      "Kwaya Kusar",
      "Mafa",
      "Magumeri",
      "Maiduguri",
      "Marte",
      "Mobbar",
      "Monguno",
      "Ngala",
      "Nganzai",
      "Shani",
    ],
    CrossRiver: [
      "Abi",
      "Akamkpa",
      "Akpabuyo",
      "Bakassi",
      "Bekwarra",
      "Biase",
      "Boki",
      "Calabar Municipal",
      "Calabar South",
      "Etung",
      "Ikom",
      "Obanliku",
      "Obubra",
      "Obudu",
      "Odukpani",
      "Ogoja",
      "Yakurr",
      "Yala",
    ],
    Delta: [
      "Aniocha North",
      "Aniocha South",
      "Bomadi",
      "Burutu",
      "Ethiope East",
      "Ethiope West",
      "Ika North East",
      "Ika South",
      "Isoko North",
      "Isoko South",
      "Ndokwa East",
      "Ndokwa West",
      "Okpe",
      "Oshimili North",
      "Oshimili South",
      "Patani",
      "Sapele",
      "Udu",
      "Ughelli North",
      "Ughelli South",
      "Ukwuani",
      "Uvwie",
      "Warri North",
      "Warri South",
      "Warri South West",
    ],
    Ebonyi: [
      "Abakaliki",
      "Afikpo North",
      "Afikpo South",
      "Ebonyi",
      "Ezza North",
      "Ezza South",
      "Ikwo",
      "Ishielu",
      "Ivo",
      "Izzi",
      "Ohaozara",
      "Ohaukwu",
      "Onicha",
    ],
    Edo: [
      "Akoko-Edo",
      "Egor",
      "Esan Central",
      "Esan North-East",
      "Esan South-East",
      "Esan West",
      "Etsako Central",
      "Etsako East",
      "Etsako West",
      "Igueben",
      "Ikpoba Okha",
      "Oredo",
      "Orhionmwon",
      "Ovia North-East",
      "Ovia South-West",
      "Owan East",
      "Owan West",
      "Uhunmwonde",
    ],
    Ekiti: [
      "Ado Ekiti",
      "Efon",
      "Ekiti East",
      "Ekiti South-West",
      "Ekiti West",
      "Emure",
      "Gbonyin",
      "Ido Osi",
      "Ijero",
      "Ikere",
      "Ikole",
      "Ilejemeje",
      "Irepodun/Ifelodun",
      "Ise/Orun",
      "Moba",
      "Oye",
    ],
    Enugu: [
      "Aninri",
      "Awgu",
      "Enugu East",
      "Enugu North",
      "Enugu South",
      "Ezeagu",
      "Igbo Etiti",
      "Igbo Eze North",
      "Igbo Eze South",
      "Isi Uzo",
      "Nkanu East",
      "Nkanu West",
      "Nsukka",
      "Oji River",
      "Udenu",
      "Udi",
      "Uzo-Uwani",
    ],
    Gombe: [
      "Akko",
      "Balanga",
      "Billiri",
      "Dukku",
      "Funakaye",
      "Gombe",
      "Kaltungo",
      "Kwami",
      "Nafada",
      "Shongom",
      "Yamaltu/Deba",
    ],
    Imo: [
      "Aboh Mbaise",
      "Ahiazu Mbaise",
      "Ehime Mbano",
      "Ezinihitte",
      "Ideato North",
      "Ideato South",
      "Ihitte/Uboma",
      "Ikeduru",
      "Isiala Mbano",
      "Isu",
      "Mbaitoli",
      "Ngor Okpala",
      "Njaba",
      "Nkwerre",
      "Nwangele",
      "Obowo",
      "Oguta",
      "Ohaji/Egbema",
      "Okigwe",
      "Orlu",
      "Orsu",
      "Oru East",
      "Oru West",
      "Owerri Municipal",
      "Owerri North",
      "Owerri West",
    ],
    Jigawa: [
      "Auyo",
      "Babura",
      "Biriniwa",
      "Birnin Kudu",
      "Buji",
      "Dutse",
      "Gagarawa",
      "Garki",
      "Gumel",
      "Guri",
      "Gwaram",
      "Gwiwa",
      "Hadejia",
      "Jahun",
      "Kafin Hausa",
      "Kaugama",
      "Kazaure",
      "Kiri Kasama",
      "Kiyawa",
      "Maigatari",
      "Malam Madori",
      "Miga",
      "Ringim",
      "Roni",
      "Sule Tankarkar",
      "Taura",
      "Yankwashi",
    ],
    Kaduna: [
      "Birnin Gwari",
      "Chikun",
      "Giwa",
      "Igabi",
      "Ikara",
      "Jaba",
      "Jema'a",
      "Kachia",
      "Kaduna North",
      "Kaduna South",
      "Kagarko",
      "Kajuru",
      "Kaura",
      "Kauru",
      "Kubau",
      "Kudan",
      "Lere",
      "Makarfi",
      "Sabon Gari",
      "Sanga",
      "Soba",
      "Zangon Kataf",
      "Zaria",
    ],
    Kano: [
      "Ajingi",
      "Albasu",
      "Bagwai",
      "Bebeji",
      "Bichi",
      "Bunkure",
      "Dala",
      "Dambatta",
      "Dawakin Kudu",
      "Dawakin Tofa",
      "Doguwa",
      "Fagge",
      "Gabasawa",
      "Garko",
      "Garun Mallam",
      "Gaya",
      "Gezawa",
      "Gwale",
      "Gwarzo",
      "Kabo",
      "Kano Municipal",
      "Karaye",
      "Kibiya",
      "Kiru",
      "Kumbotso",
      "Kunchi",
      "Kura",
      "Madobi",
      "Makoda",
      "Minjibir",
      "Nasarawa",
      "Rano",
      "Rimin Gado",
      "Rogo",
      "Shanono",
      "Sumaila",
      "Takai",
      "Tarauni",
      "Tofa",
      "Tsanyawa",
      "Tudun Wada",
      "Ungogo",
      "Warawa",
      "Wudil"
    ],
    Katsina: [
      "Bakori",
      "Batagarawa",
      "Batsari",
      "Baure",
      "Bindawa",
      "Charanchi",
      "Dan Musa",
      "Dandume",
      "Danja",
      "Daura",
      "Dutsi",
      "Dutsin Ma",
      "Faskari",
      "Funtua",
      "Ingawa",
      "Jibia",
      "Kafur",
      "Kaita",
      "Kankara",
      "Kankia",
      "Katsina",
      "Kurfi",
      "Kusada",
      "Mai'Adua",
      "Malumfashi",
      "Mani",
      "Mashi",
      "Matazu",
      "Musawa",
      "Rimi",
      "Sabuwa",
      "Safana",
      "Sandamu",
      "Zango"
    ],
    Kebbi: [
      "Aleiro",
      "Arewa Dandi",
      "Argungu",
      "Augie",
      "Bagudo",
      "Birnin Kebbi",
      "Bunza",
      "Dandi",
      "Fakai",
      "Gwandu",
      "Jega",
      "Kalgo",
      "Koko Besse",
      "Maiyama",
      "Ngaski",
      "Sakaba",
      "Shanga",
      "Suru",
      "Wasagu Danko",
      "Yauri",
      "Zuru"
    ],
    Kogi: [
      "Adavi",
      "Ajaokuta",
      "Ankpa",
      "Bassa",
      "Dekina",
      "Ibaji",
      "Idah",
      "Igalamela Odolu",
      "Ijumu",
      "Kabba Bunu",
      "Kogi",
      "Lokoja",
      "Mopa Muro",
      "Ofu",
      "Ogori Magongo",
      "Okehi",
      "Okene",
      "Olamaboro",
      "Omala",
      "Yagba East",
      "Yagba West"
    ],
    Kwara: [
      "Asa",
      "Baruten",
      "Edu",
      "Ekiti",
      "Ifelodun",
      "Ilorin East",
      "Ilorin South",
      "Ilorin West",
      "Irepodun",
      "Isin",
      "Kaiama",
      "Moro",
      "Offa",
      "Oke Ero",
      "Oyun",
      "Pategi"
    ],
    Lagos: [
      "Agege",
      "Ajeromi Ifelodun",
      "Alimosho",
      "Amuwo Odofin",
      "Apapa",
      "Badagry",
      "Epe",
      "Eti Osa",
      "Ibeju Lekki",
      "Ifako Ijaiye",
      "Ikeja",
      "Ikorodu",
      "Kosofe",
      "Lagos Island",
      "Lagos Mainland",
      "Mushin",
      "Ojo",
      "Oshodi Isolo",
      "Shomolu",
      "Surulere"
    ],
    Nasarawa: [
      "Akwanga",
      "Awe",
      "Doma",
      "Karu",
      "Keana",
      "Keffi",
      "Kokona",
      "Lafia",
      "Nasarawa",
      "Nasarawa Egon",
      "Obi",
      "Toto",
      "Wamba"
    ],
    Niger: [
      "Agaie",
      "Agwara",
      "Bida",
      "Borgu",
      "Bosso",
      "Chanchaga",
      "Edati",
      "Gbako",
      "Gurara",
      "Katcha",
      "Kontagora",
      "Lapai",
      "Lavun",
      "Magama",
      "Mariga",
      "Mashegu",
      "Mokwa",
      "Moya",
      "Paikoro",
      "Rafi",
      "Rijau",
      "Shiroro",
      "Suleja",
      "Tafa",
      "Wushishi"
    ],
    Ogun: [
      "Abeokuta North",
      "Abeokuta South",
      "Ado Odo Ota",
      "Egbado North",
      "Egbado South",
      "Ewekoro",
      "Ifo",
      "Ijebu East",
      "Ijebu North",
      "Ijebu North East",
      "Ijebu Ode",
      "Ikenne",
      "Imeko Afon",
      "Ipokia",
      "Obafemi Owode",
      "Odeda",
      "Odogbolu",
      "Ogun Waterside",
      "Remo North",
      "Shagamu"
    ],
    Ondo: [
      "Akoko North East",
      "Akoko North West",
      "Akoko South Akure East",
      "Akoko South West",
      "Akure North",
      "Akure South",
      "Ese Odo",
      "Idanre",
      "Ifedore",
      "Ilaje",
      "Ile Oluji Okeigbo",
      "Irele",
      "Odigbo",
      "Okitipupa",
      "Ondo East",
      "Ondo West",
      "Ose",
      "Owo"
    ],
    Osun: [
      "Aiyedade",
      "Aiyedire",
      "Atakumosa East",
      "Atakumosa West",
      "Boluwaduro",
      "Boripe",
      "Ede North",
      "Ede South",
      "Egbedore",
      "Ejigbo",
      "Ife Central",
      "Ife East",
      "Ife North",
      "Ife South",
      "Ifedayo",
      "Ifelodun",
      "Ila",
      "Ilesa East",
      "Ilesa West",
      "Irepodun",
      "Irewole",
      "Isokan",
      "Iwo",
      "Obokun",
      "Odo Otin",
      "Ola Oluwa",
      "Olorunda",
      "Oriade",
      "Orolu",
      "Osogbo"
    ],
    Oyo: [
      "Afijio",
      "Akinyele",
      "Atiba",
      "Atigbo",
      "Egbeda",
      "Ibadan Central",
      "Ibadan North East",
      "Ibadan North West",
      "Ibadan South East",
      "Ibadan South West",
      "Ibarapa Central",
      "Ibarapa East",
      "Ibarapa North",
      "Ido",
      "Irepo",
      "Iseyin",
      "Itesiwaju",
      "Iwajowa",
      "Kajola",
      "Lagelu",
      "Ogbomosho North",
      "Ogbomosho South",
      "Ogo Oluwa",
      "Olorunsogo",
      "Oluyole",
      "Ona Ara",
      "Orelope",
      "Ori Ire",
      "Oyo East",
      "Oyo West",
      "Saki East",
      "Saki West"
    ],
    Plateau: [
      "Barkin Ladi",
      "Bassa",
      "Bokkos",
      "Jos East",
      "Jos North",
      "Jos South",
      "Kanam",
      "Kanke",
      "Langtang North",
      "Langtang South",
      "Mangu",
      "Mikang",
      "Pankshin",
      "Qua'an Pan",
      "Riyom",
      "Shendam",
      "Wase"
    ],
    Rivers: [
      "Abua Odual",
      "Ahoada East",
      "Ahoada West",
      "Akuku Toru",
      "Andoni",
      "Asari Toru",
      "Bonny",
      "Degema",
      "Eleme",
      "Emohua",
      "Etche",
      "Gokana",
      "Ikwerre",
      "Khana",
      "Obio Akpor",
      "Ogba Egbema Ndoni",
      "Ogu Bolo",
      "Okrika",
      "Omuma",
      "Opobo Nkoro",
      "Oyigbo",
      "Port Harcourt",
      "Tai"
    ],
    Sokoto: [
      "Binji",
      "Bodinga",
      "Dange Shuni",
      "Gada",
      "Goronyo",
      "Gudu",
      "Gwadabawa",
      "Illela",
      "Isa",
      "Kebbe",
      "Kware",
      "Rabah",
      "Sabon Birni",
      "Shagari",
      "Silame",
      "Sokoto North",
      "Sokoto South",
      "Tambuwal",
      "Tangaza",
      "Tureta",
      "Wamako",
      "Wurno",
      "Yabo"
    ],
    Taraba: [
      "Ardo Kola",
      "Bali",
      "Donga",
      "Gashaka",
      "Gassol",
      "Ibi",
      "Jalingo",
      "Karim Lamido",
      "Kurmi",
      "Lau",
      "Sardauna",
      "Takum",
      "Ussa",
      "Wukari",
      "Yorro",
      "Zing"
    ],
    Yobe: [
      "Bade",
      "Bursari",
      "Damaturu",
      "Fika",
      "Fune",
      "Geidam",
      "Gujba",
      "Gulani",
      "Jakusko",
      "Karasuwa",
      "Machina",
      "Nangere",
      "Nguru",
      "Potiskum",
      "Tarmuwa",
      "Yunusari",
      "Yusufari"
    ],
    Zamfara: [
      "Anka",
      "Bakura",
      "Birnin Magaji Kiyaw",
      "Bukkuyum",
      "Bungudu",
      "Gummi",
      "Gusau",
      "Kaura Namoda",
      "Maradun",
      "Maru",
      "Shinkafi",
      "Talata Mafara",
      "Zurmi"
    ],
    "Federal Capital Territory": ["Abaji", "Abuja Municipal", "Bwari", "Gwagwalada", "Kuje", "Kwali"]
  }  
  

  const handleInputChange = (field, value) => {
    setFormData(prevData => ({ ...prevData, [field]: value }));
  };


  return (
    <VStack align="start" spacing={4} minWidth={"100%"}>
        <Grid
        templateColumns={['1fr', '1fr 1fr']} // One column on mobile, two columns on desktop
        gap={4}
        width="100%"
        style={{fontFamily: 'Kumbh Sans', fontSize: '12px !important', fontWeight: 500
          }}
      >
      <GridItem>
        <FormLabel color="#374151" fontSize={13}
        > Title <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
      <Select
        value={biodata?.title || formData?.title}
        fontSize={13}
        onChange={(e) => handleInputChange('title', e.target.value)}
        required={true}
      >
        <option value="" disabled>Select Title</option>
        <option value=""></option>
        <option value="Mr">Mr</option>
        <option value="Mrs">Mrs</option>
        <option value="Miss">Miss</option>
      </Select>
      </GridItem>
      <GridItem>
      <FormLabel color="#374151"  fontSize={13}> Last Name <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
      <Input
        value={biodata?.lastName || formData?.lastName}
        fontSize={13}
        required={true}
        onChange={(e) => handleInputChange('lastName', e.target.value)}
      />
      </GridItem>
      <GridItem>
      <FormLabel color="#374151"  fontSize={13}> First Name <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
      <Input
        value={biodata?.firstName || formData?.firstName}
        fontSize={13}
        onChange={(e) => handleInputChange('firstName', e.target.value)}
      />
      </GridItem>
      <GridItem>
      <FormLabel color="#374151"  fontSize={13}> Other Names </FormLabel>
      <Input
        // placeholder="Middle Name"
        fontSize={13}
        value={biodata?.otherName || formData?.otherName}
        onChange={(e) => handleInputChange('otherName', e.target.value)}
      />
      </GridItem>
      <GridItem>
      <FormLabel color="#374151"
       fontSize={13}
       > Maiden Name </FormLabel>
      <Input
        // placeholder="Middle Name"
        fontSize={13}
        value={biodata?.maidenName || formData?.maidenName}
        onChange={(e) => handleInputChange('otherName', e.target.value)}
      />
      </GridItem>
      <GridItem>
      <FormLabel color="#374151"
       fontSize={13}
       > Marital Status <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
      <Select
        value={biodata?.maritalStatus ||formData?.maritalStatus}
        fontSize={13}
        onChange={(e) => handleInputChange('maritalStatus', e.target.value)}
      >
         <option value="" disabled>Select Status</option>
         <option></option>
        <option value="Single">Single</option>
        <option value="Married">Married</option>
        <option value="Divorced">Divorced</option>
        <option value="Widowed">Widowed</option>
      </Select>
      </GridItem>
      <GridItem>
      <FormLabel color="#374151"
       fontSize={13}
       > Date of Birth <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
      <Input
        type="date"
        fontSize={13}
        placeholder="Date of Birth"
        value={biodata?.dateOfBirth || formData?.dateOfBirth}
        onChange={(e) => handleInputChange('dateOfBirth', e.target.value)}
      />
      </GridItem>
      <GridItem>
      <FormLabel color="#374151"
       fontSize={13}
       > Nationality <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
      <Select
        // placeholder="Nationality"
        fontSize={13}
        
        value={biodata?.nationality || formData?.nationality}
        onChange={(e) => handleInputChange('nationality', e.target.value)}
      >
        <option value="" disabled>Select Nationality</option>
        <option></option>
         <option value="Nigerian">Nigerian</option>
         <option value="Other">Other</option>
      </Select>
      </GridItem>
      <GridItem>
      <FormLabel color="#374151"
       fontSize={13}
       > State of Origin<Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
      <Select
      fontSize={13}
      
      value={biodata?.state || formData?.state}
      onChange={(e) => handleInputChange('state', e.target.value)}
    >
      <option></option>
      <option value="" disabled>Select State</option>
      <option value="Abia">Abia</option>
      <option value="Adamawa">Adamawa</option>
      <option value="Akwa Ibom">Akwa Ibom</option>
      <option value="Anambra">Anambra</option>
      <option value="Bauchi">Bauchi</option>
      <option value="Bayelsa">Bayelsa</option>
      <option value="Benue">Benue</option>
      <option value="Borno">Borno</option>
      <option value="Cross River">Cross River</option>
      <option value="Delta">Delta</option>
      <option value="Ebonyi">Ebonyi</option>
      <option value="Edo">Edo</option>
      <option value="Ekiti">Ekiti</option>
      <option value="Enugu">Enugu</option>
      <option value="Gombe">Gombe</option>
      <option value="Imo">Imo</option>
      <option value="Jigawa">Jigawa</option>
      <option value="Kaduna">Kaduna</option>
      <option value="Kano">Kano</option>
      <option value="Katsina">Katsina</option>
      <option value="Kebbi">Kebbi</option>
      <option value="Kogi">Kogi</option>
      <option value="Kwara">Kwara</option>
      <option value="Lagos">Lagos</option>
      <option value="Nasarawa">Nasarawa</option>
      <option value="Niger">Niger</option>
      <option value="Ogun">Ogun</option>
      <option value="Ondo">Ondo</option>
      <option value="Osun">Osun</option>
      <option value="Oyo">Oyo</option>
      <option value="Plateau">Plateau</option>
      <option value="Rivers">Rivers</option>
      <option value="Sokoto">Sokoto</option>
      <option value="Taraba">Taraba</option>
      <option value="Yobe">Yobe</option>
      <option value="Zamfara">Zamfara</option>
      <option value="FCT">Federal Capital Territory (FCT)</option>
      <option value="Other">Other</option>
    </Select>

    </GridItem>
    <GridItem>
      <FormLabel color="#374151"
       fontSize={13}
       > Local Government of Origin <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
      <Select
       fontSize={13}
       
       value={biodata?.localGovernmentArea || formData?.localGovernmentArea}
        onChange={(e) => handleInputChange('localGovernmentArea', e.target.value)}
      >
         <option value="" disabled>Select Local Government Area</option>
          <option></option>
          {/* Iterate over states and their local government areas */}
          {Object.entries(stateLocalGovernments).map(([state, localGovernments]) => (
            <optgroup label={state} key={state}>
              {localGovernments.map((lg) => (
                <option value={lg} key={lg}>
                  {lg}
                </option>
              ))}
            </optgroup>
          ))}

          {/* Option for 'Other' if the local government is not listed */}
          <option value="Other">Other</option>
      </Select>
    </GridItem>
    <GridItem colSpan={[1, 2]}>
      <FormLabel color="#374151"
       fontSize={13}
       width={"100%"}
       > Permanent home address <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
      <Input
       fontSize={13}
        value={biodata?.permanentHomeAddress || formData.permanentHomeAddress}
        onChange={(e) => handleInputChange('permanentHomeAddress', e.target.value)}
      />
      </GridItem>
          {/* <br /> */}
    <GridItem>
      <FormLabel color="#374151"
       fontSize={13}
       > Telephone <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
        <Input
        type={'number'}
         fontSize={13}
          value={biodata?.telephone || formData?.telephone}
          onChange={(e) => handleInputChange('telephone', e.target.value)}
        />
      </GridItem>

      <GridItem>
      <FormLabel color="#374151"
       fontSize={13}
       > Mobile <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
      <Input
      type={'number'}
        fontSize={13}
        value={biodata?.mobile || formData?.mobile}
        onChange={(e) => handleInputChange('mobile', e.target.value)}
      />
      </GridItem>
      <GridItem>
      <FormLabel color="#374151"
       fontSize={13}
       > Email <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
      <Input
      type="email"
        fontSize={13}
        value={biodata?.emailAddress || formData?.emailAddress}
        onChange={(e) => handleInputChange('emailAddress', e.target.value)}
      />
      </GridItem>
<GridItem>
      <FormLabel color="#374151"
       fontSize={13}
       > Religion <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
     <Select
        value={biodata?.religion || formData?.religion}
        fontSize={13}
        onChange={(e) => handleInputChange('religion', e.target.value)}
      >
        <option></option>
        <option value="Islam">Islam</option>
        <option value="Christianity">Christianity</option>
        <option value="Traditional">Traditional</option>
        <option value="Others">Traditional</option>
      </Select>

      </GridItem>
      <GridItem>
      <FormLabel color="#374151"
       fontSize={13}
       > Next of Kin <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
        <Input
          fontSize={13}
          value={biodata?.nextOfKin || formData?.nextOfKin}
          onChange={(e) => handleInputChange('nextOfKin', e.target.value)}
        />
      </GridItem>
      <GridItem>
      <FormLabel color="#374151"
       fontSize={13}
       > Phone Number of Next of Kin <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
        <Input
        type={"number"}
         fontSize={13}
          value={biodata?.nextOfKinPhone || formData?.nextOfKinPhone}
          onChange={(e) => handleInputChange('nextOfKinPhone', e.target.value)}
        />
        </GridItem>
        <GridItem colSpan={[1, 2]}> 
      <FormLabel color="#374151"
       fontSize={13}
       > Address of Next of Kin <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
        <Input
          fontSize={13}
          value={biodata?.nextOfKinAddress || formData.nextOfKinAddress}
          onChange={(e) => handleInputChange('nextOfKinAddress', e.target.value)}
        />
      </GridItem>
      <GridItem>
      <FormLabel color="#374151"
       fontSize={13}
       > Name of Sponsor <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
        <Input
         fontSize={13}
        value={biodata?.sponsorName || formData?.sponsorName}
        onChange={(e) => handleInputChange('sponsorName', e.target.value)}
      />
      </GridItem>
      <GridItem>
      <FormLabel color="#374151"
       fontSize={13}
       > Phone Number of Sponsor <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
        <Input
        type="number"
         fontSize={13}
        value={biodata?.sponsorPhone || formData?.sponsorPhone}
        onChange={(e) => handleInputChange('sponsorPhone', e.target.value)}
      />
      </GridItem>
      <GridItem colSpan={[1,2]}>
      <FormLabel color="#374151"
       fontSize={13}
       > Address of Sponsor <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
        <Input
         fontSize={13}
        value={biodata?.sponsorAddress || formData?.sponsorAddress}
        onChange={(e) => handleInputChange('sponsorAddress', e.target.value)}
      />
      </GridItem>


</Grid>
    </VStack>
  );
};

export default Step1;
