import React, { useState, useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Text,
  Td,
  Button,
  Box,
  Badge,
  useMediaQuery,
  Input,
  Grid,
  Flex,
  Spinner
} from '@chakra-ui/react';
import Pagination from './Pagination';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CSVLink } from 'react-csv';
import PDFRenderer from './pdfRenderer';
import { FaEllipsisH} from 'react-icons/fa';

const DataTable = ({ data, headers, buttons, page, onPageChange, pageSize, totalItems, loading, search, exports, pagination }) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const isTablet = useMediaQuery("(max-width: 1023px)")[0];
  
  const [clickedItem, setClickedItem] = useState(null);
  const [visibleMenuRowId, setVisibleMenuRowId] = useState(null);

  const showMenu = (item) => {
    setClickedItem(item);
    setVisibleMenuRowId(item?._id);
  };

  const hideMenu = () => {
    setClickedItem(null);
    setVisibleMenuRowId(null);
  };

  const handleOpen = (item) => {
    setClickedItem(item);
    // setIsOpen(true);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  console.log(pageSize)
 
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState();
  
  // Update filteredData when searchQuery changes
  useEffect(() => {
    const lowercasedQuery = searchQuery?.toLowerCase();
    const filtered = data?.filter(item =>
      headers.some(header =>
        item[header.key]?.toString()?.toLowerCase()?.includes(lowercasedQuery)
      )
    );
    setFilteredData(filtered);
    // console.log(headers)
  }, [searchQuery, data, headers]);


  // Outside your component
const getNestedPropertyValue = (obj, path) => {
  const keys = path.split('.');
  let value = obj;
  
  for (const key of keys) {  
        value = value[key];
        if (value === undefined) {
          return ''; // Or handle the case when a nested property is missing
        }
  }
  
  return value;
};
  const tableStyles = {
    width: '100%',
    marginBottom: '1rem',
    overflowX: 'auto',
    color: '#6c757d',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    fontSize: ".825rem",
  };

  const thStyles = {
    // backgroundColor: '#f7f7f9',
    fontWeight: 'bold',
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #dee2e6',
  };

  const tdStyles = {
    padding: '8px',
    borderBottom: '1px solid #dee2e6',
  };
  // console.log(filteredData)
  return (
    <Box overflowX="auto"  bg={'#fff'} color={'#6c757d'} 
    py={5}
    px={3}
    > 
      <Grid templateColumns={isMobile ? "1fr" : exports == true && search == true ? "repeat(2, 1fr)": "1fr"}>
        {(exports == true) && (
        <Flex>
        <Button mx={1} key="export-pdf" size={isMobile ? 'xs' : 'sm'} colorScheme="blue"
          style={{
            display: "inline-block",
            fontWeight: 400,
            backgroundColor: "#6c757d",
            color: "white",
            textAlign: "center",
            verticalAlign: "middle",
            userSelect: "none",
            padding: ".25rem .7rem",
            fontSize: "11px",
            lineHeight: "1.625",
            borderRadius: ".2rem",
            transition: "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out"
          }}
        >
          <PDFDownloadLink
            document={<PDFRenderer data={filteredData || []} headers={headers} />}
            fileName="table.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? 'Loading document...' : 'PDF'
            }
          </PDFDownloadLink>
        </Button>

      <Button
      key="print"
      size={isMobile ? 'xs' : 'sm'}
      onClick={() => window.print()}
      style={{
        display: "inline-block",
        fontWeight: 400,
        backgroundColor: "#6c757d",
        color: "white",
        textAlign: "center",
        verticalAlign: "middle",
        userSelect: "none",
        padding: ".25rem .7rem",
        fontSize: "11px",
        lineHeight: "1.625",
        borderRadius: ".2rem",
        transition: "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out"
      }}
    >
      Print
    </Button>


      <Button mx={1} key="export-csv" size={isMobile ? 'xs' : 'sm'} style={{
            display: "inline-block",
            fontWeight: 400,
            backgroundColor: "#6c757d",
            color: "white",
            textAlign: "center",
            verticalAlign: "middle",
            userSelect: "none",
            padding: ".25rem .7rem",
            fontSize: "11px",
            lineHeight: "1.625",
            borderRadius: ".2rem",
            transition: "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out"
          }}>
        <CSVLink data={filteredData || []} headers={headers} filename="table.csv">
          CSV
        </CSVLink>
      </Button>
        </Flex>)}
        {(search == true) && (
          <Flex justifyContent="flex-end" >
          <Input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            width={"70%"}
            // border={'1px solid #6c757d'}
            onChange={(e) => setSearchQuery(e.target.value)}
            mb={1}
            bg={"#F6F6FE"}
            // width={200}
            fontSize={12}
            color={'#6c757d'}
          _placeholder={{
            color: '#6c757d' // Change this to the color you want
          }}
        />
   
          </Flex>)}
      </Grid>
      <Table variant="simple" 
      style={tableStyles} 
      mt={((exports == true) || (search == true)) ? 5: 0}
      width={'100%'}
      >
        <Thead fontSize={11} color={"gray.500"}>
          <Tr>
            {headers.map((header, index) => (
              <Th key={index} style={thStyles}>
                {header.label.toUpperCase()}
              </Th>
            ))}
            {buttons.length != 0 && (
            <Th style={thStyles}>ACTIONS</Th>)}
          </Tr>
        </Thead>
        <Tbody fontSize={11}>
          { loading ? <Spinner size="md" /> : (filteredData) ? (
            filteredData.map((item, rowIndex) => (
              <Tr key={item?._id} bg={rowIndex % 2 === 0 ? "#f4f7f9" : "white"}>

                {headers.map((header, index) => (
                  <Td key={index} style={tdStyles}>
                    {Array.isArray(item[header.key]) ? (
                      item[header.key].map((value, index) => (
                        <div key={index}>
                          {getNestedPropertyValue({ [header.key]: value, type1: item?.type1, type2: item?.type2, type3: item?.type3 })}
                        </div>
                      ))
                    ) : (
                      header.key === 'status' ? (
                        <Badge
                          borderRadius={5}
                          colorScheme={
                            item[header.key]?.toLowerCase() === header?.type1?.toLowerCase() ? 'green' :
                            item[header.key]?.toLowerCase() === header?.type2?.toLowerCase() ? 'indigo' : 
                            item[header.key]?.toLowerCase() === header?.type3?.toLowerCase() ? 'gray' : 
                            item[header.key]?.toLowerCase() === header?.type4?.toLowerCase() ? 'red' : 
                            ''
                          }
                        >
                          {item[header.key]}
                        </Badge>
                      )  : header.key === 'approval' ? (
                        <>
                        { item[header.key] == false ? <span> N </span> : <span>Y</span>}
                        </>
                      )  : header.key === 'featured' ? (
                        <>
                        { item[header.key] == false ? <span> N </span> : <span>Y</span>}
                        </>
                      )  : header.key === 'publish' ? (
                        <>
                        { item[header.key] == false ? <span> N </span> : <span>Y</span>}
                        </>
                      )  : header.key === 'createdAt' ? (
                    // Format date
                    formatDate(item[header.key])
                  ) : (
                        getNestedPropertyValue(item, header.key)
                      )
                    )}
                  </Td>
                ))}
                {buttons.length !== 0 && (
                <Td style={tdStyles} textAlign={'center'} justifyContent={'center'}>
                  <FaEllipsisH
                    textAlign={'center'}
                    key={item?._id}
                    display={'flex'}
                    justifyself={'center'}
                    fontSize="12px"
                    cursor={'pointer'}
                    onClick={() => showMenu(item)} // Pass the clicked item to showMenu
                  />

                  <Box
                    bg="white"
                    borderRadius={10}
                    justifyContent='center'
                    display={visibleMenuRowId === item?._id ? 'block' : 'none'}
                    onMouseLeave={hideMenu} // Hide menu when mouse leaves the Box
                    px={4}
                    py={2}
                    border="1px solid rgb(222, 226, 230)"
                    alignItems={'center'}
                    position={'absolute'}
                  >
                    {buttons.map((button, buttonIndex) => (
                      item[button.fieldToCompare] === button.valueToCompare ? (
                        <Box
                          key={item?._id + buttonIndex}
                          mt={2}
                          fontSize=".825rem"
                          bg="transparent"
                          cursor={'pointer'}
                          color={button.color}
                          onClick={() => { button.onClick(clickedItem); hideMenu(); }}
                        >
                          {button.label}
                        </Box>
                      ) : !item[button.fieldToCompare] ? (
                        <Box
                          key={item?._id + buttonIndex}
                          mt={2}
                          fontSize=".825rem"
                          cursor={'pointer'}
                          bg="transparent"
                          color={button.color}
                          onClick={() => { button.onClick(clickedItem); hideMenu(); }}
                          mr={2}
                        >
                          {button.label}
                        </Box>
                      ) : null
                    ))}
                  </Box>
                </Td>
              )}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={headers.length + 1} style={tdStyles}>
                No data available.
              </Td>
            </Tr>
          )}
        </Tbody>

      </Table>
     {(pagination == true) && (<>
      <Flex justifyContent="flex-start" fontSize={12}>Page {page || 1} to {pageSize || 1} of {totalItems || 0} entries</Flex>
      <Flex justifyContent="flex-end">
      <Pagination currentPage={page} totalPages={pageSize} onPageChange={onPageChange} />
      </Flex></>)}

     
    </Box>
  );
};

export default DataTable;
