import React, { useState, useEffect } from 'react';
import {
  Heading,
  Box,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import DataTable from '../components/DataTable';
import AddSchoolFeesModal from '../components/AddSchoolFeesModal';
import useFetch from '../hooks/useFetch';
import DashboardLayout from '../components/DashboardLayout';
import { useSelector } from 'react-redux';
const AddSchoolFeesPage = () => {
  const configs = useSelector((state)=>state.auth.config)
  const { data: schoolFeesData, loading, error, fetchData } = useFetch('Auth API');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dynamicPath, setDynamicPath] = useState('school-fees');
 
  
  
  const headers = [
    { label: 'Amount', key: 'amount' },
    { label: 'Session', key: 'session.name' },
    { label: 'Programme', key: 'programme.name' },
    { label: 'Department', key: 'department.name' },
    { label: 'Level', key: 'level.name' }
  ];

  
  useEffect(() => {
    fetchData(dynamicPath);
  }, [dynamicPath]); // Only run the effect when dynamicPath changes
  
  

  // Example function for handling the edit action
  const handleEdit = (item) => {
    console.log('Edit:', item);
  };

  // Example function for handling the delete action
  const handleDelete = (item) => {
    console.log('Delete:', item);
  };

  const buttons = [
    { label: 'Edit', onClick: handleEdit },
   
  ];
  
  console.log(schoolFeesData?.data)
  return (
    <DashboardLayout sidebar={"two"}>
    <Box borderRadius={11} px={2} py={5} width={["120%", '100%' ]}>
  <Box 
    bg="white" 
    borderRadius={11} 
    mt={2} 
    p={5}  
    width={['200','100%']}
    height={['100%', '80vh']}
    // display={'flex'}
  >
      <Heading mb={4} fontFamily="Kumbh Sans" fontSize={14} >School Fees Management</Heading>

      <Box mb={4} display="flex">
        <Button bg={configs?.primaryBg} 
        fontFamily="Kumbh Sans" fontSize={13} 
        justifyContent={'flex-end'}
        color={configs?.primaryColor} onClick={onOpen}>
          Add School Fees
        </Button>
      </Box>

      <AddSchoolFeesModal isOpen={isOpen} onClose={onClose} />

      <DataTable
        data={schoolFeesData?.data}
        headers={headers}
        buttons={buttons}
        loading={loading}
        page={schoolFeesData?.currentPage}
        onPageChange={(newPage) =>  setDynamicPath(`school-fees/${newPage}`)}
        pageSize={schoolFeesData?.totalPages}
        totalItems={schoolFeesData?.totalItems}
        search={false}
        exports={false}
        pagination={true}
      />
    </Box>
    </Box>
    </DashboardLayout>
  );
};

export default AddSchoolFeesPage;
