// StudentImage.js
import React, { useState } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import ImageUpload from '../components/ImageUpload';
import useFormSubmission from '../hooks/useFormSubmission'; // Update the path

const StudentImage = () => {
  const [formData, setFormData] = useState(null);
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API"); // Update the API name

  const handleFormSubmit = async () => {
    try {
      // Assuming you want to submit the formData to the specified API
      const submissionResult = await submitForm("upload", formData, "multipart");

      if (submissionResult.submitSuccess) {
        // Handle success, e.g., show a success message
        console.log("Form submitted successfully");
      } else {
        // Handle submission failure, e.g., show an error message
        console.error("Form submission failed");
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("Error submitting form:", error.message);
    }
  };

  return (
    <DashboardLayout sidebar={"two"}>
      <Box borderRadius={11} px={2} py={5} width={["120%", '100%' ]}>
        <Box
          bg="white"
          borderRadius={11}
          mt={2}
          p={10}
          width={['200','100%']}
          height={'100%'}
          // display={'flex'}
          alignItems="center" justifyContent={'center'} textAlign={'center'}
        >
          <ImageUpload setFormData={setFormData} />
          <Text
         display={'inline-flex'}
        fontSize={13}
        color="red"
        alignItems="center" justifyContent={'center'} textAlign={'center'}>
           {submitError && <div>{submitError}</div>}</Text>
          <Box 
         display={'flex'}
        
        alignItems="center" justifyContent={'center'} textAlign={'center'}>
          <Button 
              border="1px solid #D1D5DB" 
              bg="white" _hover={{bg: "white"}} 
              color="gray.700" 
              mt={5}
              
              onClick={handleFormSubmit} disabled={isSubmitting}
              >
              Update
          </Button>
         
        </Box>
        </Box>
        
      </Box>
    </DashboardLayout>
  );
};

export default StudentImage;
