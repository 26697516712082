import { useState } from "react";
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, UnorderedList, ListItem } from "@chakra-ui/react";

function DynamicAccordion({data}) {
  const [openSection, setOpenSection] = useState(null);

  const handleAccordionClick = (index) => {
    setOpenSection((prev) => (prev === index ? null : index));
  };


  return (
    <Accordion>
      {data?.map((item, index) => (
        <AccordionItem key={index} isExpanded={openSection === index}>
          <h2>
            <AccordionButton onClick={() => handleAccordionClick(index)}>
              <AccordionIcon />
              {item?.name}
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <UnorderedList>
              {item?.availableCourses?.map((course, courseIndex) => (
                <ListItem key={courseIndex}>{course?.name}</ListItem>
              ))}
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

export default DynamicAccordion;
