import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Stack, Text, Checkbox, VStack } from '@chakra-ui/react';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { useNavigate, useLocation, useParams } from 'react-router-dom'; // Import useParams
import DashboardLayout from '../components/DashboardLayout';
import Step1 from '../components/Step1';
import Step2 from '../components/Step2';
import Step3 from '../components/Step3';
import Step4 from '../components/Step4';
import useFormSubmission from '../hooks/useFormSubmission';
import { Link } from 'react-router-dom';
import RemitaCheckOut from './Remita';
import useFetch from '../hooks/useFetch';
const steps = ['biodata', 'education', 'uploads', 'review', 'payment'];
const totalSteps = steps.length;

const Application = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const { data: application, loading, error, fetchData } = useFetch('Auth API');
  const user = useSelector((state)=> state.auth.user)
   const navigate = useNavigate();
  const location = useLocation();
  const { page, id } = useParams(); // Use useParams to get the page and id
  const [formData, setFormData] = useState({ id: id});
  const config = useSelector((state)=> state?.auth?.config);  
  
const handleCheckboxChange = (key) => {
  setFormData((prevData) => ({ ...prevData, [key]: !prevData[key] }));
};

  useEffect(() => {
    const currentPath = window.location.pathname;
  
    const currentStepIndex = steps.findIndex((step) => currentPath.includes(step));

    if (currentStepIndex !== -1) {
      setCurrentStep(currentStepIndex);
    }
    console.log(currentStep);
  }, [location.pathname]);

  const handleNext = async() => {
    
   
   if  (steps[currentStep] == "review"){
      setFormData((prevFormData) => ({
        ...prevFormData,
        status: "Reviewed",
        _id: id
      }));
   }
   
    const submissionResult = await submitForm(`applications/${steps[currentStep]}`, formData, steps[currentStep] == "uploads" ? "multipart" : "json");
    
    if (submissionResult.submitSuccess) {
      const nextStep = Math.min(currentStep + 1, totalSteps - 1);
      setCurrentStep(nextStep);
      updateUrl(steps[nextStep]);
    } else {
      
      if (submitError && steps[currentStep] == "uploads" && application?.data?.upload?.length > 0){
        const nextStep = Math.min(currentStep + 1, totalSteps - 1);
        setCurrentStep(nextStep);
        updateUrl(steps[nextStep]);
      }
      console.error('Form submission failed:', submitError);
    }
  };

  const handlePrev = () => {
    const prevStep = Math.max(currentStep - 1, 0);
    setCurrentStep(prevStep);
    updateUrl(steps[prevStep]);
  };

  const updateUrl = (step) => {
    const newPath = `/application/${step}/${id}`;
    navigate(newPath);
  };
  const goBack = () => {
    // Go back one step in the browser history
    window.history.back();
  };
  useEffect(() => {
    fetchData('/applications/details/'+id);
    
  }, [application]);
  return (
    <DashboardLayout sidebar="one">
      <Box bg="white" borderRadius={11} px={10} py={5} width={"100%"} height={"100%"}
       style={{fontFamily: 'Kumbh Sans', fontSize: '12px !important', fontWeight: 500
      }}
      >
        <Link onClick={goBack} className="hidden-section">Back</Link>
        <Box align="start" spacing={1} p={8}>
          
          <Box>
            {/* Render the form for the current step */}
            {currentStep === 0 && <Step1 biodata={application?.data?.biodata} formData={formData} setFormData={setFormData} />}
            {currentStep === 1 && <Step2 education={application?.data?.education} formData={formData} setFormData={setFormData} />}
            {currentStep === 2 && <Step3 upload={application?.data?.upload} formData={formData} setFormData={setFormData} id={id}/>}
            {currentStep === 3 && <Step4 id={id}  setFormData={setFormData} />}
            {currentStep === totalSteps - 1 && <Box px={8}>
              <RemitaCheckOut id={id} type="Application Fee"/>
          </Box>}
          </Box>
          { (user?.role?.name == "Applicant" && currentStep == 3) && (
             <Box p={8} display={'flex'} flexDirection={'column'}>
             <Checkbox
               isChecked={formData.consentChecked}
               onChange={() => handleCheckboxChange('consentChecked')}
               fontSize={12}
             >
               I consent to the processing of my data.
             </Checkbox>
       
             <Checkbox
               isChecked={formData.termsChecked}
               onChange={() => handleCheckboxChange('termsChecked')}
               
               fontSize={12}
             >
               I accept the terms and conditions.
             </Checkbox>
             
             </Box>
          )}
          { user?.role?.name == 'Applicant' && (
          <Stack direction="row" spacing={4} justifyContent="flex-end" mt={8} >
            <Text fontSize={13} color="red">{ (currentStep === 0 || currentStep === 1) &&(submitError)}</Text>
         {((currentStep !== 0) && (currentStep !== totalSteps -1)) && (
            <Button
              leftIcon={<FiChevronLeft />}
              bg={config?.primaryBg}
              fontSize={14}
              color={"white"}
              onClick={handlePrev}
              isDisabled={currentStep === 0}
            >
              Previous
            </Button>
         )}
            { currentStep !== totalSteps - 1 && (
            <Button
              rightIcon={<FiChevronRight />}
              bg={config?.primaryBg}
              fontSize={14}
              color={"white"}
              onClick={handleNext}
              // isDisabled={currentStep === totalSteps - 1}
            >
              {currentStep === totalSteps - 2  ? 'Proceed' : 'Next' }
            </Button>)}
          </Stack>)
          
          }
        </Box>
      </Box>
    </DashboardLayout>
  );
};



export default Application;
