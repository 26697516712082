import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  Modal,
  Grid,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import DataTable from '../components/DataTable';
import useFormSubmission from '../hooks/useFormSubmission';
import useFetch from '../hooks/useFetch';
import SearchableSelect from '../components/SearchableSelect';
import { useSelector } from 'react-redux';
const AvailableCourse = () => {
  const configs = useSelector((state)=> state.auth.config)
  const [isAddCourseModalOpen, setIsAddCourseModalOpen] = useState(false);
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const [isEditCourseModalOpen, setIsEditCourseModalOpen] = useState(false); 
  const { data: courses, loading, error, fetchData } = useFetch();
  const { data: coursesAvailable, loading: loadingCourses, error: errorCourses, fetchData: fetchAvailable } = useFetch();
  const { data: semester, loading: loadingSemester, error: errorSemester, fetchData: fetchSemester} = useFetch();
  const { data: programme, loading: loadingProgramme, error: errorProgramme, fetchData: fetchProgramme} = useFetch();
  const { data: department, loading: loadingDepartment, error: errorDepartment, fetchData: fetchDepartment} = useFetch();
  const { data: level, loading: loadingLevel, error: errorLevel, fetchData: fetchLevel} = useFetch();
  const { data: staff, loading: loadingStaff, error: errorStaff, fetchData: fetchStaff} = useFetch();

  const [message, setMessage] = useState();
  const [selectedCourse, setSelectedCourse] = useState();
  const [dynamicPath, setDynamicPath] = useState('courses/available-courses');
  const dynamicParams = "";
  const [formData, setFormData] = useState({
    courseId: '',    
    semesterId: '',
    departmentId: '',
    programmeId: '',
    levelId: '',
    unit: '',
    status: '',
    lecturerId: ''
  });

  const handleAddCourse = async () => {
    const { submitSuccess, responseBody } = await submitForm('courses/available-courses', formData);
    if (submitSuccess) {
      setMessage(responseBody.message);
      setFormData({
        courseId: '',    
        semesterId: '',
        departmentId: '',
        programmeId: '',
        levelId: '',
        unit: '',
        status: '',
        lecturerId: ''
      })
      setIsAddCourseModalOpen(false)
      setMessage("")


    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMessage("")
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleEditCourse = (id) => {
    // Fetch the details of the selected course by its ID and set the editFormData state.
      setIsEditCourseModalOpen(true);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [id]: id,
      }));
  };
  const handleUpdateCourse = async () => {
    // Call your API to update the course details based on editFormData.
    // You can use a similar approach as handleAddCourse.
    const { submitSuccess, responseBody } = await submitForm(`courses/available-courses/${selectedCourse}`, formData);
    if (submitSuccess) {
      setFormData({
        courseId: '',    
        semesterId: '',
        departmentId: '',
        programmeId: '',
        levelId: '',
        unit: '',
        status: '',
        lecturerId: ''
      })
      setMessage(responseBody.message);
      setIsEditCourseModalOpen(false);
    }
  };
  useEffect(() => {
    fetchAvailable(dynamicPath);
    fetchData('courses/all');
    fetchSemester('semesters/all');
    fetchLevel('levels/all');
    fetchProgramme('programmes/all');
    fetchDepartment('courses/major/all');
    fetchStaff('users/staffs');
  }, [dynamicPath]);
  
  const headers = [
    { label: 'Title', key: 'courseId.name' },
    { label: 'Course Code', key: 'courseId.code' },
    { label: 'Semester', key: 'semesterId.name' },
    { label: 'Programme', key: 'programmeId.name' },
    { label: 'Department', key: 'departmentId.name' },
    { label: 'Level', key: 'levelId.name' },
    { label: 'Unit', key: 'unit' },
    { label: 'Lecturer', key: 'lecturerId.firstName' },
    { label: 'Status', key: 'status' }
  ];

  const buttons = [
    {
      label: 'Edit',
      onClick: (id) => handleEditCourse(id),
      color: 'darkblue'
    },
  ];

    return (
    <DashboardLayout sidebar="one">
      <Box borderRadius={11} px={2} py={5} width={['120%', '100%']}>
        <Box
          bg="white"
          borderRadius={11}
          
          p={10}
          width={['200', '100%']}
          height={['100%', '100%']}
        >
          <Text>Make Course Available</Text>

          <Box
            display={'flex'}
            justifyContent="right"
          >
            <Button
              bg={`${configs?.primaryBg}`}
              color={`${configs?.primaryColor}`}
              mt={5}
              _hover={{ bg: '#1E40AF' }}
              fontSize={12}
              onClick={() => setIsAddCourseModalOpen(true)}
            >
              Change Course
            </Button>
          </Box>
          <Box >
            <DataTable
              data={coursesAvailable?.data || []}
              headers={headers}
              buttons={buttons}
              page={coursesAvailable?.currentPage}
              onPageChange={(newPage) => setDynamicPath(`/courses/available-courses/${newPage}`)}
              pageSize={coursesAvailable?.totalPages}
              totalItems={coursesAvailable?.totalItems}
              loading={loadingCourses}
              search={true}
              exports={true}
              pagination={true}
            />
          </Box>
        </Box>
      </Box>

      <Modal isOpen={isAddCourseModalOpen} onClose={() => setIsAddCourseModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={14}>Set Course</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Grid
              templateColumns={{ base: '1fr', md: '1fr 1fr' }}
              gap={6}
            >
             <FormControl  >
               <FormLabel fontSize={13} >Course </FormLabel>
              <SearchableSelect
                name="courseId"
                placeholder="Select Course"
                data={courses || []}
                onChange={(e) => handleInputChange(e)}
              />
            </FormControl>

             <FormControl  >
               <FormLabel fontSize={13} >Semester</FormLabel>
              <Select
                name="semesterId"
                onChange={(e) => handleInputChange(e)}
                fontSize={13}
            >
                <option value="">Select option</option>
                {Array.isArray(semester) && semester?.map((item) => (
                <option key={item.id} value={item?._id}>
                    {item.name} 
                </option>
                ))}
            </Select>
            </FormControl>
             <FormControl  >
               <FormLabel fontSize={13} >Programme</FormLabel>
              <Select
                name="programmeId"
                onChange={(e) => handleInputChange(e)}
                fontSize={13}
                >
                    <option value="">Select option</option>
                {Array.isArray(programme) && programme?.map((item) => (
                <option key={item?._id} value={item?._id}>
                    {item.name} 
                </option>
                ))}
            </Select>
            </FormControl>
             <FormControl  >
               <FormLabel fontSize={13} >Department</FormLabel>
              <Select
                name="departmentId"
                onChange={(e) => handleInputChange(e)}
                fontSize={13}
                >
                    <option value="">Select option</option>
                {Array.isArray(department) && department && department?.map((item) => (
                <option key={item?._id} value={item?._id}>
                    {item.name} 
                </option>
                ))}
            </Select>
            </FormControl>

             <FormControl  >
               <FormLabel fontSize={13} >Level</FormLabel>
              <Select
                name="levelId"
                onChange={(e) => handleInputChange(e)}
                fontSize={13}
                >
                    <option value="">Select option</option>
                
                {Array.isArray(level) && level?.map((item) => (
                <option key={item?._id} value={item?._id}>
                    {item.name} 
                </option>
                ))}
            </Select>
            </FormControl>
            <FormControl  >
               <FormLabel fontSize={13} >Assign Lecturer</FormLabel>
              <Select
                name="lecturerId"
                onChange={(e) => handleInputChange(e)}
                fontSize={13}
                >
                    <option value="">Select option</option>
                
                {Array.isArray(staff) && staff?.map((item) => (
                <option key={item?._id} value={item?._id}>
                    {item.firstName} {item.lastName} 
                </option>
                ))}
            </Select>
            </FormControl>

             <FormControl  >
               <FormLabel fontSize={13} >Course Units</FormLabel>
              <Input
                type="text"
                name="unit"
                fontSize={13}
                onChange={handleInputChange}
              />
            </FormControl>

             <FormControl  >
               <FormLabel fontSize={13} >Status</FormLabel>
              <Select
              fontSize={13}
                name="status"
                onChange={(e) => handleInputChange(e)}
              >
                <option value="Elective">Elective</option>
                <option value="Compulsory">Compulsory</option>
                <option value="Other">Other</option>
                {/* Add more status options as needed */}
              </Select>
            </FormControl>
            </Grid>
              { submitError &&   <Text color='red.500'>{submitError}</Text>}
              {message && <Text color='green.500'>{message}</Text>}
          </ModalBody>
          <ModalFooter>
            <Button  bg={`${configs?.primaryBg}`}
                 color="white"
               
                 _hover={{ bg: '#1E40AF' }}
                 fontSize={12} mr={3} onClick={handleAddCourse}>
              {isSubmitting ? 'is Submitting' : 'Submit'}
            </Button>
            <Button  fontSize={12} onClick={() => setIsAddCourseModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal  isOpen={isEditCourseModalOpen} onClose={() => setIsEditCourseModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={14}>Set Course</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Grid
              templateColumns={{ base: '1fr', md: '1fr 1fr' }}
              gap={6}
            >
             <FormControl  >
               <FormLabel fontSize={13} >Course </FormLabel>
              <SearchableSelect
                name="courseId"
                placeholder="Select Course"
                data={courses || []}
                onChange={(e) => handleInputChange(e)}
              />
            </FormControl>

             <FormControl  >
               <FormLabel fontSize={13} >Semester</FormLabel>
              <Select
                name="semesterId"
                onChange={(e) => handleInputChange(e)}
                fontSize={13}
            >
                <option value="">Select option</option>
                {Array.isArray(semester) && semester?.map((item) => (
                <option key={item.id} value={item?._id}>
                    {item.name} 
                </option>
                ))}
            </Select>
            </FormControl>
             <FormControl  >
               <FormLabel fontSize={13} >Programme</FormLabel>
              <Select
                name="programmeId"
                onChange={(e) => handleInputChange(e)}
                fontSize={13}
                >
                    <option value="">Select option</option>
                {Array.isArray(programme) && programme?.map((item) => (
                <option key={item?._id} value={item?._id}>
                    {item.name} 
                </option>
                ))}
            </Select>
            </FormControl>
             <FormControl  >
               <FormLabel fontSize={13} >Department</FormLabel>
              <Select
                name="departmentId"
                onChange={(e) => handleInputChange(e)}
                fontSize={13}
                >
                    <option value="">Select option</option>
                {Array.isArray(department) && department && department?.map((item) => (
                <option key={item?._id} value={item?._id}>
                    {item.name} 
                </option>
                ))}
            </Select>
            </FormControl>

             <FormControl  >
               <FormLabel fontSize={13} >Level</FormLabel>
              <Select
                name="levelId"
                onChange={(e) => handleInputChange(e)}
                fontSize={13}
                >
                    <option value="">Select option</option>
                
                {Array.isArray(level) && level?.map((item) => (
                <option key={item?._id} value={item?._id}>
                    {item.name} 
                </option>
                ))}
            </Select>
            </FormControl>
            <FormControl  >
               <FormLabel fontSize={13} >Assign Lecturer</FormLabel>
              <Select
                name="lecturerId"
                onChange={(e) => handleInputChange(e)}
                fontSize={13}
                >
                    <option value="">Select option</option>
                
                {Array.isArray(staff) && staff?.map((item) => (
                <option key={item?._id} value={item?._id}>
                    {item.firstName} {item.lastName} 
                </option>
                ))}
            </Select>
            </FormControl>

             <FormControl  >
               <FormLabel fontSize={13} >Course Units</FormLabel>
              <Input
                type="text"
                name="unit"
                fontSize={13}
                onChange={handleInputChange}
              />
            </FormControl>

             <FormControl  >
               <FormLabel fontSize={13} >Status</FormLabel>
              <Select
              fontSize={13}
                name="status"
                onChange={(e) => handleInputChange(e)}
              >
                <option value="Elective">Elective</option>
                <option value="Compulsory">Compulsory</option>
                <option value="Other">Other</option>
                {/* Add more status options as needed */}
              </Select>
            </FormControl>
            </Grid>

            <Text color='green.500' fontSize={13}>{message}</Text>
          </ModalBody>
          <ModalFooter>
          <Button
            bg={`${configs?.primaryBg}`}
            color="white"
            _hover={{ bg: '#1E40AF' }}
            fontSize={12}
            mr={3}
            onClick={handleUpdateCourse}
          >
            Update
          </Button>
          <Button fontSize={12} onClick={() => setIsEditCourseModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
        </ModalContent>
      </Modal>
    </DashboardLayout>
  );
};

export default AvailableCourse;
