// ProgrammeDetails.js
import React, { useEffect } from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Spacer from '../components/Spacer';
import DynamicAccordion from '../components/DynamicAccordion';

const ProgrammeDetails = () => {
  const { data, fetchData } = useFetch();
  const configs = useSelector((state) => state.auth.config);
  const { id } = useParams();

  useEffect(() => {
    fetchData(`programmes/all`);
  }, [id]);

  return (
    <Box>
      <Header
        config={configs?.api + configs?.logoDark || '/images/loading.png'}
        bannerSrc={configs?.newsBg ? configs?.api + configs?.newsBg : configs?.api + '/uploads/image.png'}
        buttonText='Login'
        
        height="300px"
      >
        <Box
        width={'100%'}
        ml={['10px', "200px"]}
          
          justifyContent="center"
          alignItems="center"
          textAlign="left"

        >
         <Box justifySelf={'center'}>
            <Text
              mt={3}
              color={`${configs?.primaryColor}`}
              fontFamily="Kumbh Sans"
              fontSize="50px"
              fontWeight="600"
              lineHeight="64px"
              letterSpacing="-0.03em"
            >
              Programmes
            </Text>
          </Box>
        </Box>
      </Header>

      <Spacer gap="500" />

      <Box px="15%">
        <Box mt={5}> 
            <DynamicAccordion data={data}></DynamicAccordion>
        </Box>
      </Box>

      <Footer config={configs?.logoDark} />
    </Box>
  );
};

export default ProgrammeDetails;
