import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Grid,
} from '@chakra-ui/react';

import useSchoolInfo from '../hooks/useSchoolInfo';
import useFormSubmission from '../hooks/useFormSubmission';
import { useSelector } from 'react-redux';
const AddSchoolFeesModal = ({ isOpen, onClose }) => {
  const configs = useSelector((state)=>state.auth.config)
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
 
  const [formData, setFormData] = useState({
    amount: '', // Amount is no longer part of the breakdown array
    session: '',
    programme: '',
    department: '',
    level: '',
    semesters: '',
    breakdown: [{ name: '', amount: '' }],
  });
  const { semesters, semestersLoading, sessions, sessionsLoading, programmes, programmesLoading, departments, departmentsLoading, levels, levelsLoading } = useSchoolInfo();

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    console.log("changed")
    if (name === 'name' || name === 'amount') {
      const updatedBreakdown = [...formData.breakdown];
      updatedBreakdown[index] = {
        ...updatedBreakdown[index],
        [name]: value,
      };

      // Calculate the total amount based on the sum of breakdown amounts
      const totalAmount = updatedBreakdown.reduce(
        (sum, item) => sum + parseFloat(item.amount || 0),
        0
      );

      setFormData((prevData) => ({
        ...prevData,
        amount: totalAmount,
        breakdown: updatedBreakdown,
      }));
    } else {
      console.log(name, value)
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleAddBreakdown = () => {
    setFormData((prevData) => ({
      ...prevData,
      breakdown: [...prevData.breakdown, { category: '', amount: '' }],
    }));
  };

  const handleRemoveBreakdown = (index) => {
    const updatedBreakdown = [...formData.breakdown];
    updatedBreakdown.splice(index, 1);

    // Calculate the total amount based on the sum of breakdown amounts
    const totalAmount = updatedBreakdown.reduce(
      (sum, item) => sum + parseFloat(item.amount || 0),
      0
    );

    setFormData((prevData) => ({
      ...prevData,
      amount: totalAmount,
      breakdown: updatedBreakdown,
    }));
  };

  const handleSubmit = async() => {
    // Implement the logic for submitting the new school fees data
    // You may want to use the useFormSubmission hook here

    const submissionResult = await submitForm(`school-fees`, formData, "json");
    
    if (submissionResult.submitSuccess) {
  
    console.log('Form Data:', formData);

    }
    // Add your logic to send the formData to the server
    // Use the useFormSubmission hook to handle the API call
    onClose(); // Close the modal after submission
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add School Fees</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Form inputs for adding school fees data */}
          <FormControl mb={2}>
            <FormLabel fontSize={13}>Amount</FormLabel>
            <Input
              type="number"
              name="amount"
              value={formData.amount}
              fontFamily="Kumbh Sans" fontSize={13} 
              readOnly
            />
          </FormControl>
          <FormControl mb={2}>
            <FormLabel fontSize={13}>Session</FormLabel>
            <Select
              name="session"
              value={formData.session}
              onChange={(e) => handleChange(e)}
              fontFamily="Kumbh Sans" fontSize={13} 
            >
              {sessionsLoading ? (
                <option>Loading Sessions...</option>
              ) : (
                sessions?.map((session) => (
                  <option key={session.id} value={session?._id}>
                    {session.name}
                  </option>
                ))
              )}
            </Select>
          </FormControl>
          <FormControl mb={2}>
            <FormLabel fontSize={13}>Programme</FormLabel>
            <Select
              name="programme"
              value={formData?.programme}
              onChange={(e) => handleChange(e)}
              fontFamily="Kumbh Sans" fontSize={13} 
            >
              {programmesLoading ? (
                <option>Loading Programmes...</option>
              ) : (
                programmes?.map((programme) => (
                  <option key={programme?._id} value={programme?._id}>
                    {programme?.name}
                  </option>
                ))
              )}
            </Select>
          </FormControl>
          <FormControl mb={2}>
            <FormLabel fontSize={13}>Department</FormLabel>
            <Select
              name="department"
              value={formData?.department}
              onChange={(e) => handleChange(e)}
              fontFamily="Kumbh Sans" fontSize={13} 
            >
              {departments && (
                departments?.map((department) => (
                  <option key={department?.id} value={department?._id}>
                    {department?.name}
                  </option>
                ))
              )}
            </Select>
          </FormControl>
          <FormControl mb={2}>
            <FormLabel fontFamily="Kumbh Sans" fontSize={13} >Level</FormLabel>
            <Select
              name="level"
              value={formData?.level}
              onChange={(e) => handleChange(e)}
              fontFamily="Kumbh Sans" fontSize={13} 
            >
              {levelsLoading ? (
                <option>Loading Levels...</option>
              ) : (
                levels?.map((level) => (
                  <option key={level.id} value={level?._id}>
                    {level?.name}
                  </option>
                ))
              )}
            </Select>
          </FormControl>
          {/* <FormControl mb={2}>
            <FormLabel fontSize={13}>Semester</FormLabel>
            <Select
              name="level"
              value={formData.level}
              onChange={(e) => handleChange(e)}
              fontSize={13}
            >
              {levelsLoading ? (
                <option>Loading Semester...</option>
              ) : (
                semesters.map((semester) => (
                  <option key={semester.id} value={semester.id}>
                    {semester.name}
                  </option>
                ))
              )}
            </Select>
          </FormControl> */}
          {/* Breakdown categories */}
          {formData?.breakdown.map((breakdownField, index) => (
            <Grid key={index} templateColumns={"repeat(2, 1fr)"}  gap={10}>
              <FormControl mb={2}>
                <FormLabel fontFamily="Kumbh Sans" fontSize={13} >Breakdown Title</FormLabel>
                <Input
                  type="text"
                  name="name"
                  fontFamily="Kumbh Sans" fontSize={13} 
                  value={breakdownField?.name}
                  onChange={(e) => handleChange(e, index)}
                />
              </FormControl>
              <FormControl mb={2}>
                <FormLabel     fontSize={13}>Amount</FormLabel>
                <Input
                  type="number"
                  name="amount"
                  fontFamily="Kumbh Sans" fontSize={13} 
                  value={breakdownField?.amount}
                  onChange={(e) => handleChange(e, index)}
                />
              </FormControl>
              {index > 0 && (
                <Button
                fontFamily="Kumbh Sans" fontSize={13} 

                  onClick={() => handleRemoveBreakdown(index)}
                >
                  -
                </Button>
              )}
            </Grid>
          ))}
          <Button bg={configs?.primaryBg} 
        fontFamily="Kumbh Sans" fontSize={13} 
        color={configs?.primaryColor} mt={4}
         
           onClick={handleAddBreakdown}>
            +
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button bg={configs?.primaryBg} 
        fontFamily="Kumbh Sans" fontSize={13} 
        color={configs?.primaryColor}
        mr={3} onClick={handleSubmit}>
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddSchoolFeesModal;
