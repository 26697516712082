import React, { useState } from 'react';
import { Input, Button, FormControl, FormLabel, Box , Text} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import useFormSubmission from '../hooks/useFormSubmission';
import { useSelector } from 'react-redux';

const Others = () => {
  const [formData, setFormData] = useState({ transcript: {
      email: '',
      courier: '',
      postal: ''
  }});
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const [message, setMessage] = useState();
  const configs = useSelector((state)=> state.auth.config)

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      transcript: {
        ...prevFormData.transcript,
        [name]: value
      }
    }));
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const { submitSuccess, responseBody } = await submitForm('configs/update', formData, 'json');
    
    
    if (submitSuccess) {
      setMessage(responseBody?.data?.message)
    } else {
      console.error(submitError || 'Form submission failed');
    }
  
  };

  return (
    <DashboardLayout sidebar={"two"}>
    <Box 
     borderRadius={11} px={2} py={5} 
     width={["120%", '100%' ]}>
     <Box 
       bg="white" 
       borderRadius={11} 
       mt={2} 
       p={20}  
       width={['200','100%']}
       height={'100%'}
     
     >
    <Text  fontFamily="Kumbh Sans"
            fontSize="16px"
            fontWeight={700}
            mb={4}
            >Others Settings</Text>
    <form onSubmit={handleSubmit}>
    

      <FormControl mt={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px" >Acceptance Fee</FormLabel>
        <Input type="number" name="acceptanceFee"
        fontFamily="Kumbh Sans"
        fontSize="13px" 
        onChange={handleInputChange} />
      </FormControl>

      <FormControl mt={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px" >Application Fee</FormLabel>
        <Input type="number" name="applicationFee"
        fontFamily="Kumbh Sans"
        fontSize="13px" 
        onChange={handleInputChange} />
      </FormControl>
      {submitError && (<Text color="red.500">{submitError}</Text>)}
      {message && (<Text color="green.500">{message}</Text>)}
      
      <Button type="submit" mt={4} fontFamily="Kumbh Sans"
            fontSize="13px" 
            bg={`${configs?.primaryBg}`}
            color={`${configs?.primaryColor}`}
            float="right"
            >
        {isSubmitting ? '...Submitting' : 'Submit'}
      </Button>
    </form>
    </Box>
    </Box>
    </DashboardLayout>
  );
};

export default Others;
