import React, { useState, useEffect } from 'react';
import { Box} from '@chakra-ui/react';
import {  useParams } from 'react-router-dom'; // Import useParams
import DashboardLayout from '../components/DashboardLayout';
import { Link } from 'react-router-dom';
import useFetch from '../hooks/useFetch';

const TranscriptDisplay = () => {
  
  const { id } = useParams(); // Use useParams to get the page and id
  const { data: transcripts, loading, error, fetchData } = useFetch();
  const [dynamicPath, setDynamicPath] = useState('/results/view-transcripts/'+id);
  
  useEffect(() => {
    fetchData(dynamicPath);
  }, [dynamicPath]);

  const goBack = () => {
    // Go back one step in the browser history
    window.history.back();
  };
  
  return (
    // <DashboardLayout sidebar={"none"}>
    <Box 
    bg="white"
   >
    <Link onClick={goBack} className="hidden-section">Back</Link>
  <Box 
    bg="white" 
    borderRadius={11} 
    mt={5} 
    px={10}  
    width={['100%','100%']}
    justifyContent={'center'}
    // height={['100%', '100%']}
   
  >
    
    <div>
      {/* Display the HTML content using dangerouslySetInnerHTML */}
      <div dangerouslySetInnerHTML={{ __html: transcripts?.data }} />
</div>
      </Box>
      </Box>
    // </DashboardLayout> 
  );
};



export default TranscriptDisplay;
