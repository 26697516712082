import React, { useState } from 'react';
import { VStack, Input, Image, FormLabel, Button, Box, Grid, GridItem,
Table, Th, Td, Tr, Text, Thead, Tbody } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const Step3 = ({ upload, setFormData, id }) => {
  const configs = useSelector((state)=> state.auth.config)
  const [fileInputs, setFileInputs] = useState([{ id: 1, title: 'File 1', file: null }]);
  const handleInputChange = (field, value, index) => {
    const updatedFileInputs = [...fileInputs];
    updatedFileInputs[index][field] = value;
    
    // Update filename based on the title
    updatedFileInputs[index]['filename'] = value.replace(/\s+/g, '_').toLowerCase();
  
    setFileInputs(updatedFileInputs);
    
    // Include uploads data in the formData
    const newFormData = new FormData();
    updatedFileInputs.forEach((fileInput, i) => {
      newFormData.append(`files[${i}][title]`, fileInput.title);
      newFormData.append(`files[${i}][filename]`, fileInput.filename); // Include filename in formData
      if (fileInput.file) {
        newFormData.append(`files[${i}][file]`, fileInput.file);
      }
    });
    setFormData(newFormData);
  };
  
  const handleFileChange = (file, index) => {
    const updatedFileInputs = [...fileInputs];
    updatedFileInputs[index].file = file;
    setFileInputs(updatedFileInputs);
    
    // Include uploads data in the formData
    const newFormData = new FormData();
    newFormData.append("id", id)
    updatedFileInputs.forEach((fileInput, i) => {
      if (fileInput.file) {
        newFormData.append(`files[${i}][title]`, fileInput.title);
        newFormData.append(`files[${i}][file]`, fileInput.file);
        
      }
    });
    setFormData(newFormData);
  };

  const handleAdd = () => {
    setFileInputs((prevInputs) => [
      ...prevInputs,
      { id: prevInputs.length + 1, title: `File ${prevInputs.length + 1}`, file: null },
    ]);
  };

  const handleRemove = (index) => {
    setFileInputs((prevInputs) => prevInputs.filter((_, i) => i !== index));
  };

  return (
    <VStack align="start" spacing={4} minWidth="100%">
      {fileInputs.map((fileInput, index) => (
        <Grid
          templateColumns={['1fr', '1fr 1fr']} // One column on mobile, two columns on desktop
          gap={10}
          style={{ fontFamily: 'Kumbh Sans', fontSize: '12px !important', fontWeight: 500 }}
          key={index}
        >
          <GridItem>
            <FormLabel color="#374151" fontSize={13}>
              Upload Title <Box display="inline-flex" color={'red !important'}>*</Box>
            </FormLabel>
            <Input
              fontSize={13}
              value={fileInput.title}
              onChange={(e) => handleInputChange('title', e.target.value, index)}
            />
          </GridItem>
          <GridItem>
            <FormLabel color="#374151" fontSize={13}>
              Upload File <Box display="inline-flex" color={'red !important'}>*</Box>
            </FormLabel>
            <Input
              type="file"
              onChange={(e) => handleFileChange(e.target.files[0], index)}
              borderRadius="6px"
              fontSize={13}
            />
          </GridItem>
          {index !== 0 && (
            <Button bg="#FEEEEE" fontSize={13} color="#F92F2F" onClick={() => handleRemove(index)}>
              Remove <Image src="/icons/delete.svg" />
            </Button>
          )}
        </Grid>
      ))}
      <Button bg="#F6F6FE" fontSize={13} color={`${configs?.primaryBg}`} onClick={handleAdd}>
        <Image src="/icons/add-circle.svg" mr="2" /> Add Upload
      </Button>

      <Text mt={5} fontSize={13} fontWeight={600}>Uploaded Files </Text>
      <Table variant="striped" fontSize={13}>
      <Thead fontSize={13}>
        <Tr fontSize={13}>
         
          <Th fontSize={13}>Title</Th>
          <Th fontSize={13}>URL</Th>
        </Tr>
      </Thead>
      <Tbody fontSize={13}>
        {upload?.map((item, index) => (
          <Tr fontSize={13} key={index}>
            <Td fontSize={13}>{item.title}</Td>
            <Td fontSize={13}>
              <a href={configs.api+item.url} target="_blank" rel="noopener noreferrer">
                View
              </a>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
        {configs?.requirement && (
          <Box>
              <Text>Undergraduates/Diploma: {configs?.requirement?.undergraduate}</Text>
              <Text>Post Graduate: {configs?.requirement?.postgraduate}</Text>
          </Box>
        )}
    </VStack>
  );
};

export default Step3;
