import React from 'react';
import { Box, Text, Button, Image, VStack, SimpleGrid } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Pagination from './Pagination';
const NewsCard = ({ id, image, category, createdAt, title, content  }) => {
  const configs = useSelector((state) => state.auth.config);
  const limitStringToWords = (input, wordLimit) =>{
    const words = input.split(/\s+/); // Split the string into an array of words
    const limitedWords = words.slice(0, wordLimit); // Get the specified number of words
    return limitedWords.join(' '); // Join the words back into a string
  }
  
  return (

    <Box  key={id} bg="white" p={{ base: 0, md: 4}}>
    
      <Image src={configs?.api+image} alt="News Image" mb={4}
      borderRadius={10}
      />
      <VStack align="start" spacing={2}>
        <Text 
         color={configs?.primaryBg}
         fontSize="14"
         fontWeight="400"
         lineHeight="140%"
        > {category?.name}    |   {new Date(createdAt)?.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        })} </Text>
        <hr style={{background: "#3A4553", width: "100%", height: "0.887px"}}></hr>
        <Text 
          color="#232738"
          fontSize="16px"
          fontWeight="700"
          lineHeight="normal"
          textTransform="uppercase"
        >{title}</Text>
        <Text
        color="#929EAE"
        fontSize="14px"
        fontWeight="400"
        lineHeight="140%"
        >{limitStringToWords(content, 19)}</Text>
        <a
          as="a"
          href={`/news/${id}`}
          target="_blank"
          rel="noopener noreferrer"
          color="#3A4553"
          fontSize="16px"
          fontWeight="400"
          lineHeight="normal"
          style={{textDecoration:"underline #3A4553"}}
          textTransform="capitalize"
          // colorScheme="blue"
        >
          Read More
        </a>
      </VStack>
    </Box>
  );
};

const NewsSection = ({newsData, loading, home, page, onPageChange, pageSize}) => {
  const configs = useSelector((state) => state.auth.config);
  return (
    <Box>
    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} gap={5}>
      {newsData?.map((news) => (
        <NewsCard key={news.id} {...news} />
      ))}
    
    </SimpleGrid>

        { home == true ? 
      <Box   display="flex"
      flexDirection="column"
      alignItems="center">
        <Link to='/latest_news'>
        <Button 
      
        padding="15px 20px"
        
        borderRadius="8px"
        background={configs?.primaryBg}
        color={configs?.primaryColor}
        boxShadow="0px 4px 16px 0px rgba(76, 147, 255, 0.20)"
        mt={4}>
          See More News
        </Button>
    </Link>
    </Box>:<Box display={'flex'} float={'right'}><Pagination currentPage={page} totalPages={pageSize} onPageChange={onPageChange} /></Box>}
    </Box>
  );
};

export default NewsSection;
