import React, {useState} from 'react';
import { Box, Flex, Image, Button, Grid, GridItem, 
  useMediaQuery, IconButton, Link, Text, Icon } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import { FaFacebook, FaTwitter, FaInstagram, FaMobile, FaEnvelope } from 'react-icons/fa';

const Header = ({ config, bannerSrc, doubleGrid, children, height, heading }) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [isNavVisible, setIsNavVisible] = useState(false);

  const location = useLocation();
  const isLatestNewsPage = location.pathname === '/latest_news';


  const toggleNavVisibility = () => {
    setIsNavVisible(!isNavVisible);
  };

const configs = useSelector((state)=> state.auth.config)  
  return (
    <>
      <Box   bg={configs?.primaryBg} color="#5C5C5C" 
      pt={5} 
      // px={20} 
      position="relative" zIndex={1000}
      >
       <Grid display={'flex'} px={10} gap={10} justifyContent="space-between">
  <GridItem justifySelf="flex-start">
    <Flex mt={{ base: 4, md: 0 }} direction="row" gap={5}>
      {/* Social Media Icons */}
      <Link href={configs?.facebook} mb={2}>
        <Icon color={"#FFF"} as={FaFacebook} boxSize={{ base: 5, md: 5 }} />
      </Link>
      <Link href={configs?.twitter} mb={2}>
        <Icon color={"#FFF"} as={FaTwitter} boxSize={{ base: 5, md: 5 }} />
      </Link>
      <Link href={configs?.instagram}>
        <Icon color={"#FFF"} as={FaInstagram} boxSize={{ base: 5, md: 5 }} />
      </Link>
    </Flex>
  </GridItem>
  
  <GridItem justifySelf="flex-end">
   
  </GridItem>
  
  <GridItem display={isMobile ? "block" : "flex"} justifySelf={ isMobile ? "center": "flex-end"}  direction="row">
  <Flex mt={{ base: 4, md: 0 }} direction="row" gap={1} mr={5}>
      <Icon color={"#FFF"} as={FaMobile} boxSize={{ base: 5, md: 5 }} />
      <Text  color={"#FFF"} fontSize={'13px'}>{configs?.phoneNumber}</Text>
    </Flex>
    <Flex mt={{ base: 4, md: 0 }} direction="row" gap={1}>
      <Icon color={"#FFF"} as={FaEnvelope} boxSize={{ base: 5, md: 5 }} />
      <Text  color={"#FFF"} fontSize={'13px'}>{configs?.email}</Text>
    </Flex>
  </GridItem>
</Grid>

       <Box width={"100%"}
       display={'block'}
       px={10}
          bg={"white"}>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          align={{ base: 'initial', md: 'center' }}          
        >
          <Box mb={{ base: 2, md: 2 }} mt={2}>
         
            <Image src={config} alt="Logo"  width={100}/>
          </Box>
          <>
          <Box display={(!isMobile && isNavVisible) ? 'block' : (!isMobile && !isNavVisible) ? 'block'  : (isMobile && isNavVisible) ? 'block' :'none'}>
            <nav>
              <ul
                style={{
                  fontFamily: 'Kumbh Sans',
                  fontSize: '18px',
                  fontWeight: 500,
                  lineHeight: '17px',
                  letterSpacing: '0em',
                  textAlign: 'center',
                  listStyle: 'none',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: isMobile ? 'center' : 'flex-start',
                  gap: isMobile ? '10px' : '60px',
                  flexDirection: isMobile ? 'column' : 'row',
                }}
              >
                <li style={{ margin: isMobile ? '5px 0' : '0 15px', fontWeight: location.pathname == "/" ? 600:400 }}>
                  <a href="/">HOME</a>
                </li>
               
                <li style={{ margin: isMobile ? '5px 0' : '0 15px', fontWeight: location.pathname == "/programmes" ? 600:400 }}>
                  <a href="/programmes">PROGRAMMES</a>
                </li>
                <li style={{ margin: isMobile ? '5px 0' : '0 15px', fontWeight: location.pathname == "/latest_news" ? 600:400 }}>
                  <a href="/latest_news">LATEST NEWS</a>
                </li>
                <li style={{ margin: isMobile ? '5px 0' : '0 15px', fontWeight: location.pathname == "/contact" ? 600:400 }}>
                  <a href="/contact">CONTACT US</a>
                </li>
              </ul>
            </nav>
           
          </Box>
          {isMobile && 
             <IconButton
             icon={<HamburgerIcon />}
             aria-label="Toggle Sidebar"
             mr="4"
            width={20}
            onClick={toggleNavVisibility}></IconButton>
            
            }
          </>
          <Box mt={{ base: 4, md: 0 }}>
            {/* Buttons */}
            <Button  as="a"
              fontFamily='Kumbh Sans'
              fontSize='13px'
              href={"/login"}
              target="_blank"  
              bg={configs?.primaryBg} color={configs?.primaryColor} 
              boxShadow="0px 4px 16px 0px rgba(76, 147, 255, 0.20)"
               mr={2}
               >
              Login
            </Button>
            {/* You can add more buttons or components as needed */}
          </Box>
        </Flex>
        </Box>
      </Box>
      {/* Banner Image */}
      { bannerSrc ? 
      <Box position="relative" zIndex={1}>
        <Image src={bannerSrc} alt="Banner" w="100%" height={500}/>
      </Box>: ""}
      <Box zIndex={999} position={'absolute'} top={["100px","280px"]} 
       justifyContent={'center'} alignItems={'center'} >
      {doubleGrid ? (
        <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={4} p={4}>
          <GridItem colSpan={2}>
            {children}
          </GridItem>
        </Grid>
      ) : (
        <Box p={4}>
          {children}
        </Box>
      )}
      </Box>
      {!isLatestNewsPage &&
      <Box zIndex={998} position={'absolute'} background={configs?.primaryBg+75} height={isMobile? 550 :515}
          top={["3.5%", height ? "9%" :"5%"]}
          mt={1}
          width={"100%"}
       justifyContent={'center'} alignItems={'center'} >

        </Box>}
    </>
  );
};

export default Header;
