// Dashboard.js
import React, { useEffect, useState } from 'react';
import { Text, Box, Button, FormControl, FormLabel, Select, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,  Grid } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import InformationCard from '../components/InformationCard';
import DataTable from '../components/DataTable';
import useFetch from '../hooks/useFetch';
import useFormSubmission from '../hooks/useFormSubmission';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const TranscriptAdmin = () => {
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const [selectedItem, setSelectedItem] = useState(null);
  const { data: application, loading, error, fetchData } = useFetch();
  const configs = useSelector((state)=>state.auth.config)
  const navigate = useNavigate();
  const [dynamicPath, setDynamicPath] = useState('results/transcripts/findTranscript');
  const dynamicParams = "";
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [message, setMessage] = useState()  
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const handleDisplay = (item) => {
    setSelectedItem(item);
    onOpen();
  };
  
  const headers = [
    { label: 'Name', key: 'fullName' },
    { label: 'Matric', key: 'matric' },
    { label: 'Type', key: 'type' },
    { label: 'Status', key: 'status' }
  ];
  const buttons = [
      {
      color: 'darkblue',
      label: 'Details',
      onClick: (item) => handleDisplay(item),
    }
    // ... additional buttons
  ];
  const handleStatusChange = async() => {
    if (selectedItem) {
      // Handle the logic to update the status (you may need an API call here)
      const updatedTranscript = {
        matric: selectedItem.matric,
        status: selectedStatus,
      };
      const { submitSuccess, responseBody } = await submitForm('transcripts/updateTranscript',updatedTranscript);
      if (submitSuccess){
        console.log(responseBody)
          setMessage(responseBody.data)
          // navigate(`/payment/generated/${responseBody?.data?._id}`);
      }
    }
    
    // Close the modal after handling the status change

    onClose();
  };

const handleView = (item) =>{
    navigate('/results/view-transcripts/'+item.userId)
}

useEffect(() => {
  fetchData(dynamicPath, dynamicParams);
}, [dynamicPath]);

  return (
    <DashboardLayout sidebar={"two"}>
        <Box borderRadius={11} px={2} py={5} width={["130%", '100%' ]}>
      <Box 
        bg="white" 
        borderRadius={11} 
        mt={2} 
        p={5}  
        width={['200','100%']}
        height={['100%', '100%']}
        // display={'flex'}
      >
        <Grid
            templateColumns="repeat(4, 1fr)"
            gap={2}
            mt={3}
            // maxW="600px"
            // margin="0 auto"
        >
            <InformationCard title="Total No of Request" value={application?.data?.length} semesterInfo="This semester" />
         
        </Grid>
        <Box>

    
        </Box>
        <Box mt={5}>
          <DataTable
                data={application?.data || []}
                headers={headers}
                buttons={buttons}
                page={application?.currentPage}
                onPageChange={(newPage) =>  setDynamicPath(`/results/transcripts/findTranscript/${newPage}`)}
                pageSize={application?.totalPages}
                totalItems={application?.totalItems}
                loading={loading}
                search={true}
                exports={true}
                pagination={true}
              />
            </Box>
            <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={13}>Detailed Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Link to={'/results/view-transcripts/'+selectedItem?.userId}>View</Link>
            {selectedItem && (
              <Box>
                <Text fontSize={13}>Name: {selectedItem?.fullName}</Text>
                <Text fontSize={13}>Matric: {selectedItem?.matric}</Text>
                <Text fontSize={13}>Type: {selectedItem?.type}</Text>
                <Text fontSize={13}>Status: {selectedItem?.status}</Text>
                <Text fontSize={13}>Address: {selectedItem?.address}</Text>
                {/* Add additional details as needed */}

                {/* Select option to change status */}
                <FormControl mt={4}>
                  <FormLabel fontSize={13}>Change Status:</FormLabel>
                  <Select
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    <option></option>
                    <option value="Approved">Approved</option>
                    <option value="Sent">Sent</option>
                  </Select>
                </FormControl>
              </Box>
            )}
             <Text color="red.500">{submitError}</Text>
            <Text color="green.500">{message}</Text>
          </ModalBody>
          <ModalFooter>
            <Button  bg={`${configs?.primaryBg}`}
                 color={`${configs?.primaryColor}`}
                   
                 _hover={{ bg: '#1E40AF' }}
                 fontSize={13} mr={3} 
                  onClick={handleStatusChange}>
              {isSubmitting ? 'Submitting' : 'Change Status'}
            </Button>
            <Button fontSize={13} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
        </Box>
    </Box>  
    </DashboardLayout>
  );
};

export default TranscriptAdmin;
