import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Text, Image } from '@chakra-ui/react';

const ApplicationLink = ({ title, icon, link }) => {
  return (
    <Link to={link}>
      <Box
        p={5}
        height={["100%", 120]}
        width={["100%", 140]}
        borderRadius="10"
        border="1px"
        textAlign="center"
        borderColor="#CCDFF2"
        display="flex"
        flexDirection="column"
        alignItems="center" // Center horizontally
        justifyContent="center" // Center vertically
      >
        <Image src={`/icons/${icon}`} width={50} mt={5} />
        <Text
          fontFamily="Kumbh Sans"
          fontSize="10px"
          fontWeight="600"
          lineHeight="12px"
          letterSpacing="0em"
          textAlign="center"
          my={5}
        >
          {title}
        </Text>
      </Box>
    </Link>
  );
};

export default ApplicationLink;
