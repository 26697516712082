import { MdDashboard, MdShoppingCart, MdAssignment, MdPeople, MdSettings } from "react-icons/md"; // Import Material Icons

const menuItems = [
  {
    label: 'Dashboard',
    icon: '/icons/dashboard-filled.svg', // Use the Material Icon component
    icons: '/icons/dashboard-unfilled.svg',
    url: '/dashboard' || '/',
    role: ["Student"],
    unit: ["All"]
  },
  {
    label: 'Dashboard',
    icon: '/icons/dashboard-filled.svg', // Use the Material Icon component
    icons: '/icons/dashboard-white.svg',
    url: '/dashboard' || '/',
    role: ["Administrator", "Staff"],
    unit: ["All"]
  },
   {
    label: 'Bio Data',
    icon: '/icons/note-filled.svg', // Use the Material Icon component
    icons: '/icons/note-unfilled.svg',
    url: '/application/biodata',
    role: ["Applicant"],
    unit: ["All"]
  },{
    label: 'Education History',
    icon: '/icons/teacher-filled.svg', // Use the Material Icon component
    icons: '/icons/teacher-unfilled.svg',
    url: '/application/education',
    role: ["Applicant"],
    unit: ["All"]
  },{
    label: 'Uploads',
    icon: '/icons/note-filled.svg', // Use the Material Icon component
    icons: '/icons/note-unfilled.svg',
    url: '/application/uploads',
    role: ["Applicant"],
    unit: ["All"]
  },{
    label: 'Review',
    icon: '/icons/document-filled.svg', // Use the Material Icon component
    icons: '/icons/document-unfilled.svg',
    url: '/application/review',
    role: ["Applicant"],
    unit: ["All"]
  },
  {
    label: 'Student Data',
    icon: '/icons/note-filled.svg', // Use the Material Icon component
    icons: '/icons/note-unfilled.svg',
    url: '/student' || '/',
    role: ["Student"],
    unit: ["All"]
  },{
    label: 'Payments',
    icon: '/icons/card-filled.svg', // Use the Material Icon component
    icons: '/icons/card-unfilled.svg',
    url: '/application/payment',
    role: ["Applicant"],
    unit: ["All"]
  },
  {
    label: 'Payments',
    icon: '/icons/receipt-filled.svg', // Use the Material Icon component
    icons: '/icons/receipt-unfilled.svg',
    url: '/payment' || '/',
    role: ["Student"],
    unit: ["All"]
  },{
    label: 'Course Registration',
    icon: '/icons/books-filled.svg', // Use the Material Icon component
    icons: '/icons/books-unfilled.svg',
    url: '/courses' || '/',
    role: ["Student"],
    unit: ["All"]
  },{
    label: 'Result Page',
    icon: '/icons/book-filled.svg', // Use the Material Icon component
    icons: '/icons/book-unfilled.svg',
    url: '/result' || '/',
    role: ["Student"],
    unit: ["All"]
  }, {
    label: 'E-Clearance',
    icon: '/icons/award-filled.svg', // Use the Material Icon component
    icons: '/icons/award-filled.svg',
    url: '/eclearance' || '/',
    role: ["Student"],
    unit: ["All"]
  },{
    label: 'E-Transcript',
    icon: '/icons/receipt-filled.svg', // Use the Material Icon component
    icons: '/icons/receipt-unfilled.svg',
    url: '/transcripts' || '/',
    role: ["Student"],
    unit: ["All"]
  },
  {
    label: 'Student Management',
    icon: '/icons/people-filled.svg', // Use the Material Icon component
    icons: '/icons/people-unfilled.svg',
    url: '/student-management' || '/',
    role: ["Administrator", "Staff"],
    unit: ["All"]
  },
  {
    label: 'Staff Management',
    icon: '/icons/profile-filled.svg', // Use the Material Icon component
    icons: '/icons/profile-unfilled.svg',
    url: '/staff-management' || '/',
    role: ["Administrator"],
    unit: ["All"]
  },{
    label: 'Result Management',
    icon: '/icons/teacher-filled.svg', // Use the Material Icon component
    icons: '/icons/teacher-unfilled.svg',
    url: '/academic-performance',
    role: ["Administrator"],
    unit: ["All"]
  }, {
    label: 'E-Clearance',
    icon: '/icons/award-filled.svg', // Use the Material Icon component
    icons: '/icons/award-unfilled.svg',
    url: '/e-clearance' || '/',
    role: ["Administrator", "Staff"],
    unit: ["All"]
  },{
    label: 'Course Management',
    icon: '/icons/books-filled.svg', // Use the Material Icon component
    icons: '/icons/books-unfilled.svg',
    url: '/courses-admin' || '/',
    role: ["Administrator"],
    unit: ["All"]
  },{
    label: 'Communication',
    icon: '/icons/messages-filled.svg', // Use the Material Icon component
    icons: '/icons/messages-unfilled.svg',
    url: '/communications' || '/',
    role: ["Administrator"],
    unit: ["All"]
  },{
    label: 'E-Transcript',
    icon: '/icons/receipt-filled.svg', // Use the Material Icon component
    icons: '/icons/receipt-unfilled.svg',
    url: '/transcripts-management' || '/',
    role: ["Administrator"],
    unit: ["All"]
  },{
    label: 'Payments',
    icon: '/icons/card-filled.svg', // Use the Material Icon component
    icons: '/icons/card-unfilled.svg',
    url: '/payments',
    role: ["Administrator"],
    unit: ["All"]
  },{
    label: 'School Management',
    icon: '/icons/teacher-filled.svg', // Use the Material Icon component
    icons: '/icons/teacher-unfilled.svg',
    url: '/school-structure',
    role: ["Administrator"],
    unit: ["All"]
  }, {
    label: 'Settings',
    icon: '/icons/setting-filled.svg', // Use the Material Icon component
    icons: '/icons/setting-unfilled.svg',
    url: '/settings' || '/',
    role: ["Administrator"],
    unit: ["All"]
  }
  ];

export default menuItems;
