import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Textarea, Select, Checkbox, useToast, Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure, Heading
 } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import useFormSubmission from '../hooks/useFormSubmission';
import DataTable from '../components/DataTable';  // Replace with the actual path
import useFetch from '../hooks/useFetch';
import { useSelector } from 'react-redux';

const NewsPostPage = () => {
  const { data: news, loading, error, fetchData } = useFetch();
    const { data: category, loading: isLoading, error: isError, fetchData:fetchCategory } = useFetch();
  const [dynamicPath, setDynamicPath] = useState('/news/all');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const configs = useSelector((state)=> state.auth.config)
  const [newsData, setNewsData] = useState({
    title: '',
    content: '',
    image: null,
    category: '',
    publish: '',
    isFeatured: false, // New field for featuring news
  });
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const [message, setMessage] = useState();

  const toast = useToast();
  const headers = [
    { label: 'Date', key: 'createdAt' },
    { label: 'Title', key: 'title' },
    { label: 'Featured', key: 'featured' },
    { label: 'Status', key: 'publish' },
  ];
  
  // Make sure news.data is present before accessing its properties
  const dataHeaders = news?.data ? headers : [];
  
  
  const buttons = [
    // Define your action buttons here
  ];

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewsData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setNewsData((prevData) => ({
        ...prevData,
        image: imageFile,
      }));
    }
  };
  

  useEffect(() => {
    fetchData(dynamicPath);
    fetchCategory('news/categories');
  }, [dynamicPath]);

  
console.log(news)
  const handlePostNews = async () => {
      const formData = new FormData();
      formData.append('title', newsData.title);
      formData.append('content', newsData.content);
      formData.append('category', newsData.category);
      formData.append('isFeatured', newsData.isFeatured);
      formData.append('file', newsData.image);

      const { submitSuccess, responseBody } = await submitForm('news', formData, 'multipart');
    
    
    if (submitSuccess) {
      setMessage(responseBody?.data?.message)
    } else {
      console.error(submitError || 'Form submission failed');
    }
    }

    //   if (response.ok) {
    //     toast({
    //       title: 'News Posted Successfully',
    //       status: 'success',
    //       duration: 3000,
    //       isClosable: true,
    //     });
    //   } else {
    //     throw new Error('Failed to post news');
    //   }
    // } catch (error) {
    //   console.error('Error posting news:', error);
    //   toast({
    //     title: 'Failed to Post News',
    //     description: 'An error occurred while posting news. Please try again.',
    //     status: 'error',
    //     duration: 3000,
    //     isClosable: true,
    //   });
    // }
  

  return (
    <DashboardLayout sidebar={"two"}>
    <Box 
     borderRadius={11} px={2} py={5} 
     width={["120%", '100%' ]}>
     <Box 
       bg="white" 
       borderRadius={11} 
       mt={2} 
       p={10}  
       width={['200','100%']}
       height={'100%'}
     
     >
      <Box display={'flex'} justifyContent={'flex-end'}>
   <Button  fontFamily="Kumbh Sans"
            fontSize="13px" 
            bg={`${configs?.primaryBg}`}
            color={`${configs?.primaryColor}`}
            float="right"
             onClick={onOpen}>
        Post News
      </Button>
      </Box>
      <Heading   fontFamily="Kumbh Sans"
            fontSize="14px" 
            mb={4}>Post News</Heading>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={13}>Send Message</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Box p={2}>
      <FormControl mb={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px" >Title:</FormLabel>
        <Input type="text" name="title" fontFamily="Kumbh Sans"
            fontSize="13px"  onChange={handleInputChange} />
      </FormControl>

      <FormControl mb={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px" >Content:</FormLabel>
        <Textarea
          name="content"
          fontFamily="Kumbh Sans"
            fontSize="13px" 
          onChange={handleInputChange}
          placeholder="Enter news content here..."
        />
      </FormControl>

      <FormControl mb={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px" >Category:</FormLabel>
        <Select name="category" fontFamily="Kumbh Sans"
            fontSize="13px"  onChange={handleInputChange}>
               <option></option>
              {category?.map((item) => (
                <option key={item?._id} value={item?._id}>
                  {item?.name}
                </option>
              ))}
          {/* Add more categories as needed */}
        </Select>
      </FormControl>

      <FormControl mb={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px" >Image:</FormLabel>
        <Input type="file" name="image" 
        accept="image/*"
        fontFamily="Kumbh Sans"
        fontSize="13px" 
         onChange={handleImageChange} />
      </FormControl>

      <FormControl mb={4}>
      <Checkbox
        fontFamily="Kumbh Sans"
        fontSize="12px"
        name="isFeatured"  // Make sure this matches the key in handleInputChange
        isChecked={newsData?.isFeatured}
        onChange={handleInputChange}
      >
        Featured
      </Checkbox>
      

      </FormControl>
      <FormControl mb={4}>
      <Checkbox
        fontFamily="Kumbh Sans"
        fontSize="12px"
        name="publish"  // Make sure this matches the key in handleInputChange
        isChecked={newsData?.publish}
        onChange={handleInputChange}
      >
        Publish
      </Checkbox>
      

      </FormControl>

    </Box>
          </ModalBody>
          <Text color="red.500">{submitError}</Text>
            <Text color="green.500">{message}</Text>
      <ModalFooter>
        
      <Button 
       fontFamily="Kumbh Sans"
            fontSize="13px" 
            bg={`${configs?.primaryBg}`}
            color={`${configs?.primaryColor}`}
            float="right" 
            mr={2}
            onClick={handlePostNews}>
        {isSubmitting ? 'Submitting' : 'Post'}
      </Button>
        <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

   
    <DataTable
                data={news?.data || []}
                headers={headers}
                buttons={buttons}
                page={news?.currentPage}
                onPageChange={(newPage) =>  setDynamicPath(`/news/all/${newPage}`)}
                pageSize={news?.totalPages}
                totalItems={news?.totalItems}
                loading={loading}
                search={true}
                exports={true}
                pagination={true}
              />
    </Box>
    </Box>
    </DashboardLayout>
  );
};

export default NewsPostPage;
