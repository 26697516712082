
import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Grid,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import DataTable from '../components/DataTable';  // Replace with the actual path
import SelectableCourseTable from '../components/SelectableCourseTable'; // Import the new component
import useFetch from '../hooks/useFetch';
import useFormSubmission from '../hooks/useFormSubmission';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RegisterCourse = () => {
  const { data: coursesAvailable, loading: loadingCourses, error: errorCourses, fetchData: fetchAvailable } = useFetch();
  const [dynamicPath, setDynamicPath] = useState('/courses/user-courses');
  const dynamicParams = "";
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const navigate = useNavigate()
  const configs = useSelector((state)=> state.auth.config)
  const [data, setData] = useState();
  useEffect(() => {
    fetchAvailable(dynamicPath);
  }, [dynamicPath]);
  
  const headers = [
    { label: 'Course Code', key: 'courseId.code' },
    { label: 'Title', key: 'courseId.name' },
    { label: 'Status', key: 'courseId.status'},
    { label: 'Units', key: 'unit' },
   
  ];
  const buttons = []
  const [isAddCourseModalOpen, setIsAddCourseModalOpen] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);

  const handleCourseSelect = (course) => {
    setSelectedCourses((prevSelectedCourses) => {
      
      if (prevSelectedCourses.includes(course)) {
        // If the course is already selected, remove it
        return prevSelectedCourses.filter((selectedCourse) => selectedCourse !== course);
      } else {
        // If the course is not selected, add it
        return [...prevSelectedCourses, course];
      }
    });
  };
  

  const handleAddCourse = () => {
    setData(selectedCourses)    
    // Clear selected courses
    setSelectedCourses([]);

    // Close the modal
    setIsAddCourseModalOpen(false);
  };

const handleSubmit = async() =>{
  const { submitSuccess, responseBody } = await submitForm('registered-courses', data, 'json');

  if (submitSuccess) {
    navigate(`/courses`);
  } else {
    console.error(submitError || 'Form submission failed');
  }
}
  console.log(coursesAvailable)

  return (
    <DashboardLayout sidebar="two">
      <Box borderRadius={11} px={2} py={5} width={['120%', '100%']}>
        <Box
          bg="white"
          borderRadius={11}
          mt={2}
          p={10}
          width={['200', '100%']}
          height={['100%', '80vh']}
        >
          <Box>
            <Grid templateColumns={['1fr', 'repeat(2, 1fr)']}>
              <Text
                fontFamily="Kumbh Sans"
                fontSize="16px"
                fontWeight="500"
                lineHeight="20px"
                letterSpacing="0em"
                textAlign="left"
                color="#374151"
                mb={4}
              >
                Course Registration
              </Text>
              <Button
                justifySelf={'flex-end'}
                float="right"
                bg={`${configs?.primaryBg}`}
                color="white"
                _hover={{ bg: '#1E40AF' }}
                fontSize={13}
                mb={2}
                width={'50%'}
                onClick={() => setIsAddCourseModalOpen(true)}
              >
                Add Course
              </Button>
            </Grid>
          </Box>
          <Box mt={2}>
          <DataTable
            data={data}
            headers={headers}
            buttons={buttons}
            page={1}  // Replace with your actual page state
            onPageChange={(newPage) => console.log('Page changed to:', newPage)}
            pageSize={10}  // Replace with your actual page size state
            totalItems={100}  // Replace with your actual total items state
            loading={false}  // Replace with your actual loading state
            // search={true}  // Adjust as needed
            // exports={true}  // Adjust as needed
            // pagination={true}  // Adjust as needed
          />
           <Grid templateColumns={'1fr 2fr'} mt={"-9"} ml={5} gap={"70%"}>
            <Text fontSize={12} fontWeight={600}>Total Units</Text>
            <Text fontSize={12} display={'flex'} fontWeight={600} float="right" ml={10}>{data?.reduce((nestedAcc, value) => nestedAcc + value.unit, 0)}</Text>
          </Grid>

          {submitError && <Text fontSize={12} color={"red"}>{submitError}</Text>}
          <Text fontSize={12} mt={5}>N:B - If you're satisfied with your selection, kindly submit else reset</Text>
          <Grid templateColumns={['1fr', 'repeat(2, 1fr)']} gap={200} mt={5}>
          <Button
              
                bg={`${configs?.primaryBg}`}
                color={`${configs?.primaryColor}`}
                _hover={{ bg: '#1E40AF' }}
                fontSize={13}
                mb={2}
                width={['50%', '30%']}
                onClick={() => setData([])}
              >
                Reset
              </Button>
              <Button
                display="flex"
                float={"right"}
                bg={`${configs?.primaryBg}`}
                color={`${configs?.primaryColor}`}
                _hover={{ bg: '#1E40AF' }}
                fontSize={13}
                mb={2}
                width={['50%', '30%']}
                onClick={handleSubmit}
              >
                { isSubmitting ? 'Submitting' : 'Submit'}
              </Button>
          </Grid>
          </Box>
          <Box width={'100%'}>
            <Modal isOpen={isAddCourseModalOpen} onClose={() => setIsAddCourseModalOpen(false)}>
              <ModalOverlay />
              <ModalContent width={'100%'}>
                <ModalHeader fontSize={13} ml={5}>
                  Add Course
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody width={'100%'}>
                  <Box mt={2}>
                    <SelectableCourseTable
                      data={coursesAvailable?.data || []}
                      onCourseSelect={handleCourseSelect}
                      pageSize={10}
                    />
                  </Box>
                </ModalBody>
                <ModalFooter>
                  <Button   bg={`${configs?.primaryBg}`}
                color={`${configs?.primaryColor}`}
                _hover={{ bg: '#1E40AF' }}
                fontSize={13}
                mb={2}
                
                onClick={handleAddCourse}>
                    Add
                  </Button>
                  <Button onClick={() => setIsAddCourseModalOpen(false)}>Cancel</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default RegisterCourse;

