import React from 'react';
import { Box, Text, Button, Image, Grid } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const LandscapeCard = ({ id, image, category, createdAt, title, content }) => {
  const configs = useSelector((state) => state.auth.config);

  const limitStringToWords = (input, wordLimit) => {
    const words = input?.split(/\s+/);
    const limitedWords = words?.slice(0, wordLimit);
    return limitedWords?.join(' ');
  };

  return (
    <Box key={id} bg="white" mt={10}>
      <Grid templateColumns={['1fr', '1fr', '2fr 3fr']} gap={4} alignItems="center">
        <Box colSpan={[1, 1, 2]}>
          <Image src={configs?.api + image} alt="Landscape Image" mb={4}  borderRadius={10}/>
        </Box>
        <Box>
        <Text 
         color={`${configs?.primaryBg}`}
         fontSize="14"
         fontWeight="400"
         lineHeight="140%"
         mb={3}
        > {category?.name}    |   {new Date(createdAt)?.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        })} </Text>
        <hr style={{background: "#3A4553", width: "50%", height: "0.887px"}}></hr>
        <Text 
          mt={3}
          color="#232738"
          fontSize="16px"
          fontWeight="700"
          lineHeight="normal"
          textTransform="uppercase"
        >{title}</Text>
          <Text mt={5}>{limitStringToWords(content, 30)}</Text>
          <a
          mt={2}
          as="a"
          href={`/news/${id}`}
          target="_blank"
          rel="noopener noreferrer"
          color="#3A4553"
          fontSize="16px"
          fontWeight="400"
          lineHeight="normal"
          style={{textDecoration:"underline #3A4553"}}
          textTransform="capitalize"
          // colorScheme="blue"
        >
          Read More
        </a>
        </Box>
      </Grid>
    </Box>
  );
};

export default LandscapeCard;
