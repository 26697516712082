// DynamicForm.js
import React, { useState } from 'react';
import {
  VStack,
  Select,
  Image,
  Input,
  Button,
  Box,
  Grid,
  GridItem,
  FormLabel,
  Text
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const Step2 = ({education, formData, setFormData}) => {
 
  const configs = useSelector((state)=>state.auth.config);
  
    const [educationalHistory, setEducationalHistory] = useState(education ||
      [{
        schoolName: '',
        schoolAddress: '',
        courseOfStudy: '',
        qualificationObtained: '',
        fromDate: '',
        toDate: '',
        grade: '',
      }]);
    
      const handleInputChange = (field, value, index) => {
        const updatedHistory = [...educationalHistory];
        updatedHistory[index][field] = value;
        setEducationalHistory(updatedHistory);
        setFormData((prevData) => ({
          ...prevData,
          educationalHistory: updatedHistory,
        }));
      };
      
      const handleAdd = () => {
        setEducationalHistory([...educationalHistory, {
          schoolName: '',
          schoolAddress: '',
          courseOfStudy: '',
          qualificationObtained: '',
          fromDate: '',
          toDate: '',
          grade: '',
        }]);
      };
    
      const handleRemove = (index) => {
        const updatedHistory = [...educationalHistory];
        updatedHistory.splice(index, 1);
        setEducationalHistory(updatedHistory);
        setFormData((prevData) => ({
          ...prevData,
          educationalHistory: updatedHistory,
        }));
      };

  return (
    <VStack align="start" spacing={4} minWidth={"100%"}>
         {educationalHistory?.map((history, index) => (
        <Grid
        templateColumns={['1fr', '1fr 1fr']} // One column on mobile, two columns on desktop
        gap={4}
        key={index}
        width="100%"
        style={{fontFamily: 'Kumbh Sans', fontSize: '12px !important', fontWeight: 500
          }}
      >
      <GridItem>
        <FormLabel color="#374151" fontSize={13}
        > Name of School <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
        <Input
            fontSize={13}
            value={history?.schoolName}
            onChange={(e) => handleInputChange('schoolName', e.target.value, index)}
            />
        </GridItem>
        <GridItem>
        <FormLabel color="#374151" fontSize={13}
        > Location <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>
        <Input
          fontSize={13}
        value={history?.schoolAddress}
        onChange={(e) => handleInputChange('schoolAddress', e.target.value, index)}
        />
        </GridItem>
       
        <GridItem>
        <FormLabel color="#374151" fontSize={13}
        > Qualification Obtained <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel>        
        <Input
          fontSize={13}
        value={history?.qualificationObtained}
        onChange={(e) => handleInputChange('qualificationObtained', e.target.value, index)}
        />
        </GridItem>
        <GridItem>
        <FormLabel color="#374151" fontSize={13}
        > Course of Study </FormLabel>
        <Input
          fontSize={13}
        value={history?.courseOfStudy}
        onChange={(e) => handleInputChange('courseOfStudy', e.target.value, index)}
        />
        </GridItem>
        <GridItem>
        <FormLabel color="#374151" fontSize={13}
        > From <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel> 
        <Input
        type="date"
          fontSize={13}
        value={history?.fromDate}
        onChange={(e) => handleInputChange('fromDate', e.target.value, index)}
        />
        </GridItem>
        <GridItem>
        <FormLabel color="#374151" fontSize={13}
        > To <Box display="inline-flex" color={"red !important"}>*</Box></FormLabel> 
        <Input
        type="date"
          fontSize={13}
        value={history?.toDate}
        onChange={(e) => handleInputChange('toDate', e.target.value, index)}
        />
        </GridItem>
        <GridItem>
        <FormLabel color="#374151" fontSize={13}
        > Grade </FormLabel>        
        <Input
          fontSize={13}
          value={history?.grade}
            onChange={(e) => handleInputChange('grade', e.target.value, index)}
        />
        </GridItem>
       
          { index !== 0 ? (
        <Button mt={"100px"} bg={"#FEEEEE"} fontSize={13} color={"#F92F2F"} onClick={() => handleRemove(index)}>
            Remove
             <Image src="/icons/delete.svg" />
          </Button>): (<Box mt={"125px"}></Box>)}
        </Grid>))}
        <Button bg={"#F6F6FE"} mt={"-55px"} fontSize={13} color={`${configs?.primaryBg}`} onClick={handleAdd}>
        <Image src="/icons/add-circle.svg" mr="2" /> Add History 
      </Button>
      <Text fontSize={10} color="red">For SSCE/O'Level result, leave course of study field and grade blank</Text>
    </VStack>
  );
};

export default Step2;
