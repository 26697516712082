// App.js

import React, { Suspense } from 'react';
import { Spinner, Box } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import routes from './index';

const MainRoute = () => {
  return (
    <Router>
        <Suspense fallback={<Box justifyContent="center" alignItems="center" display="flex"><Spinner /></Box>}>
          <Routes>
            {routes.map(route => (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                element={route.component}
               
              />
            ))}
          </Routes>
        </Suspense>
    
    </Router>
  );
}

export default MainRoute;
