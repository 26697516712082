import React from 'react';
import { ChakraProvider, Box, Image, Text } from '@chakra-ui/react';

const AdminSideCard = ({ src, label, count }) => {
  return (
  
      <Box border="1px solid #F5F5F5" borderRadius="sm" alignItems={'center'} overflow="hidden" display={'flex'} height={"53px"}>
        <Image src={`/icons/${src}`} alt="Enrollment"  width={"25px"}/>
        <Text
          textAlign="center"
          color="#232738"
          fontSize="12px"
          fontWeight="500"
          p={2}
        >
          {label}   ({count})
        </Text>
     
      </Box>
    
  );
};

export default AdminSideCard;
