import React, { useState } from 'react';
import { Input, Button, FormControl, FormLabel, Box, Text, Textarea, Grid } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import useFormSubmission from '../hooks/useFormSubmission';
import { useSelector } from 'react-redux';

const ApplyCard = () => {
  const [applyCards, setApplyCards] = useState({
    applyCards: [
      {
        title: "",
        uploadSrc: "",
        description: "",
        buttonText: "",
      },
    ],
  });

  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const [message, setMessage] = useState();
  const configs = useSelector((state) => state.auth.config);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedApplyCards = { ...applyCards };
    updatedApplyCards.applyCards[index] = { ...updatedApplyCards.applyCards[index], [name]: value };
    setApplyCards(updatedApplyCards);
  };

  const handleImageUpload = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const updatedApplyCards = { ...applyCards };
      updatedApplyCards.applyCards[index] = { ...updatedApplyCards.applyCards[index], uploadSrc: file };
      setApplyCards(updatedApplyCards);
    }
  };

  const handleAddField = () => {
    setApplyCards((prevData) => ({
      ...prevData,
      applyCards: [...prevData.applyCards, { title: "", uploadSrc: "", description: "", buttonText: "" }],
    }));
  };

  const handleRemoveField = (index) => {
    const updatedApplyCards = { ...applyCards };
    updatedApplyCards.applyCards.splice(index, 1);
    setApplyCards(updatedApplyCards);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    applyCards.applyCards.forEach((card, index) => {
      Object.keys(card).forEach((key) => {
        if (key === 'uploadSrc') {
          formData.append(`${key}-${index}`, card[key]);
        } else {
          formData.append(`${key}-${index}`, card[key]);
        }
      });
    });

    const { submitSuccess, responseBody } = await submitForm('configs/cards', formData, 'multipart');

    if (submitSuccess) {
      setMessage(responseBody?.data?.message);
    } else {
      console.error(submitError || 'Form submission failed');
    }
  };

  return (
    <DashboardLayout sidebar={"two"}>
      <Box borderRadius={11} px={2} py={5} width={["120%", '100%']}>
        <Box bg="white" borderRadius={11} mt={2} p={20} width={['200', '100%']} height={'100%'}>
          <Text fontFamily="Kumbh Sans" fontSize="13px" fontWeight={700} mb={4}>
            Apply Cards
          </Text>
          <form onSubmit={handleSubmit}>
          
              {applyCards.applyCards.map((card, index) => (
                <div key={index}>
                    <Grid templateColumns={["1fr", "2fr 2fr"]} gap={4} mt={10}>
                  <FormControl>
                    <FormLabel fontSize={13}>Title</FormLabel>
                    <Input
                      type="text"
                      fontSize={13}
                      placeholder="APPLY"
                      name={`title-${index}`}
                      value={card.title}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel fontSize={13}>Upload Source</FormLabel>
                    <Input
                      type="file"
                      accept="image/*"
                      fontSize={13}
                      name={`uploadSrc-${index}`}
                      onChange={(e) => handleImageUpload(e, index)}
                    />
                  </FormControl>

                  <FormControl mt={2}>
                    <FormLabel fontSize={13}>Button Text</FormLabel>
                    <Input
                    fontSize={13}
                      type="text"
                      placeholder="Apply Now"
                      name={`buttonText-${index}`}
                      value={card.buttonText}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </FormControl>
                  </Grid>
                  <FormControl mt={2}>
                    <FormLabel fontSize={13}>Description</FormLabel>
                    <Textarea
                    fontSize={13}
                      // placeholder="Apply to any of our programmes and get the best out of your academics"
                      name={`description-${index}`}
                      value={card.description}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </FormControl>
                  {index !== 0 && (
                  <Button fontSize={13}
                  colorScheme="red" mt={2} onClick={() => handleRemoveField(index)}>
                    Remove Field
                  </Button>
                  )}
                </div>
              ))}
            

            <Button  bg={`${configs?.primaryBg}`}
            color={`${configs?.primaryColor}`} fontSize={13} mt={4} onClick={handleAddField}>
              Add Field
            </Button>

            <Text color="red.500">{submitError}</Text>
            <Text color="green.500">{message}</Text>

            <Button
              type="submit"
              mt={4}
              fontFamily="Kumbh Sans"
              fontSize="13px"
              bg={`${configs?.primaryBg}`}
              color={`${configs?.primaryColor}`}
              float="right"
            >
              {isSubmitting ? '...Submitting' : 'Submit'}
            </Button>
          </form>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ApplyCard;
