import React, { useState, useEffect } from 'react';
import { Box, Text, Grid} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import DataTable from '../components/DataTable';  // Replace with the actual path
import useFetch from '../hooks/useFetch';
import { useSelector } from 'react-redux';
import Headers from '../components/Headers';
const CourseHistory = () => {
  const { data: courseHistory, loading: loadingCourses, error: errorCourses, fetchData: fetchHistory } = useFetch();
  const configs = useSelector((state) => state.auth.config);
  const user = useSelector((state) => state?.auth?.user);
  const headers = [
    { label: 'Course Code', key: 'code' },
    { label: 'Title', key: 'name' },
    { label: 'Status', key: 'status'  },
    { label: 'Units', key: 'unit' },
   
  ];

  useEffect(() => {
    fetchHistory("registered-courses/user");
  }, []);

  const buttons = [
    // Define your action buttons here
  ];

  
  return (
    <DashboardLayout sidebar="two">
      <Box borderRadius={11} px={2} py={5} width={["120%", '100%']}>
        <Box
          bg="white"
          borderRadius={11}
          mt={2}
          p={10}
          width={['200', '100%']}
         miHeight={['100%', '80vh']}
        >
          <Headers logo={configs?.api+configs?.logoDark} title={configs?.schoolName} />
          {courseHistory && courseHistory.map((item)=>(
            <>
          <Grid templateColumns={'1fr 2fr'} ml={5}>
          <Text fontFamily="Kumbh Sans" fontSize="12" fontWeight="500" textAlign="left" color="#374151">
              Name
          </Text>
          <Text fontFamily="Kumbh Sans" fontSize="12" fontWeight="500" textAlign="left" color="#374151">
              {user.firstName} {user.lastName}
          </Text>
          <Text fontFamily="Kumbh Sans" fontSize="12" fontWeight="500" textAlign="left" color="#374151">
              Level
          </Text>
          <Text fontFamily="Kumbh Sans" fontSize="12" fontWeight="500" textAlign="left" color="#374151">
              {item.levelName} 
          </Text>
          <Text fontFamily="Kumbh Sans" fontSize="12" fontWeight="500" textAlign="left" color="#374151">
              Semester
          </Text>
          <Text fontFamily="Kumbh Sans" fontSize="12" fontWeight="500"  textAlign="left" color="#374151" >
               {item.semesterName}
          </Text>
          </Grid>
    
          <DataTable
            data={item.courses}
            headers={headers}
            buttons={buttons}
            page={1}  // Replace with your actual page state
            onPageChange={(newPage) => console.log('Page changed to:', newPage)}
            pageSize={10}  // Replace with your actual page size state
            totalItems={100}  // Replace with your actual total items state
            loading={false}  // Replace with your actual loading state
            // search={true}  // Adjust as needed
            exports={true}  // Adjust as needed
            // pagination={true}  // Adjust as needed
          />
          <Grid templateColumns={'1fr 2fr'} mt={"-9"} ml={5} gap={"70%"}>
            <Text fontSize={12} fontWeight={600}>Total Units</Text>
            <Text fontSize={12} display={'flex'} fontWeight={600} float="right" ml={10}>{item.courses.reduce((nestedAcc, value) => nestedAcc + value.unit, 0)}</Text>
          </Grid>
          </>  ))}     </Box>
      </Box>
    </DashboardLayout>
  );
};

export default CourseHistory;
