// Dashboard.js
import React, { useEffect } from 'react';
import { Box,  SimpleGrid } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import ApplicationLink from '../components/ApplicationLink';

const StudentManagement = () => {
  
  return (
    <DashboardLayout sidebar={"two"}>
        <Box borderRadius={11} px={2} py={5} width={["120%", '100%' ]}>
      <Box 
        bg="white" 
        borderRadius={11} 
        mt={2} 
        p={5}  
        width={['200','100%']}
        height={['100%', '80vh']}
        display={'flex'}
      >
        <SimpleGrid
          height={['100%', '50%']}
          mt={5}
          flexDirection={['row','column']}
          columns={[1, 4]} // Set the number of columns based on your design
          spacing={4}
          gap={5}
          px={{ base: 10, md: 50 }}
          justifyContent={'center'}
        >
            <ApplicationLink
              link={'/enrolment'}
              icon={'menu-board.svg'}
              title='Enrolment'
              ></ApplicationLink>
                  <ApplicationLink
              link={'/student-profile'}
              icon={'menu-document-text.svg'}
              title='Student Profile'
              mb={5}
            />
            <ApplicationLink
              link={'/academic-performance'}
              icon={'menu-teacher.svg'}
              title='Academic Performance'
              mb={5}
            />
          
                {/* <ApplicationLink
              link={'/complaints'}
              icon={'menu-receipt-edit.svg'}
              title='Student Complaints'
              ></ApplicationLink>
            */}
          
           {/* <ApplicationLink
              link={'#'}
              icon={'menu-setting-4.svg'}
              title='Transfer & Withdraw Students'
              ></ApplicationLink>

            <ApplicationLink
              link={'#'}
              icon={'printer.svg'}
              title='Print Admission Form'
              ></ApplicationLink> */}
        
          </SimpleGrid>
        </Box>
    </Box>  
    </DashboardLayout>
  );
};

export default StudentManagement;
