import React, { useState, useEffect } from 'react';
import { Box, Text, Select, Button } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import useFormSubmission from '../hooks/useFormSubmission';
import useFetch from '../hooks/useFetch';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const GenerateFees = () => {
  const configs = useSelector((state) => state.auth.config);
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const [amount, setAmount] = useState();
  const [message, setMessage] = useState();
  const user = useSelector((state) => state?.auth?.user);
  
  const navigate = useNavigate();
  const location = useLocation();
  const { data: sessionData, loading: sessionLoading, error: sessionError, fetchData: fetchSession } = useFetch("Auth API");
  const [selectedSession, setSelectedSession] = useState('');
  const [selectedSessionName, setSelectedSessionName] = useState('');
  const [selected, setSelected] = useState('');
   
  const handleFormSubmit = async () => {
    const formData = {
      payerName: user.firstName + " " + user.lastName,
      payerEmail: user.email,
      amount: amount,
      payerPhone: user.phoneNumber,
      description: 'School Fees for ' + selectedSessionName + " Session",
      paymentId: selected
    };

    const { submitSuccess, responseBody } = await submitForm('payments/generate', formData);
    
    if (submitSuccess) {
      setMessage(responseBody?.data?.message);
      navigate(`/payment/generated/${responseBody?.data?._id}`);
    } else {
      console.error(submitError || 'Form submission failed');
    }
  };

  useEffect(() => {
    fetchSession(`/school-fees/byUser`);
  }, []);

  const handleSessionChange = (e) => {
    const selectedId = e.target.value;
    setSelected(selectedId);

    const selectedSessionObject = sessionData.find(session => session._id === selectedId);
    if (selectedSessionObject) {
      setAmount(selectedSessionObject?.breakdown?.reduce((accumulator, current) => accumulator + Number(current.amount), 0));
      setSelectedSession(selectedSessionObject?.session._id);
      setSelectedSessionName(selectedSessionObject?.session.name);
    }
  };

  return (
    <DashboardLayout sidebar="two">
      <Box borderRadius={11} px={2} py={5} width={["120%", '100%']}>
        <Box
          bg="white"
          borderRadius={11}
          mt={2}
          p={10}
          width={['200', '100%']}
          height={['100%', '80vh']}
        >
          <Text
            fontFamily="Kumbh Sans"
            fontSize="16px"
            fontWeight="500"
            lineHeight="20px"
            letterSpacing="0em"
            textAlign="left"
            color="#374151"
            mb={4}
          >
            Select a session to generate Fees
          </Text>
          <Text fontSize="13px" mb={4} fontWeight={400}>
            Select a session
          </Text>
          <Select fontSize={13} value={selected} mb={1} onChange={handleSessionChange}>
            <option value="">Select Session</option>
            {sessionData &&
              sessionData.map((session) => (
                <option key={session._id} value={session._id}>
                  {session.session.name}
                </option>
              ))}
          </Select>
          <Button
            float="right"
            bg={`${configs?.primaryBg}`}
            color={`${configs?.primaryColor}`}
            mt={5}
            fontSize={13}
            onClick={handleFormSubmit}
          >
            Generate Fees
          </Button>
        </Box>
        <Text color="red.500">{submitError}</Text>
        <Text color="green.500">{message}</Text>
      </Box>
    </DashboardLayout>
  );
};

export default GenerateFees;
