// Dashboard.js
import React, { useEffect, useState} from 'react';
import { Box, Text, Image, Grid, GridItem, SimpleGrid, useMediaQuery } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import ScrollableCard from '../components/ScrollableCard';
import { useSelector } from 'react-redux';
import InformationCard from '../components/InformationCard';
import DataTable from '../components/DataTable';
import useFetch from '../hooks/useFetch';

const Dashboard = () => {
  const user = useSelector((state) => state?.auth?.user);
  const firstName = useSelector((state) => state?.auth?.user?.firstName);
  const config = useSelector((state) => state.auth.config);
  const { data: reg, loading: regLoading, error: regError, fetchData:fetchReg } = useFetch(); 
  const { data: payment, loading: paymentLoading, error: paymentError, fetchData:fetchPayment } = useFetch(); 
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const { data: staff, loading: staffLoading, error: staffError, fetchData:fetchStaff } = useFetch();
  const { data: students, loading: studentLoading, error: studentError, fetchData: fetchStudent } = useFetch();
  const { data: count, loading: countLoading, error: countError, fetchData:fetchCount } = useFetch();

  const headers = [
    { label: 'Student', key: 'userId.firstName' },
    { label: 'Department', key: 'userId.lastName' },
    { label: 'Email', key: 'userId.email' },
    { label: 'Matric No', key: 'matric' },
  ];
  const buttons = [
    {
      color: 'darkblue',
      label: 'View',
      onClick: (item) => handleView(item),
    },
    {
      color: '#fde428',
      label: 'Edit',
      onClick: (item) => handleEdit(item),
    },
    // ... additional buttons
  ];
const handleView = () =>{

}
const handleEdit = () =>{

}
useEffect(() => {
  fetchCount("registered-courses/count");
  fetchStudent("students/dashboard");
  fetchStaff("users/staff");
  fetchPayment("payments/findByUser2");
  fetchReg('/students/findByUser')
}, []);

  return (
    <DashboardLayout sidebar={"two"}>
    <Box  borderRadius={11} px={2} py={5} width={"100%"} minHeight={isMobile ? '100vh': ''}>
    <Box position="relative" mb={2} >
    <Box height={160} 
    borderRadius={20}
    >
      <Image src={`${config?.api}/uploads/dash.jpg`} width="100%" 
      alt="Dashboard" height={"100%"} 
      borderRadius={20}
      position={'fit-content'}
      
      />

      <SimpleGrid
        columns={[1, 2]}
        spacing={4}
        position="absolute"
        top={["60%","50%"]}
        left={["35%", "38%"]}
        transform="translate(-50%, -50%)"
        color="white"
        zIndex={9999}
      
      >
        <Box p={[2,4]} width={"120%"}>
          <Text
            fontFamily="Kumbh Sans"
            fontSize={["12px","20px"]}
            fontWeight="600"
            lineHeight={["10px","25px"]}
            textAlign="left"
            color="#F3F2F2"
            width={'100%'}
          >
            Welcome {firstName}
          </Text>
          {user?.role?.name == "Student" ? 
          <>
          <Text  color="#F3F2F2" fontWeight="600" 
           width={'100%'}
          fontSize={["12px","14px"]}>Programme: {reg?.programme?.name}</Text>
          <Text fontSize={[10,12]} color="#DAD7D7">Course: {reg?.course.name}</Text>
          </> : <Text  color="#F3F2F2" fontWeight="600" 
           width={'100%'}
          fontSize={["12px","14px"]}> You are logged in as a {user?.role?.name}</Text>}
        </Box>
        {/* Add additional grid items as needed */}
      </SimpleGrid>
      <Box background={config?.primaryBg+98} zIndex={998}
      borderRadius={20}
          position="absolute" top="2px" width={["80%", "100%"]} height={[120, 160]}></Box>
      </Box>
    </Box>

   {user?.role?.name == "Student" ? (
    <>
      <Grid
      templateColumns={['1fr', '1fr 1fr 1fr 1fr']} 
      justifyContent="center"
      alignItems={["left", "center"]}
      textAlign="center"
      mt={10}
      bg="white"
      p={5}
      py={10}
      borderRadius={10}
    >
      {[config?.currentSemester.name, config?.currentSession.name, `${count?.data?.length || 0}/Courses`, `${count?.data?.reduce((acc, value)=>{ return acc+ value?.courseId?.unit},0) || 0}/Units`].map((item, index) => (
        <GridItem key={item}  borderRight={!isMobile && index < 3 ? '1px solid gray' : 'none'}
          borderBottom={isMobile && index < 3 ? '1px solid gray' : 'none'}
        >
         
            <SimpleGrid 
              columns={2} 
              alignContent={'center'} 
              justifyContent={["left",'center']}  
              display={'flex'}
              spacing={10}
              > 

              <Image src="/icons/dash.svg" justifyItems={'flex-end'}/>
            <Box textAlign={'left'} display={'flex'} flexDirection={'column'} ml={5} mb={2}>
              <Text color="##929EAE" mr={1}>{item?.split("/")[0] || item?.split(" ")[0] }  </Text>
              <Text color="#1B212D" fontWeight={700} >{item?.split("/")[1] || item?.split(" ")[1]}</Text>
            </Box>
            </SimpleGrid>
           
  
        </GridItem>
      ))}
    </Grid>
    {!isMobile &&(
    <ScrollableCard data={payment} loading={paymentLoading}></ScrollableCard>)}
    </>
   ):(
   <Box><Grid
    templateColumns="repeat(3, 1fr)"
    gap={2}
    mt={9}
    // maxW="600px"
   // margin="0 auto"
  >
    <InformationCard title="Total No of Students" value={students?.data?.numberOfStudent} semesterInfo="This semester" />
      
    <InformationCard title="Total Enrolment" value={students?.data?.numberOfEnrolled} semesterInfo="This semester" />
     
    <InformationCard title="Total No of Staffs" value={staff?.data?.length} semesterInfo="This semester" />
   
  </Grid>
  <Box mt={9}>
    <Text 
      fontFamily="Kumbh Sans"
      fontSize={15}
      fontWeight={700}
      my={5}
    >Latest Students</Text>
  <DataTable
        data={students?.data?.shortTable || []}
        headers={headers}
        buttons={[]}
        page={students?.currentPage}
    
        pageSize={students?.totalPages}
        totalItems={students?.totalItems}
        loading={studentLoading}
        search={false}
        exports={false}
        pagination={false}
      />
    </Box>
  </Box>)}

    </Box>
    </DashboardLayout>
  );
};

export default Dashboard;
