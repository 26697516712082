import React, { useState, useEffect } from 'react';
import {Select, Box, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Input, FormControl, FormLabel } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import DataTable from '../components/DataTable';  // Replace with the actual path
import useFetch from '../hooks/useFetch';
import useFormSubmission from '../hooks/useFormSubmission';
import { useSelector } from 'react-redux';
const Clearance = () => {
  const config = useSelector((state)=>state?.auth?.config)
  const { data, loading, error, fetchData } = useFetch('Auth API');
  const { data: roles, loading: rolLoad, error: roleErr, fetchData: fetchRoles } = useFetch('Auth API');
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const [dynamicPath, setDynamicPath] = useState("clearance/stages2");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen2, setModalOpen2] = useState(false);
  const [message,setMessage] = useState("");
  const [formData, setFormData] = useState({
    stages: []
  });

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModal2 = () => {
    setModalOpen2(true);
  };

  const handleCloseModal2 = () => {
    setModalOpen2(false);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
  
    // Update the state with the new form data
    if (name === "stages") {
      // If the name is "stages", directly update the stages array
      const updatedStages = [...formData.stages, value]; // Append the new value to the stages array
      const filteredStages = updatedStages.filter(stage => stage !== null); // Remove null values from the stages array
  
      setFormData((prevFormData) => ({
        ...prevFormData,
        stages: filteredStages,
      }));
    } else {
      // For other fields, update the state as usual
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  
  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Type', key: 'type' },
    { label: 'No of Stages', key: 'stages.length' },
    { label: 'Level', key: 'level' },
  ];
  const handleSubmit2 = async () => {
    
    const { submitSuccess, responseBody } = await submitForm(`clearance/stages/${formData?.id}`, formData);
    
    
    if (submitSuccess) {
      setMessage(responseBody?.data?.message)
   };
  }
  const handleSubmit = async () => {
    
    const { submitSuccess, responseBody } = await submitForm('clearance/stages', formData);
    
    
    if (submitSuccess) {
      setMessage(responseBody?.data?.message)
   };
  }
  const handleEdit = (item) =>{
    setFormData((prevFormData) => ({
      ...prevFormData,
      id: item._id,
      name: item.name,
      level: item.level,
      stages: item.stages,
    }));
    
    handleOpenModal2()
  }
  const buttons = [
    {
      color: 'darkblue',
      label: 'Edit',
      onClick: (item) => handleEdit(item),
    }
  ];
  // Fetch roles when the component mounts
  useEffect(() => {
    fetchData(dynamicPath);
    fetchRoles('clearance/perm/all')
  }, [dynamicPath]);
console.log(roles)
  return (
    <DashboardLayout sidebar="two">
      <Box borderRadius={11} px={2} py={5} width={["120%", '100%']}>
        <Box
          bg="white"
          borderRadius={11}
          mt={2}
          p={5}
          width={['200', '100%']}
          height={['100%', '100%']}
        >
          <Text fontFamily="Kumbh Sans" fontSize="16px" fontWeight="500" lineHeight="20px" letterSpacing="0em" textAlign="left" color="#374151" mb={4}>
            Clearance Stages
          </Text>
          <Box display={'flex'} justifyContent={'flex-end'}>
          <Button onClick={handleOpenModal} fontSize={13} bg={config?.primaryBg}
          color={config?.primaryColor}
          >Create New</Button></Box>
          <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader fontSize={13}>Add Stages</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl>
                  <FormLabel fontSize={13}>Clearance Stage</FormLabel>
                  <Input 
                      name="name"
                      onChange={handleChange}
                      fontSize={13}
                />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={13}>Type</FormLabel>
              <Select
                name="type"
                onChange={handleChange}
                fontSize={13}
                >
                  <option></option>
                <option value="admission">Admission Clearance</option>
                <option value="graduating">Graduate Clearance</option>
              </Select>
                
                </FormControl>
            

            <FormControl>
                  <FormLabel fontSize={13}>Multiple Stage</FormLabel>
              <Select
                name="multiple"
                onChange={handleChange}
                fontSize={13}
                >
                  <option></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Select>
                
                </FormControl>
                {formData.multiple === 'yes' ? (
                        <>
                            <FormControl>
                            <FormLabel fontSize={13}>User Role 1</FormLabel>
                            <Select
                                name="stages"
                                onChange={handleChange}
                                fontSize={13}
                            >
                              <option></option>
                              {rolLoad ? <p>Loading...</p> : (roles?.data?.map((item)=>
                                <option value={item?._id}>{item?.name}</option>
                              ))
                              }
                              </Select>
                            </FormControl>
                            <FormControl>
                            <FormLabel fontSize={13}>User Role 2</FormLabel>
                            <Select
                                name="stages"
                                onChange={handleChange}
                                fontSize={13}
                            >
                              <option></option>
                              {rolLoad ? <p>Loading...</p> : (roles?.data?.map((item)=>
                                <option value={item?._id}>{item?.name}</option>
                              ))
                              }
                              </Select>
                            </FormControl>
                        </>
                        ): (   <FormControl>
                            <FormLabel fontSize={13}>User Role</FormLabel>
                            <Select
                                name="stages"
                                onChange={handleChange}
                                fontSize={13}
                            >
                              <option></option>
                              {rolLoad ? <p>Loading...</p> : (roles?.data?.map((item)=>
                                <option value={item?._id}>{item?.name}</option>
                              ))
                              }
                              </Select>
                            </FormControl>)}
            
            <FormControl>
                  <FormLabel fontSize={13}>Stage No.</FormLabel>
              <Input placeholder="Stage"  
                name="level"
                onChange={handleChange}
                fontSize={13}
                />
                </FormControl>

                {submitError && <Text fontSize={13} color="red">{submitError}</Text>}
                {message && <Text fontSize={13} color="green">{message}</Text>}
              </ModalBody>
              <ModalFooter>
                <Button fontSize={13} mr={3} onClick={handleCloseModal}>
                  Close
                </Button>
                <Button onClick={handleSubmit} fontSize={13} bg={config?.primaryBg}
                  color={config?.primaryColor}>
                  Submit
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <DataTable
            data={data?.data}
            headers={headers}
            buttons={buttons}
            page={data?.currentPage}
            onPageChange={(newPage) => setDynamicPath(`clearance/stages2/${newPage}`)}
            pageSize={data?.totalPage}
            totalItems={data?.totalItems}
            loading={loading}
            search={true}
            exports={true}
            pagination={true}
          />
        </Box>
        <Modal isOpen={isModalOpen2} onClose={handleCloseModal2}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader></ModalHeader>
              <ModalCloseButton />
              <ModalBody>
              <FormControl>
                  <FormLabel fontSize={13}>Name</FormLabel>
                <Input
                  fontSize={13}
                  value={formData?.name}
                  onChange={handleChange}
                />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={13}>Type</FormLabel>
              <Select
                name="type"
                onChange={handleChange}
                fontSize={13}
                >
                  <option></option>
                <option value="admission">Admission Clearance</option>
                <option value="graduating">Graduate Clearance</option>
              </Select>
                
                </FormControl>
            

            <FormControl>
                  <FormLabel fontSize={13}>Multiple Stage</FormLabel>
              <Select
                name="multiple"
                onChange={handleChange}
                fontSize={13}
                >
                  <option></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Select>
                
                </FormControl>
                {formData.multiple === 'yes' ? (
                        <>
                            <FormControl>
                            <FormLabel fontSize={13}>User Role 1</FormLabel>
                            <Select
                                name="stages"
                                onChange={handleChange}
                                fontSize={13}
                            >
                              <option></option>
                              {rolLoad ? <p>Loading...</p> : (roles?.data?.map((item)=>
                                <option value={item?._id}>{item?.name}</option>
                              ))
                              }
                              </Select>
                            </FormControl>
                            <FormControl>
                            <FormLabel fontSize={13}>User Role 2</FormLabel>
                            <Select
                                name="stages"
                                onChange={handleChange}
                                fontSize={13}
                            >
                              <option></option>
                              {rolLoad ? <p>Loading...</p> : (roles?.data?.map((item)=>
                                <option value={item?._id}>{item?.name}</option>
                              ))
                              }
                              </Select>
                            </FormControl>
                        </>
                        ): (   <FormControl>
                            <FormLabel fontSize={13}>User Role</FormLabel>
                            <Select
                                name="stages"
                                onChange={handleChange}
                                fontSize={13}
                            >
                              <option></option>
                              {rolLoad ? <p>Loading...</p> : (roles?.data?.map((item)=>
                                <option value={item?._id}>{item?.name}</option>
                              ))
                              }
                              </Select>
                            </FormControl>)}
            
            <FormControl>
                  <FormLabel fontSize={13}>Stage No.</FormLabel>
              <Input placeholder="Stage"  
                name="level"
                onChange={handleChange}
                fontSize={13}
                />
                </FormControl>
                {submitError && <Text fontSize={13} color="red">{submitError}</Text>}
                {message && <Text fontSize={13} color="green">{message}</Text>}
              </ModalBody>
              <ModalFooter>
                <Button 
                fontSize={13}
                mr={3} onClick={handleCloseModal2}>
                  Close
                </Button>
                <Button onClick={handleSubmit2} 
                fontSize={13} bg={config?.primaryBg}
                color={config?.primaryColor}
                >
                  {isSubmitting ? '...isSubmitting' : 'Submit'}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
      </Box>
    </DashboardLayout>
  );
};

export default Clearance;
