import React, {useState} from 'react';
import {
  Box,
  Flex,
  Image,
  Input,
  Button,
  VStack,
  Text,
  useMediaQuery,
  FormLabel,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import useFormSubmission from '../hooks/useFormSubmission'; // Import the useFormSubmission hook
import { useSelector } from 'react-redux';
const ResetPasswordPage = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const { config } = useSelector((state) => state.auth);

  const [success, setSuccess] = useState()
  const [formData, setFormData] = useState({
    email: '',
  });

  // Initialize the useFormSubmission hook with the desired API name
  const { isSubmitting, submitError, submitForm } = useFormSubmission('Auth API');


  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  // Handle form submission
  const handleResetPassword = async () => {
    try {
      setSuccess("");
      // Call the submitForm function from the hook
      const result = await submitForm('auth/forgot-password', formData, 'json');

      if (result.submitSuccess) {
        // Handle successful registration, e.g., redirect or show a success message
        setSuccess(result.responseBody.message);
        console.log('Registration successful:', result.responseBody);
      } else {
        // Handle registration failure, show an error message
        console.error('Registration failed:', result.responseBody);
      }
    } catch (error) {
      console.error('Error during registration:', error);
    }
  };
  return (
    <Flex h="100vh" align="center" justify="center">
      {isLargerThan768 && (
        <Box w="50%">
          {/* Right side image */}
          <Image src="/images/register.png" alt="ResetPassword Image" objectFit="cover" w="100%" h="100%" />
        </Box>
      )}

      <Box w={{ base: '100%', md: '50%' }} 
      p={4}
      // display="flex"
      padding="4"
      flexDirection="column"
      // alignItems="center"
     
      >
        {/* Left side ResetPassword box */}
        <VStack 
        // spacing={4} 
        align="stretch" 
      
        padding={["20px 27px", "70px 100px"]}
        flexDirection="column"
        // alignItems="center"
        // gap="15px"
        >
          <Box 
          display={'flex'}
          justifyContent={'center'}
          alignItems="center">
          <img src={config?.api+config?.logoDark} width={"200px"} />
          </Box>
          <Text textAlign="center" color="#87898E" mb={5} fontWeight={600}>
          Reset Password
          </Text>
          

          <FormLabel color="#87898E" my={1}
           fontSize="14px"
           fontStyle="normal"
           fontWeight="500"
           lineHeight="17.997px"
           >Email</FormLabel>
          <Input type="email" 
          name={"email"}
          onChange={handleInputChange} 
          // placeholder="Username" 
          />

        {success && <Text color="green.500" fontSize={12}>{success}</Text>}
        {submitError && <Text color="red.500" fontSize={12}>{submitError}</Text>}
           <Button
            borderRadius="7.261px"
            background={`${config?.primaryBg}`}
            color={`${config?.primaryColor}`}
            mt={6}
            size="lg"
            onClick={handleResetPassword} // Call handleResetPassword when the button is clicked
            disabled={isSubmitting} // Disable the button during submission
          >
            {isSubmitting ? 'Reseting...' : 'Reset Password'}
          </Button>
          <Link to='/login'>
          
          </Link>
        </VStack>
      </Box>
    </Flex>
  );
};

export default ResetPasswordPage;
