import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
  Button,
  Text,
  Select
} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import useFetch from '../hooks/useFetch';
import useFormSubmission from '../hooks/useFormSubmission';
import DataTable from '../components/DataTable';
import { useSelector } from 'react-redux';
const GenerateResult = () => {
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const [message, setMessage] = useState()
  const configs = useSelector((state)=> state.auth.config)
  const { data: session, loading: loadingS, error: errorS, fetchData:fetchSession } = useFetch();
  const { data: semester, loading: loadingSem, error: errorSem, fetchData:fetchSemester } = useFetch();
  const [requestInfo, setRequestInfo] = useState({
    session: '',
    semester: ''
  });
  const [result, setResult] = useState([])

  const headers = [
    {
      label: 'Course', key: 'course.courseId.name'},
      {label: 'Unit', key: 'unit'},
      {label: 'Attnd.', key: 'ca1'},
      {label: 'Assmt.', key: 'ca2'},
      {label: 'Mid Term', key: 'ca3'},
     { label: 'Exam', key: 'exam'},
     {label: 'Grade', key: 'grade'},
     {label: 'Approval', key: 'approval'},
    ]
  const buttons = []
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRequestInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async() => {
    // Add your logic to handle the form submission
    setResult([])
    console.log('Form Submitted:', requestInfo);
    // You can make an API call or perform other actions here
    const { submitSuccess, responseBody } = await submitForm('results/byUser', requestInfo);
    if (submitSuccess){
      console.log(responseBody)
        setResult(responseBody.data)
        // navigate(`/payment/generated/${responseBody?.data?._id}`);
    }
  };
  useEffect(() => {
    fetchSession('sessions');
    fetchSemester('semesters');
  }, []);
  

  
  return (
    <DashboardLayout sidebar="two">
      <Box borderRadius={11} px={2} py={5} width={["120%", '100%']}>
        <Box
          bg="white"
          borderRadius={11}
          mt={2}
          p={10}
          width={['200', '100%']}
          height={['100%', '100%']}
        >
       
          <Stack spacing={4}>

          <FormControl id="session">
            <FormLabel  fontFamily="Kumbh Sans"
            fontSize="13">Session</FormLabel>
            <Select
              type="text"
              name="session"
              fontFamily="Kumbh Sans"
              fontSize="13"
              onChange={handleChange}
              placeholder="Enter session"
            >
              {session && session.map((prog) => (
                <option key={prog?._id} value={prog?._id}>
                  {prog.name}
                </option>
             ))}
            </Select>
          </FormControl>

          
          <FormControl id="semester">
            <FormLabel  fontFamily="Kumbh Sans"
            fontSize="13">Semester</FormLabel>
            <Select
              type="text"
              name="semester"
              fontFamily="Kumbh Sans"
              fontSize="13"
              onChange={handleChange}
              placeholder="Enter semester"
            >
              {semester && semester.map((prog) => (
                <option key={prog?._id} value={prog?._id}>
                  {prog.name}
                </option>
             ))}
            </Select>
          </FormControl>


            <Text color="red.500">{submitError}</Text>
            <Text color="green.500">{message}</Text>
          <Box  display={'flex'}
          justifyContent={'center'}>
          <Button  
          color={configs?.primaryColor}
          background={configs?.primaryBg}
           fontFamily="Kumbh Sans"
           width={'50%'}
            fontSize="12"
             onClick={handleSubmit}>
            { isSubmitting ? '...Submitting' : 'Submit' }
          </Button>
          </Box>
        </Stack>   


        <DataTable
            data={result ? result: []}
            headers={headers}
            buttons={buttons}
            page={1}  // Replace with your actual page state
            onPageChange={(newPage) => console.log('Page changed to:', newPage)}
            pageSize={result.length}  // Replace with your actual page size state
            totalItems={100}  // Replace with your actual total items state
            loading={isSubmitting}  // Replace with your actual loading state
            // search={true}  // Adjust as needed
            // exports={true}  // Adjust as needed
            // pagination={true}  // Adjust as needed
          />
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default GenerateResult;
