import React, { useState, useEffect } from 'react';
import { Box, Text, Grid, GridItem, Image, Button } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import DataTable from '../components/DataTable';  // Replace with the actual path
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import { useSelector } from 'react-redux';
import RemitaPayment from 'react-remita';
import useFormSubmission from '../hooks/useFormSubmission';
import Headers from '../components/Headers';

const GeneratedFeesPage = ({paymentId , app}) => {
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const { id } = useParams(); // Use useParams to get the page and id
  const { data: school, loading, error, fetchData } = useFetch('Auth API');
  const configs = useSelector((state) => state.auth.config);
  const [status , setStatus] = useState();
  const headers = [
    { label: 'Breakdown', key: 'name' },
    { label: 'Amount', key: 'amount' },
  ];

  const buttons = [
    // Define your action buttons here
  ];

  // ... Other necessary state and logic
  useEffect(() => {
    // Fetch programmes when the component mounts
    if (!paymentId)
      fetchData(`/payments/${id}`);
    else
      fetchData(`/payments/${paymentId}`);
      
  }, [school]);
 
  let paymentData = {
    key: 'QzAwMDAyNzEyNTl8MTEwNjE4NjF8OWZjOWYwNmMyZDk3MDRhYWM3YThiOThlNTNjZTE3ZjYxOTY5NDdmZWE1YzU3NDc0ZjE2ZDZjNTg1YWYxNWY3NWM4ZjMzNzZhNjNhZWZlOWQwNmJhNTFkMjIxYTRiMjYzZDkzNGQ3NTUxNDIxYWNlOGY4ZWEyODY3ZjlhNGUwYTY=', // enter your key here
    orderId:school?.orderId,
    customerId: school?.userId._id,
    serviceTypeId: 4430731,
    firstName: `${school?.userId.firstName}`,
    lastName: `${school?.userId.lastName}`,
    email: school?.userId.email,
    phone:school?.userId.phoneNumber,
    amount: school?.amount,
    narration:school?.description,
    processRrr: true,
    extendedData: { 
        customFields: [ 
            { 
                name: "rrr", 
                value: Number(school?.RRR)
            } 
         ]
    }
  }
  
  const data = {
    ...paymentData,
    onSuccess:async function (response) {
      // function callback when payment is successful
      const { submitSuccess, responseBody } = await submitForm('payments/update', response);
      if (submitSuccess)
        setStatus(responseBody.message)
    },
    onError: function (response) {
      // function callback when payment fails
      console.log('callback Error Response', response);
    },
    onClose: function () {
      // function callback when payment modal is closed
      console.log('closed');
    },
  };

  return (
    <>
    { app ? 
       <Box
      bg="white"
      borderRadius={11}
      mt={2}
      p={5}
      width={['200', '100%']}
      height={['100%', '100%']}
    >
        <Headers logo={configs?.api+configs?.logoDark} title={configs?.schoolName} />
      <Grid templateColumns="repeat(2, 1fr)" p={10}>
    <GridItem>
      <Text fontSize={13} fontWeight="bold">
        Name
      </Text>
      </GridItem>
      <GridItem>
      <Text fontSize={13} style={{ whiteSpace: 'nowrap' }}>
      {school?.userId?.firstName} {school?.userId?.lastName}
      </Text>
      </GridItem>

    <GridItem>
      <Text fontSize={13} fontWeight="bold">
        eMail
      </Text>
      </GridItem>
      <GridItem>
      <Text fontSize={13} style={{ whiteSpace: 'nowrap' }}>
        {school?.userId?.email}
      </Text>
    </GridItem>

    <GridItem>
      <Text fontSize={13} fontWeight="bold">
        Phone number
      </Text>
      </GridItem>
      <GridItem>
      <Text fontSize={13} style={{ whiteSpace: 'nowrap' }}>
        {school?.userId?.phoneNumber}
      </Text>
    </GridItem>
    <GridItem>
      <Text fontSize={13} fontWeight="bold">
        RRR
      </Text>
      </GridItem>
      <GridItem>
      <Text fontSize={13} style={{ whiteSpace: 'nowrap' }}>
        {school?.RRR}
      </Text>
    </GridItem>
    <GridItem>
      <Text fontSize={13} fontWeight="bold">
        Date Generated
      </Text>
      </GridItem>
      <GridItem>
      <Text fontSize={13} style={{ whiteSpace: 'nowrap' }}>
        {school?.createdAt?.toLocaleString('en-US', { timeZone: 'WAT' })}
      </Text>
    </GridItem>

    <GridItem>
      <Text fontSize={13} fontWeight="bold">
        Description
      </Text>
      </GridItem>
      <GridItem>
      <Text fontSize={13} style={{ whiteSpace: 'nowrap' }}>
      {school?.description}
      </Text>
    </GridItem>

    <GridItem>
      <Text fontSize={13} fontWeight="bold">
        Amount 
      </Text>
      </GridItem>
      <GridItem>
      <Text fontSize={13} style={{ whiteSpace: 'nowrap' }}>
      (NGN) {school?.amount?.toLocaleString()}
      </Text>
    </GridItem>

    <GridItem>
      <Text fontSize={13} fontWeight="bold">
        Status
      </Text>
      </GridItem>
      <GridItem>
      <Text fontSize={13} style={{ whiteSpace: 'nowrap' }}>
      {status || school?.status}
      </Text>
    </GridItem>

  </Grid>
  <Box p={5} mt={"-10"}>
      <DataTable
        data={school?.paymentId?.breakdown}
        headers={headers}
        buttons={[]}
        page={1}  // Replace with your actual page state
        onPageChange={(newPage) => console.log('Page changed to:', newPage)}
        pageSize={10}  // Replace with your actual page size state
        totalItems={100}  // Replace with your actual total items state
        loading={false}  // Replace with your actual loading state
        // search={true}  // Adjust as needed
        exports={false}  // Adjust as needed
        // pagination={true}  // Adjust as needed
      />
      </Box>
      <Box width="100%" display={'flex'} justifyContent={'center'}>
          <RemitaPayment remitaData={data}  style={{background: `${configs?.primaryBg}`, color: `${configs?.primaryColor}`, padding: "10px", borderRadius: "10px"}} text="Pay Now"  live={false}/>
          </Box>
      
    </Box> :
    <DashboardLayout sidebar="two">
      <Box borderRadius={11} px={2} py={5} width={["120%", '100%']}>
        <Box
          bg="white"
          borderRadius={11}
          mt={2}
          p={5}
          width={['200', '100%']}
          height={['100%', '100%']}
        >
          {/* <Image src={configs?.api+configs?.logoDark} alt="Logo" ml={35} mt={10}/> */}
          <Headers logo={configs?.api+configs?.logoDark} title={configs?.schoolName} />
          <Grid templateColumns="repeat(2, 1fr)" p={10}>
        <GridItem>
          <Text fontSize={13} fontWeight="bold">
            Name
          </Text>
          </GridItem>
          <GridItem>
          <Text fontSize={13} style={{ whiteSpace: 'nowrap' }}>
          {school?.userId?.firstName} {school?.userId?.lastName}
          </Text>
          </GridItem>

        <GridItem>
          <Text fontSize={13} fontWeight="bold">
            eMail
          </Text>
          </GridItem>
          <GridItem>
          <Text fontSize={13} style={{ whiteSpace: 'nowrap' }}>
            {school?.userId?.email}
          </Text>
        </GridItem>

        <GridItem>
          <Text fontSize={13} fontWeight="bold">
            Phone number
          </Text>
          </GridItem>
          <GridItem>
          <Text fontSize={13} style={{ whiteSpace: 'nowrap' }}>
            {school?.userId?.phoneNumber}
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize={13} fontWeight="bold">
            RRR
          </Text>
          </GridItem>
          <GridItem>
          <Text fontSize={13} style={{ whiteSpace: 'nowrap' }}>
            {school?.RRR}
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize={13} fontWeight="bold">
            Date Generated
          </Text>
          </GridItem>
          <GridItem>
          <Text fontSize={13} style={{ whiteSpace: 'nowrap' }}>
            {school?.createdAt?.toLocaleString('en-US', { timeZone: 'WAT' })}
          </Text>
        </GridItem>

        <GridItem>
          <Text fontSize={13} fontWeight="bold">
            Description
          </Text>
          </GridItem>
          <GridItem>
          <Text fontSize={13} style={{ whiteSpace: 'nowrap' }}>
          {school?.description}
          </Text>
        </GridItem>

        <GridItem>
          <Text fontSize={13} fontWeight="bold">
            Amount 
          </Text>
          </GridItem>
          <GridItem>
          <Text fontSize={13} style={{ whiteSpace: 'nowrap' }}>
          (NGN) {school?.amount?.toLocaleString()}
          </Text>
        </GridItem>
        <GridItem>
      <Text fontSize={13} fontWeight="bold">
        Status
      </Text>
      </GridItem>
      <GridItem>
      <Text fontSize={13} style={{ whiteSpace: 'nowrap' }}>
      {status || school?.status}
      </Text>
    </GridItem>


      </Grid>
      <Box p={5} mt={"-10"}>
          <DataTable
            data={school?.paymentId?.breakdown}
            headers={headers}
            buttons={[]}
            page={1}  // Replace with your actual page state
            onPageChange={(newPage) => console.log('Page changed to:', newPage)}
            pageSize={10}  // Replace with your actual page size state
            totalItems={100}  // Replace with your actual total items state
            loading={false}  // Replace with your actual loading state
            // search={true}  // Adjust as needed
            exports={false}  // Adjust as needed
            // pagination={true}  // Adjust as needed
          />
          </Box>
          <Box width="100%" display={'flex'} justifyContent={'center'}>
          <RemitaPayment style={{background: `${configs?.primaryBg}`, color: `${configs?.primaryColor}`, padding: "10px", borderRadius: "10px"}} remitaData={data}  text="Pay Now"  live={false}/>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>}
    </>
  );
};

export default GeneratedFeesPage;
