import { useEffect, useState } from 'react';

function usePercentageCompletion(startDate, endDate) {
  // Validate input dates
  if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
    console.error('Invalid date format');
    startDate = new Date(); // Provide a default date if input is invalid
    endDate = new Date(); // Provide a default date if input is invalid
    // return { percentage:0, daysLeft: 0 };
  }

  const [percentage, setPercentage] = useState(0);
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    const calculatePercentageAndDaysLeft = () => {
      const currentDate = new Date();

      // Calculate the total duration in milliseconds
      const totalDuration = endDate.getTime() - startDate.getTime();

      // Calculate the duration elapsed in milliseconds
      const elapsedDuration = currentDate.getTime() - startDate.getTime();

      // Calculate the percentage completion
      let calculatedPercentage = (elapsedDuration / totalDuration) * 100;

      // Ensure the percentage is capped at 100%
      calculatedPercentage = Math.min(100, calculatedPercentage);

      // Ensure the percentage is not negative
      calculatedPercentage = Math.max(0, calculatedPercentage);

      // Calculate the number of days left
      const remainingDays = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));

      setPercentage(calculatedPercentage.toFixed(0));
      setDaysLeft(remainingDays < 0 ? 0 : remainingDays);
    };

    calculatePercentageAndDaysLeft(); // Initial calculation

  }, []);

  return { percentage, daysLeft };
}

export default usePercentageCompletion;
