// Dashboard.js
import React, { useEffect, useState } from 'react';
import { Box,  Grid } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import InformationCard from '../components/InformationCard';
import DataTable from '../components/DataTable';
import useFetch from '../hooks/useFetch';
import { useNavigate } from 'react-router-dom';


const Applicants = () => {
  const { data: application, loading, error, fetchData } = useFetch();
  const [dynamicPath, setDynamicPath] = useState('/applications');
  const dynamicParams = "";
  const navigate = useNavigate()
  const headers = [
    { label: 'First Name', key: 'userId.firstName' },
    { label: 'Last Name', key: 'userId.lastName' },
    { label: 'Email', key: 'userId.email' },
    { label: 'Programme', key: 'programme.name' },
    { label: 'Department', key: 'course.name' },
    { label: 'Status', key: 'status' }
  ];

  const handleView = (item) =>{
    console.log(item?._id)
    navigate('/application/review/'+item?._id)
  }
  const buttons = [
    {
      color: 'darkblue',
      label: 'View',
      onClick: (item) => handleView(item),
    },
    
    // {
    //   color: '#fde428',
    //   label: 'Edit',
    //   onClick: (item) => handleEdit(item),
    // },
    // ... additional buttons
  ];

const handleEdit = () =>{

}
useEffect(() => {
  fetchData(dynamicPath);
}, [dynamicPath]);

  return (
    <DashboardLayout sidebar={"two"}>
        <Box borderRadius={11} px={2} py={5} width={["120%", '100%' ]}>
      <Box 
        bg="white" 
        borderRadius={11} 
        mt={2} 
        p={5}  
        width={['200','100%']}
        height={['100%', '100%']}
        // display={'flex'}
      >
        <Grid
            templateColumns="repeat(4, 1fr)"
            gap={2}
            mt={3}
            // maxW="600px"
            // margin="0 auto"
        >
            <InformationCard title="Total No of Applications" value={Number(application?.totalItems)} semesterInfo="This session" />
         
        </Grid>

        <Box mt={5}>
          <DataTable
                data={application?.data || []}
                headers={headers}
                buttons={buttons}
                page={application?.currentPage}
                onPageChange={(newPage) =>  setDynamicPath(`/applications/${newPage}`)}
                pageSize={application?.totalPage}
                totalItems={application?.totalItems}
                loading={loading}
                search={true}
                exports={true}
                pagination={true}
              />
            </Box>
        </Box>
    </Box>  
    </DashboardLayout>
  );
};

export default Applicants;
