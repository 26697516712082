// Dashboard.js
import React from 'react';
import { SimpleGrid, Box} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import ApplicationLink from '../components/ApplicationLink';

const School = () => {
return (
  <DashboardLayout sidebar={"two"}>
     <Box borderRadius={11} px={2} py={5} width={["120%", '100%' ]}>
    <Box 
      bg="white" 
      borderRadius={11} 
      mt={2} 
      p={5}  
      width={['200','100%']}
      height={['100%', '100%']}
      display={'flex'}
    >
      <SimpleGrid
        height={['100%', '100%']}
        mt={5}
        flexDirection={['row','column']}
        columns={[1, 4]} // Set the number of columns based on your design
        spacing={4}
        gap={5}
        px={{ base: 10, md: 50 }}
        justifyContent={'center'}
      >
         
         <ApplicationLink
              link={'/faculty'}
              icon={'menu-teacher.svg'}
              title='Faculties'
              mb={5}
            />

        <ApplicationLink
              link={'/department'}
              icon={'menu-teacher.svg'}
              title='Deparments/Major'
              mb={5}
            />
            <ApplicationLink
              link={'/programme'}
              icon={'menu-teacher.svg'}
              title='Programme'
              mb={5}
            />

          <ApplicationLink
              link={'/levels'}
              icon={'menu-teacher.svg'}
              title='Levels'
              mb={5}
            />
           
      
        </SimpleGrid>
      </Box>
  </Box>  
  </DashboardLayout>

  );
};

export default School;
