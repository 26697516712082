// src/store/index.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';
import { config } from './authActions'; // Import your action creator
import AuthService from './authService';

// import loggerMiddleware from 'redux-logger'; // Example middleware, you can add others

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
  // Add other options as needed
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
});
const fetchAndSetConfig = async () => {
  try {
    const authService = AuthService();
    const configurationData = await authService.config();
    store.dispatch(config(configurationData));
  } catch (error) {
    // Handle error if needed
  }
};

// Call the function to fetch and set config when needed
fetchAndSetConfig();
export default store;
