// Dashboard.js
import React, { useEffect, useState } from 'react';
import { Box,  Grid } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import InformationCard from '../components/InformationCard';
import DataTable from '../components/DataTable';
import useFetch from '../hooks/useFetch';


const Enrolment = () => {
  const { data: application, loading, error, fetchData } = useFetch();
  const [dynamicPath, setDynamicPath] = useState('/students/enrolled');
  const dynamicParams = "";

  const headers = [
    { label: 'First Name', key: 'userId.firstName' },
    { label: 'Last Name', key: 'userId.lastName' },
    { label: 'Matric No', key: 'matric' },
    { label: 'Email', key: 'userId.email' },
    { label: 'Programme', key: 'programme.name' },
    { label: 'Course', key: 'course.name' }
  ];
  const buttons = [
    {
      color: 'darkblue',
      label: 'View',
      onClick: (item) => handleView(item),
    },
    {
      color: '#fde428',
      label: 'Edit',
      onClick: (item) => handleEdit(item),
    },
    // ... additional buttons
  ];
const handleView = () =>{

}
const handleEdit = () =>{

}
useEffect(() => {
  fetchData(dynamicPath, dynamicParams);
}, [fetchData, dynamicPath]);

  return (
    <DashboardLayout sidebar={"two"}>
        <Box borderRadius={11} px={2} py={5} width={["120%", '100%' ]}>
      <Box 
        bg="white" 
        borderRadius={11} 
        mt={2} 
        p={5}  
        width={['200','100%']}
        height={['100%', '80vh']}
        // display={'flex'}
      >
        <Grid
            templateColumns="repeat(4, 1fr)"
            gap={2}
            mt={3}
            // maxW="600px"
            // margin="0 auto"
        >
            <InformationCard title="Total No of Students Enrolled" value={application?.data.length} semesterInfo="This semester" />
         
        </Grid>

        <Box mt={5}>
          <DataTable
                data={application?.data || []}
                headers={headers}
                buttons={buttons}
                page={application?.currentPage}
                onPageChange={(newPage) =>  setDynamicPath(`/students/enrolled/${newPage}`)}
                pageSize={application?.totalPages}
                totalItems={application?.totalItems}
                loading={loading}
                search={true}
                exports={true}
                pagination={true}
              />
            </Box>
        </Box>
    </Box>  
    </DashboardLayout>
  );
};

export default Enrolment;
