import React from 'react';
import { Image, Box, Flex, Text, Spacer, useBreakpointValue, IconButton, MenuButton, MenuItem, MenuList, Avatar, Menu } from '@chakra-ui/react';
import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../auth/authActions';
import { useNavigate } from 'react-router-dom';
const TopBar = ({ type, onToggleSidebar }) => {
  const userRole = useSelector((state) => state?.auth?.user?.role?.name);
  const firstName = useSelector((state) => state?.auth?.user?.firstName);
  const image = useSelector((state) => state?.auth?.user?.image);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const config = useSelector((state)=> state?.auth?.config);  
  const handleLogout = () => {
    // Perform any additional logout logic here if needed
    // ...

    // Dispatch the logout action
    dispatch(logout());
    navigate("/login")
  };


  return (
    <Box 
    className='hidden-section'
    w="100%"
    bg="#FFFFFF" 
    color="#1B212D"
     px="4" py={2} shadow="md"
     >
      <Flex align="center">
        {/* Hamburger menu button for sidebar */}
        {userRole !== "Applicant" ? (
        <IconButton
          icon={<HamburgerIcon />}
          aria-label="Toggle Sidebar"
          mr="4"
          display={{ base: 'block', md: 'block' }}
          onClick={onToggleSidebar} // Call the provided callback to toggle the sidebar
        />
        ): (
        <><Image src={`${config.api}${config.logoDark}`}  ml={'10%'}
         width={100} height={100}/> 
          {(userRole == "Applicant" && isMobile) && (
         <IconButton
        icon={<HamburgerIcon />}
        aria-label="Toggle Sidebar"
        mr="4"
        display={{ base: 'block', md: 'block' }}
        onClick={onToggleSidebar} // Call the provided callback to toggle the sidebar
      />)}</>)}

   

        <Spacer />

        <Flex align="center"  mr={type !== "normal" ? 1: "1%"}>
          {/* User Avatar */}
          <Avatar src={ image ? `http://localhost:3099${image}`: "a.png"} alt="User Avatar" size="sm" mr={2}  />

          {/* User Name and Dropdown */}
          <Menu>
            <MenuButton as={Box} fontSize="sm" fontWeight="bold" cursor="pointer">
              {firstName}
              <ChevronDownIcon />
            </MenuButton>
            <MenuList>
              {/* Add more menu items as needed */}
              {/* <MenuItem>Profile</MenuItem>
              <MenuItem>Settings</MenuItem> */}
              <MenuItem  onClick={handleLogout}>Logout</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        {/* <Logout /> */}
      </Flex>
    </Box>
  );
}

export default TopBar;
