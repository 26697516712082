import React from 'react';
import useApiUrls from '../hooks/useApiUrls';

const AuthService = () => {
  const { getUrlByName } = useApiUrls();
  const api = getUrlByName('Auth API');

  const login = async (credentials) => {
    const response = await fetch(`${api}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    });

    let result = await response.json();

    if (!response.ok) {
      throw new Error(result.message);
    }

    const user = result;
    return user;
  };

  const user = async (credentials) => {
    const response = await fetch(`${api}/users/profile`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${credentials}`
      }
    });

    let result = await response.json();

    if (!response.ok) {
      throw new Error(result.message);
    }

    const userProfile = result;
    return userProfile;
  };

  const logout = async () => {
    // ... your logout logic
  };

  const config = async () => {
    const response = await fetch(`${api}/configs`, {
      method: 'GET'
    });

    
    return await response.json();
  };

  return {
    api,
    login,
    user,
    logout,
    config,
  };
};

export default AuthService;
