// Contact.js
import React, { useState } from 'react';
import { Box, Text, FormControl, FormLabel, Input, Textarea, Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Spacer from '../components/Spacer';
import useFormSubmission from '../hooks/useFormSubmission';

const Contact = () => {
    const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const configs = useSelector((state) => state.auth.config);
  const [formData, setFormData] = useState({})
 const [message, setMessage] = useState()

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMessage("")
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const { submitSuccess, responseBody } = await submitForm('contact', formData);
    if (submitSuccess) {
      setMessage(responseBody.message);
    }
}
  return (
    <Box>
      <Header
        config={configs?.api + configs?.logoDark || '/images/loading.png'}
        bannerSrc={configs?.contactBg ? configs?.api + configs?.newsBg : configs?.api + '/uploads/image.png'}
        buttonText='Login'
        
        height="300px"
      >
        <Box
        width={'100%'}
        ml={['10px', "200px"]}
          
          justifyContent="center"
          alignItems="center"
          textAlign="left"

        >
         <Box justifySelf={'center'}>
            <Text
              mt={3}
              color={`${configs?.primaryColor}`}
              fontFamily="Kumbh Sans"
              fontSize="50px"
              fontWeight="600"
              lineHeight="64px"
              letterSpacing="-0.03em"
            >
              Contact Us
            </Text>
          </Box>
        </Box>
      </Header>

      <Spacer gap="200" />

      <Box px="30%">
        <Box mt={5}> 
        <form onSubmit={handleSubmit}>
        <FormControl id="name" mb={4}>
          <FormLabel  fontFamily="Kumbh Sans" fontSize={13}>Name</FormLabel>
          <Input type="text" name="name" onChange={handleInputChange} />
        </FormControl>

        <FormControl id="email" mb={4}>
          <FormLabel  fontFamily="Kumbh Sans" fontSize={13}>Email Address</FormLabel>
          <Input type="email" name="email" onChange={handleInputChange}/>
        </FormControl>

        <FormControl id="message" mb={4}>
          <FormLabel fontFamily="Kumbh Sans" fontSize={13}>Message</FormLabel>
          <Textarea name="message" onChange={handleInputChange}/>
        </FormControl>
        {submitError && <Text fontFamily="Kumbh Sans" fontSize={13} color="red">{submitError}</Text>}
        {message && <Text fontFamily="Kumbh Sans" fontSize={13} color="green">{message}</Text>}
        <Button  fontFamily="Kumbh Sans" fontSize={13} type="submit" color={configs?.primaryColor} bg={configs?.primaryBg}>
          {isSubmitting ? '...is Submitting' : 'Submit'}
        </Button>
      </form>
        </Box>
      </Box>
      <Spacer gap="200" />

      <Footer config={configs?.logoDark} />
    </Box>
  );
};

export default Contact;
