import React, { useState, useEffect } from 'react';
import { Box, Spinner, Text} from '@chakra-ui/react';
import {  useParams } from 'react-router-dom'; // Import useParams
import DashboardLayout from '../components/DashboardLayout';
import { Link } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import RemitaCheckOut from './Remita';

const UnofficialTranscript = () => {
  
  const { data: transcripts, loading, error, fetchData } = useFetch();
  const { data: unofficial, loading: loader, error: err, fetchData: fetchPayment } = useFetch();

  
  
  useEffect(() => {
    fetchPayment('payments/findUnofficial');
    fetchData('unofficial/transcriptByUser');
  }, [unofficial?.data]);
  
  const goBack = () => {
    // Go back one step in the browser history
    window.history.back();
  };
  
  return (
    <DashboardLayout sidebar={"none"}>
    <Box borderRadius={11} px={2} py={5} width={["120%", '100%' ]}  display={'flex'}
    justifyContent={'center'} bg="white">
    <Link onClick={goBack} className="hidden-section">Back</Link>
  <Box 
    bg="white" 
    borderRadius={11} 
    mt={2} 
    p={5}  
    width={['200','100%']}
    height={['100%', '100%']}
   
  >
     
    {!loader ? <>
   
    { unofficial?.data?.status == "Successful" ? 
    <div>
      {/* Display the HTML content using dangerouslySetInnerHTML */}
      <div dangerouslySetInnerHTML={{ __html: transcripts?.data }} />
     </div> : <RemitaCheckOut id={unofficial?.data?.paymentId} type="unofficial"/>}
     </> : <Spinner /> }
      </Box>
      </Box>
    </DashboardLayout>
  );
};



export default UnofficialTranscript;
