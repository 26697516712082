// Dashboard.js
import React, { useEffect, useState } from 'react';
import { Text, Box,Select, Button, FormControl, FormLabel, Input, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,  Grid } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import InformationCard from '../components/InformationCard';
import DataTable from '../components/DataTable';
import useFetch from '../hooks/useFetch';
import useFormSubmission from '../hooks/useFormSubmission';
import { useSelector } from 'react-redux';


const StaffList = () => {
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const { data: application, loading, error, fetchData } = useFetch();
  const configs = useSelector((state)=> state.auth.config)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { data: perm, loading: permLoad, error: permErr, fetchData: fetchPerm } = useFetch();
  const [dynamicPath, setDynamicPath] = useState('/users/staff');
  const dynamicParams = "";
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: ''
  });
  const [message, setMessage] = useState();

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAddStaff = async() => {
      // Generate password based on firstName and lastName
  const password = `${formData.firstName}${formData.lastName}`;

  // Update the state with the generated password
  setFormData((prevFormData) => ({
    ...prevFormData,
    password,
  }));

    const { submitSuccess, responseBody } = await submitForm('auth/registerStaff', formData);
    if (submitSuccess){
        setMessage(responseBody.message)
    }
    // onClose();
  };

  const headers = [
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'Status', key: 'status' }
  ];
  const buttons = [
    // {
    //   color: 'darkblue',
    //   label: 'View',
    //   onClick: (item) => handleView(item),
    // },
    {
      color: '#fde428',
      label: 'Edit',
      onClick: (item) => handleEdit(item),
    },
    // ... additional buttons
  ];
const handleEditStaff = async() =>{
  const { submitSuccess, responseBody } = await submitForm(`users/edit`, formData);
  if (submitSuccess) {
    setMessage(responseBody.message);
  }
}
const handleEdit = (staff) => {
  setFormData({
    id: staff._id,
    firstName: staff.firstName,
    lastName: staff.lastName,
    email: staff.email,
    password: staff.password,
    perm: staff.perm // Assuming 'perm' is the field representing permission ID in staff data
  });
  setIsEditModalOpen(true);
};
useEffect(() => {
  fetchData(dynamicPath, dynamicParams);
  fetchPerm('clearance/perm/all')
}, [dynamicPath]);

  return (
    <DashboardLayout sidebar={"two"}>
        <Box borderRadius={11} px={2} py={5} width={["120%", '100%' ]}>
      <Box 
        bg="white" 
        borderRadius={11} 
        mt={2} 
        p={5}  
        width={['200','100%']}
        height={['100%', '80vh']}
        // display={'flex'}
      >
        <Grid
            templateColumns="repeat(4, 1fr)"
            gap={2}
            mt={3}
            // maxW="600px"
            // margin="0 auto"
        >
            <InformationCard title="Total No of Staff" value={application?.data?.length} semesterInfo="This semester" />
         
        </Grid>
        <Box>
        <Button bg={configs?.primaryBg} color={configs?.primaryColor} fontSize={13} onClick={onOpen} mt={5}>
        Add Staff
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Staff</ModalHeader>
          <ModalCloseButton />
          
          <ModalBody>
            <FormControl>
              <FormLabel fontSize={13}>First Name</FormLabel>
              <Input name="firstName" value={formData.firstName}  fontSize={13} onChange={handleInputChange} />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel fontSize={13}>Last Name</FormLabel>
              <Input name="lastName" value={formData.lastName} fontSize={13} onChange={handleInputChange} />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel fontSize={13}>Email</FormLabel>
              <Input type="email" name="email" value={formData.email} fontSize={13} onChange={handleInputChange} />
            </FormControl>
            <FormControl  >
               <FormLabel fontSize={13} >Permission</FormLabel>
              <Select
                name="perm"
                onChange={(e) => handleInputChange(e)}
                fontSize={13}
            >
                <option value="">Select option</option>
                {permLoad ? <p>Loading....</p>: (perm?.data?.map((item) => (
                <option key={item.id} value={item?._id}>
                    {item.name} 
                </option>
                )))}
            </Select>
            </FormControl>
          <Text color="green.500">{message && (message)}</Text>
          </ModalBody>

          <ModalFooter>
            <Button fontSize={13} bg={configs?.primaryBg} color={configs?.primaryColor} mr={3} onClick={handleAddStaff}>
              { isSubmitting ? 'Submitting.....' : 'Save' }
            </Button>
            <Button fontSize={13} onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
        </Box>
        <Box mt={5}>
          <DataTable
                data={application?.data || []}
                headers={headers}
                buttons={buttons}
                page={application?.currentPage}
                onPageChange={(newPage) =>  setDynamicPath(`/users/staff/${newPage}`)}
                pageSize={application?.totalPages}
                totalItems={application?.totalItems}
                loading={loading}
                search={true}
                exports={true}
                pagination={true}
              />
            </Box>
        </Box>
        <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Staff</ModalHeader>
          <ModalCloseButton />
          
          <ModalBody>
            <FormControl>
              <FormLabel fontSize={13}>First Name</FormLabel>
              <Input name="firstName" value={formData.firstName}  fontSize={13} onChange={handleInputChange} />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel fontSize={13}>Last Name</FormLabel>
              <Input name="lastName" value={formData.lastName} fontSize={13} onChange={handleInputChange} />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel fontSize={13}>Email</FormLabel>
              <Input type="email" name="email" value={formData.email} fontSize={13} onChange={handleInputChange} />
            </FormControl>
            <FormControl  >
               <FormLabel fontSize={13} >Permission</FormLabel>
              <Select
                name="perm"
                onChange={(e) => handleInputChange(e)}
                fontSize={13}
            >
                <option value="">Select option</option>
                {permLoad ? <p>Loading....</p>: (perm?.data?.map((item) => (
                <option key={item.id} value={item?._id}>
                    {item.name} 
                </option>
                )))}
            </Select>
            </FormControl>
          <Text color="green.500">{message && (message)}</Text>
          </ModalBody>

          <ModalFooter>
            <Button fontSize={13} bg={configs?.primaryBg} color={configs?.primaryColor} mr={3} onClick={handleEditStaff}>
              { isSubmitting ? 'Submitting.....' : 'Save' }
            </Button>
            <Button fontSize={13} onClick={() => setIsEditModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
    </Modal>
    </Box>  
    </DashboardLayout>
  );
};

export default StaffList;
