import { Grid, GridItem, Image, Text } from "@chakra-ui/react";

function Headers({logo, title}) {
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={4} justifyContent={'center'}>
      {/* Grid for logo */}
      <GridItem colSpan={1}>
        <Image src={logo} alt="Logo" width={100} />
      </GridItem>

      {/* Grid for title */}
      <GridItem colSpan={2} align-self="center">
        <Text fontSize="30px" fontWeight={700}>{title}</Text>
      </GridItem>

      {/* Third empty grid
      <GridItem colSpan={1}></GridItem> */}
    </Grid>
  );
}

export default Headers;
