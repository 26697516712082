import React from 'react';
import { Box, Flex, Grid, GridItem, Image, Icon, Link, Text } from '@chakra-ui/react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import { useSelector } from 'react-redux';
const Footer = () => {
  const configs = useSelector((state) => state.auth.config);
  return (
    <Box py={2} px={20} mt={100} bg={configs?.footerBg ? configs?.footerBg : 'aliceblue'} color={"white"}>
      <Grid mt={5} templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={4}>
        {/* First Grid */}
        <GridItem>
          <Box bg="white" width={120} borderRadius={30} p={3} mb={3}>
            <Image src={configs?.api+configs?.logoWhite} alt="Logo" mb={2}  />
            {/* <Text color={"#626687"} fontSize={"13px"} w="45%" mb={10}>"Empowering Minds, Igniting Innovation:  Where Knowledge Shapes the Future."</Text> */}
          </Box>
          <Flex mt={{ base: 4, md: 0 }} direction="row" gap={5}>
            {/* Social Media Icons */}
            <Link href={configs?.facebook} mb={2}>
              <Icon color={"#FFF"} as={FaFacebook} boxSize={{ base: 5, md: 6 }} />
            </Link>
            <Link href={configs?.twitter} mb={2}>
              <Icon color={"#FFF"} as={FaTwitter} boxSize={{ base: 5, md: 6 }} />
            </Link>
            <Link href={configs?.instagram}>
              <Icon color={"#FFF"} as={FaInstagram} boxSize={{ base: 5, md: 6 }} />
            </Link>
          </Flex>
        </GridItem>
        {/* Second Grid */}
        <GridItem mt={{ base: 4, md: 0 }}>
          <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={10}>
           
            <GridItem>
              <Flex direction="column"  gap={2}>
                <Text fontWeight={600} color={'#FFF'} mb={2}>
                  Quick Links
                </Text>
                <Link color={"#FFF"} fontSize={'13px'} href="/latest_news">
                  Article and News
                </Link>
                <Link color={"#FFF"} fontSize={'13px'} href="/contact">
                  Contact Us
                </Link>
              </Flex>
            </GridItem>
            <GridItem colSpan={2}>
              <Flex direction="column">
                <Text fontWeight={600} color={'#FFF'} mb={2}>
                  Contact
                </Text>
                <Text  color={"#FFF"} fontSize={'13px'}>{configs?.address}</Text>
                <Text  color={"#FFF"} fontSize={'13px'}>{configs?.phoneNumber}</Text>
                <Text  color={"#FFF"} fontSize={'13px'}>{configs?.email}</Text>
                
              </Flex>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Footer;
