// Dashboard.js
import React, { useEffect } from 'react';
import { Box,  SimpleGrid , Text} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import ApplicationLink from '../components/ApplicationLink';

const StudentManagement = () => {
  
  return (
    <DashboardLayout sidebar={"two"}>
        <Box borderRadius={11} px={2} py={5} width={["120%", '100%' ]}>
      <Box 
        bg="white" 
        borderRadius={11} 
        mt={2} 
        p={5}  
        width={['200','100%']}
        height={['100%', '80vh']}
        display={'flex'}
      >
         
        <SimpleGrid
          height={['100%', '50%']}
          mt={5}
          flexDirection={['row','column']}
          columns={[1, 4]} // Set the number of columns based on your design
          spacing={4}
          gap={5}
          px={{ base: 10, md: 50 }}
          justifyContent={'center'}
        >
            <ApplicationLink
              link={'/staff-list'}
              icon={'menu-profile-2user.svg'}
              title='List Staff'
              ></ApplicationLink>
            <ApplicationLink
              link={'/permissions'}
              icon={'menu-profile-add.svg'}
              title='Approval Permissions'
              ></ApplicationLink>
             
          
        
          </SimpleGrid>
        </Box>
    </Box>  
    </DashboardLayout>
  );
};

export default StudentManagement;
