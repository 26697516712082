// Dashboard.js
import React, { useEffect, useState } from 'react';
import { Box,  Button,  Grid, Link } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import DataTable from '../components/DataTable';
import useFetch from '../hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ClearanceModal from '../components/ClearanceModal';
import useFormSubmission from '../hooks/useFormSubmission';
const AdmittedTracker = () => {
  const { isSubmitting, submitError, submitForm } = useFormSubmission('Auth API');
  const { data: application, loading, error, fetchData } = useFetch();
  const user = useSelector((state)=> state?.auth?.user)
  const config = useSelector((state)=>state?.auth?.config)
  const [dynamicPath, setDynamicPath] = useState(user?.role?.name == "Student" ? 'clearance/admissionByUser': 'clearance/admission');
  const dynamicParams = "";
  const [selectedItem, setSelectedItem] = useState(null); // State to store the selected item
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
 const [formData, setFormData] = useState({})
 const [message, setMessage] = useState()
  
  const navigate = useNavigate()
  const headers = [
    { label: 'First Name', key: 'userId.firstName' },
    { label: 'Last Name', key: 'userId.lastName' },
    { label: 'Stage', key: 'stage.name' },
    { label: 'Tracking Status', key: 'status' },
    { label: 'Clearance Status', key: 'clearanceStatus' },
    { label: 'Date', key: 'createdAt' },
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async() => {
  
    const { submitSuccess, responseBody } = await submitForm('clearance/admission_update', formData);
    if (submitSuccess) {
      console.log(responseBody);
      
      setMessage(responseBody.message);
      // Close the modal after submission
    }
    
  }
  const handleView = (item) =>{
    setSelectedItem(item); // Set the selected item
    setFormData(prevState => ({
      ...prevState,
      userId: item?.userId?._id,
      id: item?._id
    }));
    
    setIsModalOpen(true); // Open the modal
  }
  const buttons = user?.role?.name == "Student" ? [] : [
    {
      color: 'darkblue',
      label: 'View',
      onClick: (item) => handleView(item),
    }
  ];


useEffect(() => {
  fetchData(dynamicPath);
}, [dynamicPath]);



  return (
    <DashboardLayout sidebar={"two"}>
        <Box borderRadius={11} px={2} py={5} width={["120%", '100%' ]}>
      <Box 
        bg="white" 
        borderRadius={11} 
        mt={2} 
        p={5}  
        width={['200','100%']}
        height={['100%', '100%']}
        // display={'flex'}
      >
        <Grid
            templateColumns="repeat(4, 1fr)"
            gap={2}
            mt={3}
            // maxW="600px"
            // margin="0 auto"
        >
            {/* <InformationCard title="Total No of Applications" value={Number(application?.totalItems)} semesterInfo="This session" /> */}
         
        </Grid>
        <ClearanceModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} 
        type="admission"
        formData={formData} 
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        bg={config?.primaryBg}
        color={config?.primaryColor}
        status={submitError || message}
        isSubmitting={isSubmitting}
           >
        
       
      </ClearanceModal>

        <Box mt={5}>
          <DataTable
                data={application?.data || []}
                headers={headers}
                buttons={buttons}
                page={application?.currentPage}
                onPageChange={(newPage) =>  setDynamicPath(`/${user?.role?.name == "Student" ? 'clearance/admissionByUser': 'clearance/admission'}/${newPage}`)}
                pageSize={application?.totalPage}
                totalItems={application?.totalItems}
                loading={loading}
                search={true}
                exports={true}
                pagination={true}
              />
            </Box>
        </Box>
    </Box>  
    </DashboardLayout>
  );
};

export default AdmittedTracker;
