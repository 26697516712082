import React, { useState } from 'react';
import {
  Box,
  Button,
  VStack,
  Text,
  Collapse,
  Image,
  Center,
  useMediaQuery
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../auth/authActions';

function SidebarItem({ item, isActive, sub, userRole }) {
  const { id,page } = useParams(); // Use useParams to get the page and id
  const [isOpen, setIsOpen] = useState(false);
  const toggleSubMenu = () => {
    setIsOpen(!isOpen);
  };
  const configs = useSelector((state)=>state.auth.config)

  return (
    <>
     <Link
        as={Button}
        onClick={toggleSubMenu}
        color={isActive ? "#000" : "#929EAE"}
        rightIcon={
          item.submenu && (isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />)
        }
        to={window.location.origin+item.url}

      >
        <Box 
        alignItems={'center'}
        borderLeft={isActive ? `2px solid ${configs?.primaryBg}`: "none"}
        background={(isActive && (userRole =="Administrator" || userRole =="Staff")) ? `${configs?.primaryColor}` : isActive ?  `${configs?.primaryBg+60}`: "transparent"}  
         _hover={{
          background: `${configs?.primaryBg+60}`,
          color:  (userRole =="Administrator" || userRole =="Staff") ? "#000" : "#929EAE" 
        }}
        display={'flex'}
        borderRadius={10}
        w={sub ? 175 : "100%"}
        textAlign="left" // Set the textAlign property to "left"
        color={(!isActive && (userRole =="Administrator" || userRole =="Staff")) ? "white" : isActive ? "#000": "#929EAE"}  
        
        
        // justifyContent="flex-start" // Add justifyContent property to align content to the left
        px={3}
        py={2}
        >
        <Image src={isActive ? item.icon : item.icons} mr="2" />
        <Text fontSize="11">{item.label}</Text>
        </Box>
      </Link>

      {item.submenu && (
        <Collapse in={isOpen}>
          <VStack align="start" 
          ml={{ base: 0, md: 3 }} spacing="1">
            {item.submenu.map((subItem, index) => (
              <SidebarItem
                key={index}
                item={subItem}
                isActive={isActive}
                sub={true}
              />
            ))}
          </VStack>
        </Collapse>
      )}
    </>
  );
}

function Sidebar({ menuItems, isSidebarOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const configs = useSelector((state)=>state.auth.config)

  const handleLogout = () => {
    // Perform any additional logout logic here if needed
    // ...

    // Dispatch the logout action
    dispatch(logout());
    navigate("/login")
  };
  
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const activePage =  window.location.pathname;
  const step = window.location.pathname?.split("/")[1]
  const shouldHideSidebar = !isSidebarOpen;
  const userRole = useSelector((state) => state?.auth?.user?.role?.name);
  // const userBranch = useSelector((state) => state?.auth?.user?.branch?.name);
  const userUnit = useSelector((state) => state?.auth?.user?.unit?.name);
 
  const menuItemsFiltered = menuItems?.filter((item) => item?.role?.includes(userRole) || item?.unit?.includes(userUnit));
  // const userRole = "Student"
  // console.log(shouldHideSidebar)
  return (
    <Box
    className='hidden-section'
      bg={ (userRole == "Administrator" || userRole == "Staff") ? configs?.primaryBg: "#FFFF"}
      color={ (userRole == "Administrator" || userRole == "Staff")  ? "#929EAE" : "#FFFF"}
      fontFamily="Kumbh Sans"
      fontSize='20px'
      mt={userRole == "Applicant" ? 4 : 0}
      fontWeight={600}
      overflowX="hidden"
      borderRadius={userRole == "Applicant" && "11px"}
      w={userRole == "Applicant" ? "250px": "250px"}
      // px={{ base: 4, md: 4 }}
      minH={{ base: "200vh", md: "100vh" }}
      display={(!isMobile && shouldHideSidebar) ? 'block' : (!isMobile && !shouldHideSidebar) ? 'block'  : (isMobile && shouldHideSidebar) ? 'block' :'none'}
      position={isMobile ? 'absolute': 'relative'}
      zIndex={22}
      sx={{
        "&::-webkit-scrollbar": {
          width: "4px", // Adjust the scrollbar width as needed
        },
        "&::-webkit-scrollbar-thumb": {
          background: "rgba(255, 255, 255, 0.3)", // Adjust the scrollbar color and transparency
          borderRadius: "4px", // Adjust the scrollbar thumb's border radius
        },
      }}

    >
      {userRole != "Applicant" &&(
      <Center pt="4" mt={3} >
        <Image
          src={`${configs?.api}${ (userRole == "Administrator" || userRole == "Staff")  ? configs?.logoWhite : configs?.logoDark}`}
          alt="Logo"
          width={100}
          p={1}
          bg={(userRole == "Administrator" || userRole == "Staff")  ? "#FFFF": "transparent"}
          borderRadius={20}
        />
      </Center>)}

      <VStack 
      mt={30}
       align="stretch" px={3}>
        {menuItemsFiltered.map((item, index) => (
          <SidebarItem
            key={index}
            item={item}
            isActive={userRole == "Applicant" && activePage.includes(item.url) || activePage == item.url}
            px={3}
            userRole={userRole}
            
          />
        ))}
        <Box mt={20}>
        {[{ label: 'Help', url: '/complaints', icons: '/icons/help.svg'}, { label: 'Logout', url: '#', icons: '/icons/logout.svg'}].map((item, index) => (
          <>
          { index == 1 ? (
            <div onClick={handleLogout}>
          <SidebarItem
            key={index}
            item={item}
            isActive={activePage == item.url}
            px={3}
            
          />
          </div>) : ( <SidebarItem
            key={index}
            item={item}
            isActive={activePage == item.url}
            px={3}
            
          />)}</>
        ))}
        </Box>
      </VStack>
    </Box>
  );
}

export default Sidebar;
