import React, { useEffect, useState } from 'react';
import { PDFViewer, Page, View, Document, StyleSheet, Text,   Image  } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { Parser } from 'html-to-react';

import useFetch from '../hooks/useFetch';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    padding: 10,
    width: '100%',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 20,
    textAlign: 'center',
  },
});

const convertHtmlToPdfComponents = (reactElements) => {
    return reactElements.map((element, index) => {
      if (typeof element === 'string') {
        // Handle plain text
        return <Text key={index}>{element}</Text>;
      } else if (element.type === 'img') {
        // Handle image
        const { src, width, height } = element.props;
        return <Image key={index} src={src} style={{ width, height }} />;
      } else if (element.type === 'div') {
        // Handle div element
        const { style, children } = element.props;
        return (
          <View key={index} style={style}>
            {convertHtmlToPdfComponents(children)}
          </View>
        );
      } else if (element.type === 'h1' || element.type === 'h2' || element.type === 'p') {
        // Handle heading and paragraph elements
        const { style, children } = element.props;
        return (
          <Text key={index} style={style}>
            {convertHtmlToPdfComponents(children)}
          </Text>
        );
      } else if (element.type === 'table') {
        // Handle table element
        const { style, children } = element.props;
        return (
          <View key={index} style={style}>
            {convertHtmlToPdfComponents(children)}
          </View>
        );
      } else if (element.type === 'tr') {
        // Handle table row element
        const { children } = element.props;
        return (
          <View key={index}>
            {convertHtmlToPdfComponents(children)}
          </View>
        );
      } else if (element.type === 'td' || element.type === 'th') {
        // Handle table cell element
        const { children } = element.props;
        return (
          <Text key={index}>
            {convertHtmlToPdfComponents(children)}
          </Text>
        );
      } else if (element.type === 'head') {
        // Ignore the head element as it contains metadata not directly rendered
        return null;
      }
  
      // Add more cases for other HTML elements as needed
  
      return null; // Return null for unsupported elements
    });
  };

const PdfViewer = () => {
  const { id, ids } = useParams();
  const { data, loading, error, fetchData } = useFetch();
  const htmlToReactParser = new Parser();
  const [pdfComponents, setPdfComponents] = useState([]);

  useEffect(() => {
    const fetchDataUrl = ids ? `${id}/${ids}` : `${id}`;
    fetchData(fetchDataUrl);
  }, [id, ids, fetchData]);

  useEffect(() => {
    if (data?.data) {
      const reactElements = htmlToReactParser.parse(data.data);
      const pdfComponents = convertHtmlToPdfComponents(reactElements);
      setPdfComponents(pdfComponents);
    }
  }, [data?.data]);

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <PDFViewer style={{ width: '100%', height: '100%' }}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.content}>{pdfComponents}</View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

export default PdfViewer;
