import React, { useState, useEffect } from 'react';
import {
  Box, Spinner
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import GeneratedFeesPage from './GeneratedFeesPage';
import useFormSubmission from '../hooks/useFormSubmission';
import useFetch from '../hooks/useFetch';

const RemitaCheckOut = ({id, type}) => {
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const [message, setMessage] = useState();
  const user = useSelector((state) => state?.auth?.user);
  const configs = useSelector((state) => state?.auth?.config);
  const { data, loading, error, fetchData } = useFetch("Auth API");
  const [paymentId, setPaymentId] = useState();

  useEffect(() => {
    // Fetch programmes when the component mounts
    fetchData(`/payments/byPaymentId/${id?.id}`);
  }, [id?.id]);

  useEffect(() => {
    // Check if data is available before calling handleFormSubmit
    if (!loading && !data?.data) {
      handleFormSubmit();
    }else{
      setPaymentId(data?.data?._id)
    }
  }, [data?.data, loading]);

  

  const handleFormSubmit = async () => {
    const formData = {
      payerName: user?.firstName+" "+user?.lastName,
      payerEmail: user?.email,
      amount: type == 'Acceptance' ? configs?.acceptanceFee : type == "unofficial" ?  configs?.transcript?.download : configs?.applicationFee,
      payerPhone: user?.phoneNumber,
      description: type =='Acceptance' ? "Acceptance Fee" : type == "unofficial" ? "Unoffical Transcript" : "Application Fee",
      paymentId: type != 'Acceptance' ?  id?.id : user?._id
    };
  
    // Submit the form with the selected values
    const { submitSuccess, responseBody } = await submitForm('payments/generate', formData);
    
    
    if (submitSuccess) {
      setMessage(responseBody?.data?.message)
      setPaymentId(responseBody?.data?._id)
      // navigate(`/payment/generated/${responseBody?.data?._id}`);
    } else {
      console.error(submitError || 'Form submission failed');
    }
  }
 

  return (
    <Box textAlign="left" p={2}>
      {!paymentId ? <Spinner /> :
      <GeneratedFeesPage paymentId={ data?.data?._id || paymentId} app={true} /> }
    </Box>
  );
};

export default RemitaCheckOut;
