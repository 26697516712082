import React from 'react';
import { Box, SimpleGrid, Text, Image } from '@chakra-ui/react';
import ApplyButton from './ApplyButton';
import { useSelector } from 'react-redux';

const CardApply = ({cards = []}) => {
  const configs = useSelector((state) => state.auth.config);

  return (
    <Box p={{ base: 0, md: 4}}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
        {cards.map((card) => (
          <Box key={card.id} bg="white" p={4}>
            <Text 
            color={configs?.primaryBg}
            textAlign="center"
            fontFamily="Kumbh Sans"
            fontSize="36px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="normal"
            textTransform="uppercase"
            mb={4}
            >{card.title}</Text>
            <Image  
                width="270px"
                height="288px"
             borderRadius="6px" 
             src={configs?.api+card.uploadsrc} alt="Card Image" 
             mb={4} 
             />
            <Text 
            textAlign="center"
            fontSize="10px"
            fontWeight="600"
            lineHeight="normal"
            mb={4}
            >
              {card.description}
              </Text>
            <ApplyButton buttonText={card.buttonText}/>
          
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default CardApply;
