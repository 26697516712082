import React from 'react';
import { GridItem, SimpleGrid, Box, Text, Image, Progress } from '@chakra-ui/react';

const SideCard = ({ title, progress, value }) => {
  return (
    <GridItem bg="white" borderRadius="8.56px" padding={5} my={3}>
      <SimpleGrid 
        columns={2}
        alignContent="center" 
        justifyContent="flex-start"  
        textAlign={'left'} display="flex" 
        
        gap={5}
      >
        
        <Box textAlign="left" 
            justifyContent="center" 
            display="flex" 
            flexDirection="column">

          <Text color="#1B212D" fontWeight={700} lineHeight={4} fontSize={12}>
            {title} 
          </Text>
         
        </Box>
        <Image src="/icons/warning-2.svg" />
      </SimpleGrid>

      <Box>
        <SimpleGrid columns={2} display="flex" justifyContent="space-between">
          <Text fontSize={12} fontWeight={500} alignItems="flex-start" mt={2}>
            {value} days(s) left
          </Text>
          <Box display="flex" alignItems="center" fontSize={13}>
          {progress}%
          </Box>
        </SimpleGrid>

        <Progress value={progress} borderRadius={10} size="md" colorScheme="teal" />
      </Box>
    </GridItem>
  );
};

export default SideCard;
