// Dashboard.js
import React, { useEffect, useState } from 'react';
import { Box, Text, Center } from '@chakra-ui/react';
import TopBar from '../components/TopBar';
import useFetch from '../hooks/useFetch';
import DataTable from '../components/DataTable';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const ApplicationsList = () => {
  const navigate = useNavigate(); // Create a navigate function
  

    const { data: application, loading, error, fetchData } = useFetch('Auth API');
    
    const headers = [
        { label: 'Programme', key: 'programme.name' },
        { label: 'Course', key: 'course.name' },
        { label: 'Session', key: 'session.name' },
        { label: 'Status', key: 'status' },
        { label: 'Date', key: 'createdAt' },
      ];
      const buttons = [
        {
          color: 'darkblue',
          label: 'Continue',
          onClick: (item) => handleView(item),
        }
      ];
    const handleView = (item) =>{
      console.log(item?._id)
      
        if (item?.status?.toLowerCase() == "ongoing")
            navigate(`/application/biodata/${item?._id}`);
        else
          navigate(`/application/review/${item?._id}`);
    }
    const handleEdit = () =>{

    }
    useEffect(() => {
        fetchData('/applications/user');
      }, []);
    
  return (
    <Box bg="#F6F6FE" minHeight={'100vh'}>
      <TopBar type={"normal"} />
      <Center>
        <Box display={'flex'} height={'100%'} flexDirection={'column'} bg="white" borderRadius={11} mt={17} p={10} width="75%">
        <Link to='/dashboard/application'> Back </Link>
        <Text ml={5} mt={10} fontWeight={600} mb={5}>List of application</Text>
    <DataTable
        data={application?.data || []}
        headers={headers}
        buttons={buttons}
        page={application?.currentPage}
        // onPageChange={(newPage) =>  setDynamicPath(`/applications/${newPage}`)}
        pageSize={application?.totalPages}
        totalItems={application?.totalItems}
        loading={loading}
        
      />
    </Box>
    </Center>
    </Box>
  );
};

export default ApplicationsList;
