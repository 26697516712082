import React, {useState, useEffect} from 'react';
import {
  Box,
  Flex,
  Image,
  Input,
  Button,
  VStack,
  Text,
  useMediaQuery,
  FormLabel,
  Grid
} from '@chakra-ui/react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../auth/authActions';


const LoginPage = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const dispatch = useDispatch();
  const { isAuthenticated, user, error, config } = useSelector((state) => state.auth);
  const configs = useSelector((state)=> state.auth.config)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
   
    setCredentials((prevCredentials) => ({ ...prevCredentials, [name]: value }));
  };

  const handleLogin = (e) => {
 
    e.preventDefault();
   
    // Dispatch the login action with the entered credentials
  //  console.log(credentials)
     dispatch(login(credentials));
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.role?.name =="Applicant")
        navigate('/dashboard/application');
      else
        navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  return (
    <Flex h="100vh" align="center" justify="center">
      {isLargerThan768 && (
        <Box w="50%">
          {/* Right side image */}
          <Image src="/images/login.png" alt="LoginImage" 
          position={'absolute'}
          top={0}
          objectFit="cover" w="50%" h="100%"
          
          />
          <Box position={'absolute'} background={config?.primaryBg+85} 
          top={0}
          width={'50%'} height={'100%'}></Box>
        </Box>
      )}

      <Box w={{ base: '100%', md: '50%' }} 
      p={4}
      // display="flex"
      // padding="161px 150.06px 253.025px 150px"
      flexDirection="column"
      // alignItems="center"
     
      >
        {/* Left side login box */}
        <VStack 
        // spacing={4} 
        align="stretch" 
        padding={["20px 27px", "20px 100px"]}
        // padding="16.498px 23.996px"
        flexDirection="column"
        // alignItems="center"
        // gap="15px"
        >
          <Box 
          display={'flex'}
          justifyContent={'center'}
          alignItems="center">
          <Image src={config?.api+config?.logoDark} alt={'logo'} width={"100px"} />
          </Box>
          <Text textAlign="center" color="#87898E" mb={5} fontWeight={600}>
           Login page
          </Text>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <form onSubmit={handleLogin}>
            <FormLabel color="#87898E" mt={2}
              fontSize="14px"
              fontStyle="normal"
              fontWeight="500"
               lineHeight="17.997px"
               >Email</FormLabel>
            <Input type="text"  name="email" onChange={handleInputChange}/>

            <FormLabel color="#87898E" mt={2}
              fontSize="14px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="17.997px">Password</FormLabel>
            <Input type="password"  name="password" onChange={handleInputChange}/>
            <Button 
              type='submit'
              display={'flex'}
              borderRadius="7.261px"
              background={`${configs?.primaryBg}`}
              color={`${configs?.primaryColor}`}
              width={'100%'}
              mt={6}
              size="lg">
              Login
            </Button>
          </form>
          <Grid  templateColumns={['1fr repeat(1fr 2)']} gap={4}>
          <Link to='/register'>
          <Text fontSize={12}>Don't have an account? Register</Text>
          </Link>

          <Link to='/forgot-password'>
          <Text fontSize={12}>Forgot Password?</Text>
          </Link>
          </Grid>
        </VStack>
      </Box>
    </Flex>
  );
};

export default LoginPage;
