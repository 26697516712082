// src/routes.js

import { lazy } from 'react';
import TranscriptDisplay from '../pages/TranscriptDisplay';
import PaymentHistory from '../pages/PaymentHistory';
import GenerateTranscript from '../pages/GenerateTranscript';
import TranscriptHistory from '../pages/TranscriptHistory';
import RegisterCourse from '../pages/RegisterCourse';
import CourseHistory from '../pages/CourseHistory';
import AddSchoolFeesPage from '../pages/AddSchoolFees';
import ReviewPage from '../pages/ReviewPage';
import Applicants from '../pages/Applicants';
import CreateCourse from '../pages/CreateCourse';
import AvailableCourse from '../pages/AvailableCourse';
import AcademicCalendarForm from '../pages/AcademicCalendar';
import TranscriptManagement from '../pages/TranscriptManagement';
import AdmissionLetter from '../pages/AdmissionLetter';
import GeneratedFeesPage from '../pages/GeneratedFeesPage';
import GenerateFees from '../pages/GenerateFees';
import StudentUpload from '../pages/StudentUpload';
import StudentImage from '../pages/StudentImage';
import StudentEducation from '../pages/StudentEducation';
import StudentBiodata from '../pages/StudentBiodata';
import Complaints  from '../pages/Complaints';
import Admission  from '../pages/Admission';
import StudentProfile  from '../pages/StudentProfile';
import StaffList  from '../pages/StaffList';
import StudentData from '../pages/StudentData';
import Result from '../pages/Result';
import NewApplication from '../pages/NewApplication';
import ApplicationDashboard from '../pages/ApplicationDashboard';
import Application from '../pages/Application';
import ApplicationsList from '../pages/Applications';
// import DashboardPage from '../pages/Dashboard';
import LandingPage from '../pages/LandingPage';
import LoginPage  from'../pages/LoginPage.js'
import RegisterPage  from'../pages/RegisterPage.js'
import Dashboard  from'../pages/Dashboard.js'
import Payment  from'../pages/Payment.js'
import CourseReg  from'../pages/CourseRegistration.js'
import Transcripts  from'../pages/Transcripts.js'
import StudentApplication  from'../pages/StudentApplication.js'
import NewsDetails from '../pages/NewsDetails';
import StudentManagement  from'../pages/StudentManagement.js'
import CommunicationManagement  from'../pages/CommunicationManagement.js'
import StaffManagement  from'../pages/StaffManagement.js'
// import Settings  from'../pages/Settings.js'
import AdmissionManagement  from'../pages/AdmissionManagement.js'
import Enrolment  from'../pages/Enrolment.js'
import AcademicPerformance  from'../pages/AcademicPerformance.js'
import CourseAdmin from '../pages/CourseAdmin';
import SendMessagePage from '../pages/SendMessage';
import Settings from '../pages/Settings.js';
import SiteInformationForm from '../pages/SiteInformation';
import TranscriptSettingsForm from '../pages/TranscriptSettings';
import NewsPostPage from '../pages/NewsPostPage';
import NewsPage from '../pages/NewsPage';
import GenerateResult from '../pages/GenerateResult';
import AppealResult from '../pages/AppealResult';
import PdfViewer from '../pages/PdfViewer';
import ResetPasswordPage from '../pages/ResetPassword';
import SetPasswordPage from '../pages/SetPassword';
import ApplyCard from '../pages/ApplyCards';
import ProgrammeCard from '../pages/ProgrammeCards';
import GenerateUnofficialTranscript from '../pages/GenerateUnofficialTranscript';
import EClearance from '../pages/EClearance';
import EClearanceStudent from '../pages/EClearanceStudent';
import AdmissionClearanceForm from '../pages/AdmissionClearanceForm';
import GraduatingClearanceForm from '../pages/GraduatingClearanceForm';
import GraduationClearanceStudent from '../pages/GraduationClearanceStudent';
import AdmissionClearanceStudent from '../pages/AdmissionClearanceStudent';
import UnofficialTranscript from '../pages/UnofficialTranscript';
import GraduatedTracker from '../pages/GraduatedTracker';
import AdmittedTracker from '../pages/AdmittedTracker';
import ProgrammeDetails from '../pages/ProgrammeDetails';
import Contact from '../pages/Contact';
import TranscriptAdmin from '../pages/TranscriptAdmin';
import PaymentAdmin from '../pages/PaymentAdmin';
import PaymentHistoryAdmin from '../pages/PaymentHistoryAdmin';
import Others from '../pages/Others';
import School from '../pages/School';
import Faculty from '../pages/Faculty';
import Department from '../pages/Department';
import Programme from '../pages/Programme'
import Level from '../pages/Level';
import Clearance from '../pages/Clearance';
import ApprovalPermissions from '../pages/ApprovalPermissions';

const routes = [
  {
    path: '/',
    exact: true,
    component: <LandingPage />,
  },{
    path: '/dashboard',
    exact: true,
    component: <Dashboard />,
  },
  {
    path: '/login',
    exact: true,
    component: <LoginPage />,
  },
  {
    path: '/register',
    exact: true,
    component: <RegisterPage />,
  }, 
  {
    path: '/application/:page/:id',
    exact: true,
    component: <Application />,
  },  {
    path: '/dashboard/:application',
    exact: true,
    component: <ApplicationDashboard />,
  },
  {
    path: '/new',
    exact: true,
    component: <NewApplication />,
  },
  {
    path: '/applications-list',
    exact: true,
    component: <ApplicationsList />,
  }, {
    path: '/student',
    exact: true,
    component: <StudentData />,
  }, {
    path: '/payment',
    exact: true,
    component: <Payment />,
  },{
    path: '/courses',
    exact: true,
    component: <CourseReg />,
  },{
    path: '/transcripts',
    exact: true,
    component: <Transcripts />,
  },{
    path: '/application-student',
    exact: true,
    component: <StudentApplication />,
  },{
    path: '/result',
    exact: true,
    component: <Result />,
  },{
    path: '/student-management',
    exact: true,
    component: <StudentManagement />,
  },{
    path: '/staff-management',
    exact: true,
    component: <StaffManagement />,
  },
  {
    path: '/e-clearance',
    exact: true,
    component: <EClearance />,
  }, {
    path: '/admission-clearance',
    exact: true,
    component: <AdmissionClearanceStudent />,
  }, {
    path: '/graduation-clearance',
    exact: true,
    component: <GraduationClearanceStudent />,
  },{
    path: '/admission-clearance-form',
    exact: true,
    component: <AdmissionClearanceForm />,
  }, {
    path: '/graduation-clearance-form',
    exact: true,
    component: <GraduatingClearanceForm />,
  },
  {
    path: '/admissions-management',
    exact: true,
    component: <AdmissionManagement />,
  },{
    path: '/communications',
    exact: true,
    component: <CommunicationManagement />,
  },
  {
    path: '/enrolment',
    exact: true,
    component: <Enrolment />,
  },{
    path: '/student-profile',
    exact: true,
    component: <StudentProfile />,
  },{
    path: '/academic-performance',
    exact: true,
    component: <AcademicPerformance />,
  },{
    path: '/complaints',
    exact: true,
    component: <Complaints />,
  },{
    path: '/staff-list',
    exact: true,
    component: <StaffList />,
  },{
    path: '/admission-list',
    exact: true,
    component: <Admission />,
  },{
    path: '/admission-letter',
    exact: true,
    component: <AdmissionLetter />,
  },{
    path: '/applicants',
    exact: true,
    component: <Applicants />,
  },{
    path: '/student/biodata',
    exact: true,
    component: <StudentBiodata />,
  },{
    path: '/student/upload',
    exact: true,
    component: <StudentUpload/>,
  },{
    path: '/student/education',
    exact: true,
    component: <StudentEducation />,
  },{
    path: '/student/image',
    exact: true,
    component: <StudentImage/>,
  },{
    path: '/payment/generate',
    exact: true,
    component: <GenerateFees />,
  },
  {
    path: '/payment/generated',
    exact: true,
    component: <GeneratedFeesPage />,
  }, {
    path: '/payment/generated/:id',
    exact: true,
    component: <GeneratedFeesPage />,
  },
  {
    path: '/payment/history',
    exact: true,
    component: <PaymentHistory />,
  },{
    path: '/payments/history',
    exact: true,
    component: <PaymentHistoryAdmin />,
  },
  {
    path: '/payment/history/:id',
    exact: true,
    component: <PaymentHistory />,
  },
  {
    path: '/transcripts/request',
    exact: true,
    component: <GenerateTranscript />,
  },
  {
    path: '/transcripts/history',
    exact: true,
    component: <TranscriptHistory />,
  },
  {
    path: '/courses/register',
    exact: true,
    component: <RegisterCourse />,
  },
  {
    path: '/courses/history',
    exact: true,
    component: <CourseHistory />,
  },{
    path: '/courses/add',
    exact: true,
    component: <CreateCourse />,
  },{
    path: '/courses/available',
    exact: true,
    component: <AvailableCourse />,
  },
  {
    path: '/payments',
    exact: true,
    component: <PaymentAdmin />,
  },{
    path: '/payment/school-fees',
    exact: true,
    component: <AddSchoolFeesPage />,
  },
  {
    path: '/review/:id',
    exact: true,
    component: <ReviewPage />,
  },
  {
    path: '/results/view-transcripts/:id',
    exact: true,
    component: <TranscriptDisplay />,
  },
  {
    path: '/transcripts-management',
    exact: true,
    component: <TranscriptManagement />,
  },
  {
    path: '/courses-admin',
    exact: true,
    component: <CourseAdmin />,
  },
  {
    path: '/broadcast',
    exact: true,
    component: <SendMessagePage />,
  },
  {
    path: '/settings',
    exact: true,
    component: <Settings />,
  },
  {
    path: '/site-details',
    exact: true,
    component: <SiteInformationForm />,
  },
  {
    path: '/transcript-settings',
    exact: true,
    component: <TranscriptSettingsForm />,
  },
  {
    path: '/academic-calendar',
    exact: true,
    component: <AcademicCalendarForm />,
  },
  {
    path: '/news',
    exact: true,
    component: <NewsPostPage />,
  },
  {
    path: '/latest_news',
    exact: true,
    component: <NewsPage />,
  },
  {
    path: '/result/view',
    exact: true,
    component: <GenerateResult />,
  }, {
    path: '/eclearance',
    exact: true,
    component: <EClearanceStudent />,
  },
  {
    path: '/result/appeal',
    exact: true,
    component: <AppealResult />,
  },
  {
    path: '/preview/:id',
    exact: true,
    component: <PdfViewer />,
  },
  {
    path: '/preview/:id/:ids',
    exact: true,
    component: <PdfViewer />,
  },
  {
    path: '/forgot-password',
    exact: true,
    component: <ResetPasswordPage />,
  },
  {
    path: '/reset-password/:id',
    exact: true,
    component: <SetPasswordPage />,
  },
  {
    path: '/apply-cards',
    exact: true,
    component: <ApplyCard />,
  }, {
    path: '/programme-cards',
    exact: true,
    component: <ProgrammeCard />,
  }, {
    path: '/news/:id',
    exact: true,
    component: <NewsDetails />,
  },{
    path: '/transcripts/unofficial',
    exact: true,
    component: <UnofficialTranscript />,
  },{
    path: '/track-admission-clearance',
    exact: true,
    component: <AdmittedTracker />,
  },{
    path: '/track-graduation-clearance',
    exact: true,
    component: <GraduatedTracker />,
  },{
    path: '/programmes',
    exact: true,
    component: <ProgrammeDetails/>,
  },{
    path: '/contact',
    exact: true,
    component: <Contact />,
  },{
    path: '/transcript/admin',
    exact: true,
    component: <TranscriptAdmin />,
  },{
    path: '/others',
    exact: true,
    component: <Others />,
  },{
    path: '/school-structure',
    exact: true,
    component: <School />,
  },{
    path: '/faculty',
    exact: true,
    component: <Faculty />,
  },{
    path: '/programme',
    exact: true,
    component: <Programme />,
  },{
    path: '/department',
    exact: true,
    component: <Department />,
  },{
    path: '/levels',
    exact: true,
    component: <Level />,
  },{
    path: '/clearance',
    exact: true,
    component: <Clearance />,
  },{
    path: '/permissions',
    exact: true,
    component: <ApprovalPermissions />,
  }




  // {
  //   path: '/dashboard',
  //   component: <DashboardPage />,
  //   guards: [authGuard], // Apply the authGuard
  // }
];

export default routes;
