import React, { useState, useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import DataTable from '../components/DataTable';  // Replace with the actual path
import useFetch from '../hooks/useFetch';
const PaymentHistoryAdmin = () => {
  const { data: payments, loading, error, fetchData } = useFetch();
  const [dynamicPath, setDynamicPath] = useState('/payments');
  let dynamicParams = ""
       
      const headers = [
        { label: 'Date', key: 'createdAt' },
        { label: 'First Name', key: 'userId.firstName' },
        { label: 'Last Name', key: 'userId.lastName' },
        { label: 'Description', key: 'description' },
        { label: 'Amount', key: 'amount' },
        { label: 'Status', key: 'status' },
      ];
      useEffect(() => {
        fetchData(dynamicPath, dynamicParams);
      }, [dynamicPath]);
    
    

  const buttons = [
    // Define your action buttons here
  ];

  // ... Other necessary state and logic

  return (
    <DashboardLayout sidebar="two">
      <Box borderRadius={11} px={2} py={5} width={["120%", '100%']}>
        <Box
          bg="white"
          borderRadius={11}
          mt={2}
          p={5}
          width={['200', '100%']}
          height={['100%', '100%']}
        >
          <Text fontFamily="Kumbh Sans" fontSize="16px" fontWeight="500" lineHeight="20px" letterSpacing="0em" textAlign="left" color="#374151" mb={4}>
            Payment History
          </Text>
          <DataTable
                data={payments?.data || []}
                headers={headers}
                buttons={buttons}
                page={payments?.currentPage}
                onPageChange={(newPage) =>  setDynamicPath(`/payments/${newPage}`)}
                pageSize={payments?.totalPages}
                totalItems={payments?.totalItems}
                loading={loading}
                search={true}
                exports={true}
                pagination={true}
              />
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default PaymentHistoryAdmin;
