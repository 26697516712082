// ComplaintsPage.js

import React, { useState , useEffect} from 'react';
import {
  Box,
  Button,
  Text,
  Grid,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import DataTable from '../components/DataTable'; // Adjust the path based on your project structure
import DashboardLayout from '../components/DashboardLayout';
import { useSelector } from 'react-redux';
import useFetch from '../hooks/useFetch';
import InformationCard from '../components/InformationCard';
const ComplaintsPage = () => {
  const user = useSelector((state) => state?.auth?.user);
  const configs = useSelector((state)=> state?.auth?.config)
  const { data: complaint, loading, error, fetchData } = useFetch();
  const [dynamicPath, setDynamicPath] = useState(user?.role?.name == "Student" ? '/complaints/byUser' : '/complaints');
  const dynamicParams = "";
  const [complaints, setComplaints] = useState([]);
  const [newComplaint, setNewComplaint] = useState({
    title: '',
    description: '',
  });

  useEffect(() => {
    fetchData(dynamicPath);
  }, [dynamicPath]);


  const [selectedComplaint, setSelectedComplaint] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: detailsOpen, onOpen: detailsOnOpen, onClose: detailsClose } = useDisclosure();

  const handleFormSubmit = () => {
    if (newComplaint.title && newComplaint.description) {
      setComplaints([...complaints, { ...newComplaint, id: complaints.length + 1 }]);
      setNewComplaint({
        title: '',
        description: '',
      });
      onClose(); // Close the modal after submitting the complaint
    }
  };

  const handleReply = (complaintId) => {
    // Implement your reply logic here
    console.log(`Reply to complaint with ID ${complaintId}`);
    // Close the details modal after submitting a reply
    detailsClose();
  };


  const headers = [
    { label: 'Title', key: 'title' },
    { label: 'Description', key: 'description' }
  ];
const buttons =[]
  const actions = [
    {
      label: 'View Details',
      color: '#343CF0',
      onClick: (complaint) => {
        setSelectedComplaint(complaint);
        detailsOnOpen();
      },
    },
    // Add more actions as needed
  ];


  return (
    <DashboardLayout sidebar={"two"}>
    <Box borderRadius={11} px={2} py={5} width={["120%", '100%' ]}>
  <Box 
    bg="white" 
    borderRadius={11} 
    mt={2} 
    p={5}  
    width={['200','100%']}
    height={['100%', '80vh']}
    // display={'flex'}
  >
      <Text fontFamily="Kumbh Sans" fontSize="16px" fontWeight="700" mb={5}>Complaints </Text>
      {user?.role?.name == "Student" ?
      <Box display="flex" justifyContent={'flex-end'}>
     
      <Button   fontFamily="Kumbh Sans"

       mb={4} color={`${configs?.primaryColor}`} bg={`${configs?.primaryBg}`} 
      fontSize={12} borderRadius={10} onClick={onOpen}>
        Create a Complaint
      </Button></Box>:   <Grid
            templateColumns="repeat(4, 1fr)"
            gap={2}
            mt={3}
            // maxW="600px"
            // margin="0 auto"
        >
            <InformationCard title="Total No of Complaints" value={complaints?.data?.length} semesterInfo="This semester" />
         
        </Grid>}

      {/* Complaints Form Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create a New Complaint</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
         
              <Box flex={1} mr={4}>
                <FormControl>
                  <FormLabel>Title</FormLabel>
                  <Input
                    type="text"
                    value={newComplaint.title}
                    onChange={(e) =>
                      setNewComplaint({ ...newComplaint, title: e.target.value })
                    }
                  />
                </FormControl>
              </Box>
              <Box flex={2}>
                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    value={newComplaint.description}
                    onChange={(e) =>
                      setNewComplaint({
                        ...newComplaint,
                        description: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Box>
            
          </ModalBody>

          <ModalFooter>
            <Button color={`${configs?.primaryColor}`} bg={`${configs?.primaryBg}`} fontSize={12} borderRadius={10} onClick={handleFormSubmit}>
              Submit 
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Complaints Table */}
      <Box mt={3}>
        
      <DataTable
                data={complaint?.data || []}
                headers={headers}
                buttons={buttons}
                page={complaint?.currentPage}
                onPageChange={(newPage) =>  setDynamicPath(`/complaints/${newPage}`)}
                pageSize={complaint?.totalPages}
                totalItems={complaint?.totalItems}
                loading={loading}
                search={true}
                exports={true}
                pagination={true}
              />
      </Box>
      <Modal  isOpen={detailsOpen} onClose={detailsClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Complaint Details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {selectedComplaint && (
                <>
                  <Box mb={4}>
                    <strong>Title:</strong> {selectedComplaint.title}
                  </Box>
                  <Box mb={4}>
                    <strong>Description:</strong> {selectedComplaint.description}
                  </Box>
                  {/* Add more details as needed */}
                </>
              )}
              {/* Space to reply */}
              <FormControl>
                <FormLabel>Reply</FormLabel>
                <Textarea placeholder="Type your reply here..." />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" onClick={() => handleReply(selectedComplaint.id)}>
                Submit Reply
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
    </Box>
    </Box>
    </DashboardLayout>
  );
};

export default ComplaintsPage;
