import React, { useEffect, useState } from 'react';
import { VStack, Text, Box, Grid, GridItem, Select, Button,
Table, Tr, Th, Thead, Tbody, Td} from '@chakra-ui/react';
import useFetch from '../hooks/useFetch';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useFormSubmission from '../hooks/useFormSubmission';
const Step4 = () => {
  let {id} = useParams();
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const { data: application, loading, error, fetchData } = useFetch();
  const config = useSelector((state)=> state.auth.config)
  const user = useSelector((state)=> state.auth.user)
  const [dynamicPath, setDynamicPath] = useState('applications/details/'+id);
  const [status, setStatus] = useState({
    _id: id
  });
  const [message, setMessage] = useState();
  const [mainField, setMainField] = useState({});
  const [formData, setData] = useState({
    title: '',
    surname: '',
    firstName: '',
    middleName: '',
    maidenName: '',
    maritalStatus: '',
    dateOfBirth: '',
    nationality: '',
    state: '',
    localGovernmentArea: '',
    permanentHomeAddress: '',
    telephone: '',
    mobile: '',
    emailAddress: '',
    religion: '',
    nextOfKin: '',
    nextOfKinPhone: '',
    nextOfKinAddress: '',
    sponsorName: '',
    sponsorPhone: '',
    sponsorAddress: '',
    educationalHistory: [
      {
        schoolName: '',
        schoolAddress: '',
        courseOfStudy: '',
        qualificationObtained: '',
        fromDate: '',
        toDate: '',
        grade: '',
      },
      // Add more educational history entries if needed
    ],
    uploads: [
      {
        title: '',
        url: ''
      }
      // Add more upload entries if needed
    ],
  })

  useEffect(() => {
    // Only fetch data if application is null
    if (!application?.data) {
      fetchData(dynamicPath);
    } else {
      // Use the existing application data
      setData(application?.data);
      
      // setFormDatas(application?.data)
      if( application?.data?.biodata){
  
      let {
        biodata: {
          title,
          lastName,
          firstName,
          maritalStatus,
          dateOfBirth,
          nationality,
          state,
          localGovernmentArea,
          permanentHomeAddress,
          telephone,
          mobile,
          emailAddress,
          religion,
          nextOfKin,
          nextOfKinPhone,
          nextOfKinAddress,
          sponsorName,
          sponsorPhone,
          sponsorAddress,
        },
        course: { name: courseName },
        education,
        programme: { name: programmeName },
        session: { name: sessionName },
        status,
        upload: uploads, 
      } = application.data;
         
    
    const educationInformation = education.map((eduItem) => ({
      schoolName: eduItem.schoolName,
      schoolAddress: eduItem.schoolAddress,
      courseOfStudy: eduItem.courseOfStudy,
      qualificationObtained: eduItem.qualificationObtained,
      fromDate: new Date(eduItem.fromDate).toLocaleDateString("en-US"),
      toDate: new Date(eduItem.toDate).toLocaleDateString("en-US"),
      grade: eduItem.grade,
    }));
  
      setMainField({
        title,
        lastName,
        firstName,
        maritalStatus,
        dateOfBirth,
        nationality,
        state,
        localGovernmentArea,
        permanentHomeAddress,
        telephone,
        mobile,
        emailAddress,
        religion,
        nextOfKin,
        nextOfKinPhone,
        nextOfKinAddress,
        sponsorName,
        sponsorPhone,
        sponsorAddress,
        educationalHistory: educationInformation,
        programmeInformation: [
          {
            programme: programmeName,
            session: sessionName,
            course: courseName,
            status
          },
        ],
        uploads: uploads,
      });
    }}
  }, [application?.data]);
  
  const handleSubmit = async() =>{
    
    const submissionResult = await submitForm(`applications/status`, status, "json");
    
    if (submissionResult.submitSuccess) {
      setMessage(submissionResult?.responseBody?.message)
    }
  }
  const handleInputChange = (field, value) => {
    setStatus(prevData => ({ ...prevData, [field]: value }));
  };

  
    // Function to apply title case to object keys
    const applyTitleCaseToObject = (obj) => {
      const applyTitleCase = (value) => {
        if (Array.isArray(value)) {
          return value?.map(applyTitleCase);
        } else if (typeof value === 'object' && value !== null) {
          return applyTitleCaseToObject(value);
        } else if (typeof value === 'string') {
          return value?.replace(/([A-Z])/g, ' $1').toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase());
        }
        return value;
      };
    
      const newObj = {};
      for (const [key, value] of Object.entries(obj)) {
        newObj[key?.replace(/([A-Z])/g, ' $1')?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase())] = applyTitleCase(value);
      }
      return newObj;
    };
    
    const dataToDisplay =  mainField?.title ? mainField : formData;
    // Apply title case to formData keys
    const formData2 = applyTitleCaseToObject(dataToDisplay);

    const renderSection = (title, data) => (
      <Box>
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          {title}:
        </Text>
        {title === 'Uploads' ? (
          <Table variant="striped" fontSize={13}>
            <Thead fontSize={13}>
              <Tr fontSize={13}>
                <Th fontSize={13}>Title</Th>
                <Th fontSize={13}>URL</Th>
              </Tr>
            </Thead>
            <Tbody fontSize={13}>
              {data?.map((item, index) => (
                <Tr fontSize={13} key={index}>
                  <Td fontSize={13}>{item?.Title}</Td>
                  <Td fontSize={13}>
                    <a href={config?.api + item?.Url} target="_blank" rel="noopener noreferrer">
                      View
                    </a>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          data?.map((item, index) => (
            <Grid
              templateColumns={['1fr', 'repeat(4,1fr)']}
              key={index}
              width="100%"
              borderRadius={100}
            >
              {Object.entries(item)?.map(([field, fieldValue], fieldIndex) => (
                <GridItem key={fieldIndex} p={2}>
                  <Text fontSize={12} fontWeight={600}>
                    {typeof fieldValue === 'object' ? '' : field}
                  </Text>
                  <Text fontSize={13}>{typeof fieldValue === 'object' ? '' : fieldValue}</Text>
                </GridItem>
              ))}
            </Grid>
          ))
        )}
      </Box>
    );
    

  return (
    <VStack align="start" spacing={4} minWidth="100%">
      {renderSection('Programme Information', formData2["Programme Information"])}
      {renderSection('Personal Information', [formData2])}
      {renderSection('Educational History', formData2["Educational History"])}
      {renderSection('Uploads', formData2.Uploads)}
    { user?.role?.name == 'Administrator' && (
    <form >
      <Select fontSize={'12'} name="status"  onChange={(e) => handleInputChange('status', e.target.value)}>
        <option></option>
        <option>Admitted</option>
        <option>Rejected</option>
      </Select>
      {submitError && (<Text color="red.500">{submitError}</Text>)}
      <Text color="green.500">{message}</Text>
      <Button 
        onClick={handleSubmit}
      mt={3} fontSize={12} bg={config?.primaryBg} color={config?.primaryColor} > 
      
      { isSubmitting ? '...Submitting': 'Update'}</Button>
      </form>)}
    </VStack>
  );
};

export default Step4;
