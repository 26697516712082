// Dashboard.js
import React, { useEffect } from 'react';
import { Box, Text, Image, Center, SimpleGrid } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import { Link } from 'react-router-dom';
import ApplicationLink from '../components/ApplicationLink';

const StudentData = () => {
  
  return (
    <DashboardLayout sidebar={"two"}>
     <Box 
      borderRadius={11} px={2} py={5} 
      width={["120%", '100%' ]}>
      <Box 
        bg="white" 
        borderRadius={11} 
        mt={2} 
        p={5}  
        width={['200','100%']}
        height={['100%', '80vh']}
        display={'flex'}
      >
        <SimpleGrid
          height={['100%', '50%']}
          mt={5}
          flexDirection={['row','column']}
          columns={[1, 4]} // Set the number of columns based on your design
          spacing={4}
          gap={5}
          px={{ base: 10, md: 50 }}
          justifyContent={'center'}
        >
          <ApplicationLink
            link={'/student/image'}
            icon={'menu-profile-circle.svg'}
            title='Update Passport'
            mb={5} // Add margin bottom to control the space at the bottom
          />
          <ApplicationLink
            link={'/student/biodata'}
            icon={'menu-document-text.svg'}
            title='Basic Data'
            mb={5}
          />
          <ApplicationLink
            link={'/student/education'}
            icon={'menu-teacher.svg'}
            title='Education History'
            mb={5}
          />
          
          <ApplicationLink
            link={'/student/upload'}
            icon={'document-upload-nw.svg'}
            title='Document Upload'
            mb={5}
          />
          <ApplicationLink
            link={'/admission-letter'}
            icon={'printer.svg'}
            title='Print Admission Letter'
            mb={5}
          />
         
          
            {/* <ApplicationLink
              link={'#'}
              icon={'menu-shield-slash.svg'}
              title='Deferment Application'
              ></ApplicationLink> */}
        </SimpleGrid>
      </Box>
    </Box>
    </DashboardLayout>
  );
};

export default StudentData;
