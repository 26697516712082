import React, { useState, useEffect} from 'react';
import { Box } from '@chakra-ui/react';
import Header from '../components/Header'; // Assuming you have a Header component
import LandscapeCard from '../components/LandscapeCard'; // Replace with your LandscapeCard component
import NewsSection from '../components/NewsSection'; // Assuming you have a NewsSection component
import useFetch from '../hooks/useFetch';
import { useSelector } from 'react-redux';
const NewsPage = () => {
  const { data: featured, loading: featuredLoading, error: errorFeatured, fetchData: fetchFeature } = useFetch();
  const { data: news, loading: newsLoading, error: errorNews, fetchData: fetchNews } = useFetch();
  const [dynamicPath, setDynamicPath] = useState('/news/pagination');
  let dynamicParams = ""
  const configs = useSelector((state) => state.auth.config);
  useEffect(()=>{
    fetchNews(dynamicPath);
    fetchFeature('/news/featured');
  },[dynamicPath])
  
  return (
    <Box>
      <Header config={configs?.api+configs?.logoDark ? configs?.api+configs?.logoDark: "/images/logo.png"} buttonText="Subscribe" doubleGrid>
      
      </Header>
      <Box px={20} display={'inline-block'}>
      <LandscapeCard {...featured?.data} />
      <Box mt={20}>
      <NewsSection newsData={news?.data} loading={newsLoading} 
      home={false} 
      page={news?.currentPage}
      onPageChange={(newPage) =>  setDynamicPath(`/news/pagination/${newPage}`)}
      pageSize={news?.totalPages}
      totalItems={news?.totalItems}
      />
      </Box>
      </Box>
    </Box>
  );
};

export default NewsPage;
