import React, { useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Button, Box, Text } from '@chakra-ui/react';

const SelectableCourseTable = ({ data, onCourseSelect, pageSize }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil((data?.length || 0) / pageSize);
  const toggleRowSelection = (rowId) => {
    console.log(rowId)
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(rowId)) {
        return prevSelectedRows.filter((id) => id !== rowId);
      } else {
        return [...prevSelectedRows, rowId];
      }
    });
  };

  
  const renderRows = () => {
    if (!Array.isArray(data) || data.length === 0) {
      return (
        <Tr>
          <Td colSpan={4} textAlign="center">
            <Text>No courses available.</Text>
          </Td>
        </Tr>
      );
    }

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const visibleData = data.slice ? data.slice(startIndex, endIndex) : [];

    if (visibleData.length === 0) {
      return (
        <Tr>
          <Td colSpan={4} textAlign="center">
            <Text>No courses available on this page.</Text>
          </Td>
        </Tr>
      );
    }

    return visibleData?.map((course) => (
      <Tr
        key={course?.courseId?.id}
        onClick={() => {
          console.log(course?.courseId._id)
          toggleRowSelection(course?.courseId?._id);
          onCourseSelect(course, !selectedRows.includes(course._id));
        }}
        cursor="pointer"
        bg={selectedRows.includes(course?.courseId?._id) ? 'blue.100' : ''}
      >
        <Td>{course?.courseId?.code}</Td>
        <Td>{course?.courseId?.name}</Td>
        <Td>{course?.courseId?.status}</Td>
        <Td>{course?.courseId?.unit}</Td>
      </Tr>
    ));
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
console.log(selectedRows)
  return (
    <Box>
      <Table variant="simple" fontSize={12} width={'100%'}>
        <Thead>
          <Tr>
            <Th>Course Code</Th>
            <Th>Title</Th>
            <Th>Status</Th>
            <Th>Units</Th>
          </Tr>
        </Thead>
        <Tbody width={'100%'}>{renderRows()}</Tbody>
      </Table>

      {data && data.length > 0 && (
        <Box mt={4} textAlign="center">
          <Button
            variant="outline"
            size="sm"
            onClick={() => handlePageChange(currentPage - 1)}
            isDisabled={currentPage === 1}
          >
            Previous Page
          </Button>
          <Button
            variant="outline"
            size="sm"
            ml={2}
            onClick={() => handlePageChange(currentPage + 1)}
            isDisabled={currentPage === totalPages}
          >
            Next Page
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SelectableCourseTable;
