import React, { useState, useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import DataTable from '../components/DataTable';  // Replace with the actual path
import useFetch from '../hooks/useFetch';

const TranscriptHistory = () => {
  const { data: data, loading, error, fetchData } = useFetch('Auth API');
  

  const headers = [
    { label: 'Date', key: 'createdAt' },
    { label: 'Full Name', key: 'fullName' },
    { label: 'Matric', key: 'matric' },
    { label: 'Type', key: 'type' },
    { label: 'Status', key: 'status' },
  ];

  const buttons = [
    // Define your action buttons here
  ];

  // ... Other necessary state and logic
  useEffect(() => {
    // Fetch programmes when the component mounts
    fetchData(`results/transcripts/findTranscriptByUser`);
  }, []);

  return (
    <DashboardLayout sidebar="two">
      <Box borderRadius={11} px={2} py={5} width={["120%", '100%']}>
        <Box
          bg="white"
          borderRadius={11}
          mt={2}
          p={5}
          width={['200', '100%']}
          height={['100%', '80vh']}
        >
          <Text fontFamily="Kumbh Sans" fontSize="16px" fontWeight="500" lineHeight="20px" letterSpacing="0em" textAlign="left" color="#374151" mb={4}>
            Request History
          </Text>
          <DataTable
            data={data?.data || []}
            headers={headers}
            buttons={buttons}
            page={1}  // Replace with your actual page state
            onPageChange={(newPage) => console.log('Page changed to:', newPage)}
            pageSize={10}  // Replace with your actual page size state
            totalItems={100}  // Replace with your actual total items state
            loading={false}  // Replace with your actual loading state
            // search={true}  // Adjust as needed
            exports={true}  // Adjust as needed
            // pagination={true}  // Adjust as needed
          />
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default TranscriptHistory;
