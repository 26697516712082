import React, { useState } from 'react';
import { Box, Button, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, FormControl, FormLabel, Select } from '@chakra-ui/react';
import {  useParams } from 'react-router-dom'; // Import useParams
import DashboardLayout from '../components/DashboardLayout';
import Step4 from '../components/Step4';
import useFormSubmission from '../hooks/useFormSubmission';
import { Link } from 'react-router-dom';
import useSchoolInfo from '../hooks/useSchoolInfo';

const ReviewPage = () => {
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { page, id } = useParams(); // Use useParams to get the page and id
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState({});
  //  const { sessions, levels } = useSchoolInfo();

   const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  const handleSubmit = async() => {
    setMessage()
       
    const submissionResult = await submitForm(`applications/status`, formData, "json");
    
    if (submissionResult.submitSuccess) {
      setMessage(submissionResult.responseBody)
        handleCloseModal();
    } else {
      // Handle form submission error
      console.error('Form submission failed:', submitError);
    }
  };



  return (
    <DashboardLayout sidebar="one">
      <Box bg="white" borderRadius={11} px={10} py={5} width={"100%"} height={"100%"}
       style={{fontFamily: 'Kumbh Sans', fontSize: '12px !important', fontWeight: 500
      }}
      >
        <Link to='/dashboard'> Back </Link>
        <Box align="start" spacing={1} p={8}>
          
          <Box >
          <Button
           display={'flex'}
            justifyItems={'flex-end'}
            mt={3}
            bg={"#343CF0"}
            fontSize={13}
            color={"white"}
            onClick={handleOpenModal}
          >
            Set Status
          </Button>
            <Step4 id={id}  setFormDatas={setFormData}/>
            
          </Box>
      
      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader  fontSize={13}>Edit Status</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
            {/* <FormControl mb={4}>
              <FormLabel  fontSize={13}>Session</FormLabel>
              <Select
                placeholder="Select session"
                fontSize={13}
                onChange={(e) => {
                    setFormData((prevFormData) => {
                      console.log("Previous Form Data:", prevFormData);
                      return { ...prevFormData, session: e.target.value };
                    });
                  }}
             >
                {sessions?.map((session) => (
                  <option key={session.id} value={session._id}>
                    {session.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl mb={4}>
              <FormLabel  fontSize={13}>Level</FormLabel>
              <Select
                placeholder="Select level"
                fontSize={13}
                onChange={(e) => {
                    setFormData((prevFormData) => {
                      console.log("Previous Form Data:", prevFormData);
                      return { ...prevFormData, level: e.target.value };
                    });
                  }}
              >
                {levels && levels?.map((level) => (
                  <option key={level.id} value={level._id}>
                    {level.name}
                  </option>
                ))}
              </Select>
            </FormControl> */}

            <FormControl mb={4}>
              <FormLabel  fontSize={13}>Status</FormLabel>
              <Select
               fontSize={13}
                placeholder="Select status"
                onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, status: e.target.value }))}
              >
                <option value="Admitted">Admitted</option>
                <option value="Rejected">Rejected</option>
              </Select>
            </FormControl>
            { submitError && (<Text color={"red.500"}>{submitError}</Text>)}
            <Text color={"green.500"}>{message}</Text>
            <Button bg={"#343CF0"} 
            color={"white"} 
            justifySelf={'center'}
            fontSize={13}
            my={3}
           >
              {isSubmitting ? '...Submitting' : 'Update Status'}
            </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
        </Box>
      </Box>
    </DashboardLayout>
  );
};



export default ReviewPage;
