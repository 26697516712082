import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import MainRoute from './routes/routes';
import { Provider } from 'react-redux';
import store from './auth/store';
import remitacss from './remita.css';

const App = () => {
  return (
    <Provider store={store}>
      <ChakraProvider>
        <MainRoute />
      </ChakraProvider>
    </Provider>
  );
};

export default App;
