import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
  Button,
  Text,
  Select
} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import useFetch from '../hooks/useFetch';
import useFormSubmission from '../hooks/useFormSubmission';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const GenerateTranscript = () => {
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const [message, setMessage] = useState()
  const navigate = useNavigate();
  const { data: programme, loading: loadingP, error: errorP, fetchData } = useFetch();
  const { data: department, loading: loadingD, error: errorD, fetchData:fetchDepartment } = useFetch();
  const { data: sessions, loading: loadingS, error: errorS, fetchData:fetchSession } = useFetch();
  const configs = useSelector((state)=> state.auth.config)
  const [requestInfo, setRequestInfo] = useState({
    fullName: '',
    matric: '',
    programme: '',
    department: '',
    session: '',
    type:'',
    address: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRequestInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async() => {
    // Add your logic to handle the form submission
    console.log('Form Submitted:', requestInfo);
    // You can make an API call or perform other actions here
    const { submitSuccess, responseBody } = await submitForm('results/transcripts/requestTranscript', requestInfo);
    if (submitSuccess){
        setMessage(responseBody.message)
        navigate(`/payment/generated/${responseBody?.data?._id}`);
    }
  };
  useEffect(() => {
    fetchData('programmes/all');
    fetchDepartment('courses/major/all');
    fetchSession('sessions/all');
  }, []);
  

  return (
    <DashboardLayout sidebar="two">
      <Box borderRadius={11} px={2} py={5} width={["120%", '100%']}>
        <Box
          bg="white"
          borderRadius={11}
          mt={2}
          p={10}
          width={['200', '100%']}
          height={['100%', '100%']}
        >
       
          <Stack spacing={4}>
          <FormControl id="fullName">
            <FormLabel  
            fontFamily="Kumbh Sans"
            fontSize="13"
            >Full Name</FormLabel>
            <Input
              type="text"
              name="fullName"
              fontFamily="Kumbh Sans"
              fontSize="13"
              
              onChange={handleChange}
              placeholder="Enter your full name"
            />
          </FormControl>

          <FormControl id="studentID">
            <FormLabel  
            fontFamily="Kumbh Sans"
            fontSize="13">Matric Number</FormLabel>
            <Input
              type="text"
              name="matric"
              fontFamily="Kumbh Sans"
              fontSize="13"
              
              onChange={handleChange}
              placeholder="Enter your Matric No"
            />
          </FormControl>

          <FormControl id="program">
            <FormLabel  fontFamily="Kumbh Sans"
            fontSize="13">Programme</FormLabel>
            <Select
              type="text"
              name="programme"
              value={requestInfo.program}
              fontFamily="Kumbh Sans"
              fontSize="13"
              onChange={handleChange}
              placeholder="Enter your program"
            >
              {programme && programme.map((prog) => (
                <option key={prog?._id} value={prog?._id}>
                  {prog.name}
                </option>
             ))}
            </Select>
          </FormControl>

          <FormControl id="department">
            <FormLabel  fontFamily="Kumbh Sans"
            fontSize="13">Department</FormLabel>
            <Select
              type="text"
              name="department"
              value={requestInfo.department}
              onChange={handleChange}
              fontFamily="Kumbh Sans"
              fontSize="13"
              placeholder="Enter your department"
            >
              {department && department.map((prog) => (
                              <option key={prog?._id} value={prog?._id}>
                                {prog.name}
                              </option>
                          ))}
              </Select>
          </FormControl>

          <FormControl id="graduationYear">
            <FormLabel  
            fontFamily="Kumbh Sans"
            fontSize="13">Graduation Year</FormLabel>
            <Select
              type="text"
              fontFamily="Kumbh Sans"
              fontSize="13"
              name="session"
            
              onChange={handleChange}
              placeholder="Enter your graduation year"
            >
              {sessions && sessions.map((prog) => (
                              <option key={prog?._id} value={prog?._id}>
                                {prog.name}
                              </option>
                          ))}
              </Select>
          </FormControl>
          <FormControl id="graduationYear">
            <FormLabel  
            fontFamily="Kumbh Sans"
            fontSize="13">Type</FormLabel>
            <Select
              type="text"
              fontFamily="Kumbh Sans"
              fontSize="13"
              name="type"
              value={requestInfo.type}
              onChange={handleChange}
              placeholder="Select Type"
            >
                <option value="Email">Send by Email</option>
              <option value="Postal">Send by Postal Mail</option>
              <option value="Courier">Send by Courier</option>
            </Select>
          </FormControl>
          <FormControl id="additionalInfo">
            <FormLabel fontFamily="Kumbh Sans"
              fontSize="13">Address</FormLabel>
            <Textarea
            fontFamily="Kumbh Sans"
            fontSize="13"
              name="address"
              onChange={handleChange}
              placeholder="Any additional information or special instructions"
            />
          </FormControl>
            <Text color="red.500">{submitError}</Text>
            <Text color="green.500">{message}</Text>
          <Button  
             bg={`${configs?.primaryBg}`}
             color={`${configs?.primaryColor}`}
           fontFamily="Kumbh Sans"
            fontSize="12"
             onClick={handleSubmit}>
            { isSubmitting ? '...Submitting' : 'Submit' }
          </Button>
        </Stack>   
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default GenerateTranscript;
