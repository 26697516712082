// Dashboard.js
import React, { useEffect, useState } from 'react';
import { Box, Grid, Image, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, ModalFooter, Button } from '@chakra-ui/react';
import useFormSubmission from '../hooks/useFormSubmission';
import DashboardLayout from '../components/DashboardLayout';
import DataTable from '../components/DataTable';
import useFetch from '../hooks/useFetch';
import { useSelector } from 'react-redux';


const AcademicPerformance = () => {
  const configs = useSelector((state)=> state.auth.config)
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const [selectedFile, setSelectedFile] = useState(null);
  const { data: application, loading, error, fetchData } = useFetch();

  const [dynamicPath, setDynamicPath] = useState('/results');
  const dynamicParams = "";

  const headers = [
    { label: 'Matric No', key: 'matric' },
    { label: 'Session', key: 'session.name' },
    { label: 'Semester', key: 'semester.name' },
    { label: 'Attnd.', key: 'ca1' },
    { label: 'Assmt.', key: 'ca2' },
    { label: 'Mid Term', key: 'ca3' },
    { label: 'Exam', key: 'exam' },
    { label: 'Grade', key: 'grade' },
    { label: 'Approval', key: 'approval' }
  ];
  const buttons = [
      {
      color: '#fde428',
      label: 'Edit',
      onClick: (item) => handleEdit(item),
    },
    // ... additional buttons
  ];
const handleView = () =>{

}
const handleEdit = () =>{

}
useEffect(() => {
  fetchData(dynamicPath, dynamicParams);
}, [dynamicPath]);

const handleUpload = async () => {
  if (selectedFile) {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      // Use the submitForm function from useFormSubmission
      const { submitSuccess, responseBody } = await submitForm('results/batch-upload', formData, 'multipart');

      if (submitSuccess) {
        console.log('File uploaded successfully');
        // Reset the selected file and close the modal
        setSelectedFile(null);
        setUploadModalOpen(false);
      } else {
        console.error('File upload failed:', responseBody); // Handle error accordingly
      }
    } catch (error) {
      console.error('Error uploading file:', error); // Handle error accordingly
    }
  }
};


  return (
    <DashboardLayout sidebar={"two"}>
        <Box borderRadius={11} px={2} py={5} width={["120%", '100%' ]}>
      <Box 
        bg="white" 
        borderRadius={11} 
        mt={2} 
        p={5}  
        width={['200','100%']}
        height={['100%', '100%']}
        // display={'flex'}
      >
        <Grid
            templateColumns="repeat(2, 1fr)"
            gap={5}
            mt={3}
            w="40%"
            // margin="0 auto"
        >
          <Button 
            bg="#10B981" color="white"
            borderRadius={5} 
            border="1px solid #DFE8F6"
            fontSize={11}
           >Generate Grade Report
           </Button>
           <Button
              bg={"transparent"}
              color={`${configs?.primaryBg}`}
              border="1px solid #343CF0"
              borderRadius={5}
              fontSize={11}
              onClick={() => setUploadModalOpen(true)}
            >
              Upload Results <Image src={'/icons/button-document-upload.svg'} ml={2} />
            </Button>
            <Modal isOpen={isUploadModalOpen} onClose={() => setUploadModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Upload Results</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl>
                  <FormLabel fontSize={13}>Select a .csv file</FormLabel>
                  <Input
                    type="file"
                    fontSize={13}
                    accept=".csv"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setSelectedFile(file);
                    }}
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button bg={`${configs?.primaryBg}`}
                 color="white"
               
                 _hover={{ bg: '#1E40AF' }}
                 fontSize={12} mr={3}

               onClick={handleUpload}>
              {isSubmitting ? 'is Submitting' : 'Submit'}
                </Button>
                <Button onClick={() => setUploadModalOpen(false)}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

        </Grid>

        <Box mt={5}>
          <DataTable
                data={application?.data || []}
                headers={headers}
                buttons={buttons}
                page={application?.currentPage}
                onPageChange={(newPage) =>  setDynamicPath(`/results/${newPage}`)}
                pageSize={application?.totalPages}
                totalItems={application?.totalItems}
                loading={loading}
                search={true}
                exports={true}
                pagination={true}
              />
            </Box>
        </Box>
    </Box>  
    </DashboardLayout>
  );
};

export default AcademicPerformance;
