import React from 'react';
import { Box } from '@chakra-ui/react';

const Spacer = ({ gap }) => {
  return (
    <Box
      display="inline-flex"
      flexDirection="column"
      alignItems="center"
      gap={gap}
      my={10}
    >
     
    </Box>
  );
};

export default Spacer;
