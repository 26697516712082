// ApplyButton.js
import React from 'react';
import { Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const ApplyButton = ({ buttonText }) => {
  const configs = useSelector((state)=> state.auth.config)
  return (<Button
    display="flex"
    padding="15px 20px"
    justifyContent="center"
    alignItems="center"
    gap="10px"
    color={`${configs?.primaryBg}`}
    border={`1.05px solid ${configs?.primaryBg}`}
    background={`${configs?.primaryColor}`}
    boxShadow="0px 4.215384483337402px 16.86153793334961px 0px #4C93FF33"
    animation-timing-function="ease-out"
    animation-duration="300ms"
    width="100%"
    textAlign="center"
    fontSize="14px"
    fontWeight="500"
    lineHeight="normal"
  >
    {buttonText}
  </Button>
)};

export default ApplyButton;
