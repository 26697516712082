// Modal.js
import React, { useEffect } from 'react';
import {  Modal as ChakraModal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Select, FormControl, FormLabel, Input } from '@chakra-ui/react';

const ClearanceModal = ({ isOpen, onClose, handleChange,color, bg, handleSubmit, isSubmitting }) => {
   
    
    const handleSubmitInternal = () => {
      
      handleSubmit(); // Assuming this function performs the submission logic
    };
    return (
    <ChakraModal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>View Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <FormControl>
          <FormLabel fontSize={13} fontFamily="Kumbh Sans" >Status</FormLabel>
        <Select fontSize={13} fontFamily="Kumbh Sans" name="status" onChange={handleChange}>
          <option></option>
          <option> Cleared</option>
          <option> Not Cleared</option>
        </Select> 
        </FormControl>   
        <FormControl>
        <FormLabel fontSize={13} fontFamily="Kumbh Sans" >Comment</FormLabel>
          <Input fontSize={13} fontFamily="Kumbh Sans" name="comment" onChange={handleChange} />
          </FormControl>         
          <Button 
          fontSize={13}
          bg={bg}
          color={color}
          onClick={handleSubmitInternal}>{isSubmitting ? 'Submitting....' : 'Submit'}</Button>
        </ModalBody>
        <ModalFooter>
          <Button 
           fontSize={13}
           bg={bg}
           color={color} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default ClearanceModal;
