import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Button
} from "@chakra-ui/react";
import DashboardLayout from '../components/DashboardLayout';
import { useSelector } from 'react-redux';
import useFetch from '../hooks/useFetch';
import ApplicationLink from '../components/ApplicationLink';
import useFormSubmission from '../hooks/useFormSubmission';

const AdmissionClearanceForm = () => {
  const { isSubmitting, submitError, submitForm } = useFormSubmission('Auth API');
  const { data, loading, error, fetchData } = useFetch();
  const { data:clearance, loading: clearanceLoad, error: err, fetchData:fetchClearance } = useFetch();
  const config = useSelector((state)=> state?.auth?.config);  
  const [message, setMessage] = useState()
  
  const [statuses, setStatuses] = useState({});
  const [formData, setFormData] = useState({})
  useEffect(() => {
    fetchData("applications/admittedByUser");
    fetchClearance("clearance/stages/admission");
  
  }, [data?.data]);
  
  const handleSubmit = async (clearanceId) => {
    // Create a new FormData object
    const formDataObject = new FormData();
    // Append each form field to the FormData object
   
    const { submitSuccess, responseBody } = await submitForm(`clearance/admission/${clearanceId}`, formDataObject, 'multipart');
    if (submitSuccess) {
      const newStatuses = { ...statuses, [clearanceId]: responseBody.data };
      setStatuses(newStatuses);
      setMessage({ ...message, [clearanceId]: responseBody.message });
      // Close the modal after submission
    }
  };

  return (
    <DashboardLayout sidebar="two">
    <Box borderRadius={11} px={2} py={5} width={["120%", '100%']}>
      <Box
        bg="white"
        borderRadius={11}
        mt={2}
        p={10}
        width={['200', '100%']}
        height={['100%', '100%']}
      >
       <Grid
            templateColumns="repeat(4, 1fr)"
            gap={2}
            mt={3}>
      {clearanceLoad ? <p></p>: clearance?.data?.map((item)=>(
        <Box display={'flex'} flexDir={'column'} justifyContent={'center'} key={item.id}> 
          <ApplicationLink
          // link={'/faculty'}
          icon={'menu-teacher.svg'}
          title={item.name}
          mb={5}
        />
        <Button fontSize={12} my={4} onClick={() => handleSubmit(item?._id)}
          bg={(statuses[item._id]?.status == "In Progress" &&
           statuses[item._id]?.clearanceStatus == "Not Cleared") ? 'yellow': statuses[item._id]?.clearanceStatus == "Cleared" ?
          "green" :  statuses[item._id]?.clearanceStatus == "Not Cleared" ? "red": "darkblue" }
          color={(statuses[item._id]?.status == "In Progress" &&
          statuses[item._id]?.clearanceStatus == "Not Cleared") ? 'black': statuses[item._id]?.clearanceStatus == "Cleared" ?
         "white" :  statuses[item._id]?.clearanceStatus == "Not Cleared" ? "white": "white" }
        > Check Status</Button>
        </Box>
      )) }
      </Grid>
    </Box>
    </Box>
    </DashboardLayout>
  );
};

export default AdmissionClearanceForm;
