import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
  Button,
  Text,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import useFetch from '../hooks/useFetch';
import useFormSubmission from '../hooks/useFormSubmission';
import { useNavigate } from 'react-router-dom';
import DataTable from '../components/DataTable';

const AppealResult = () => {
  const { isSubmitting, submitError, submitForm } = useFormSubmission('Auth API');
  const [message, setMessage] = useState();
  const navigate = useNavigate();
  const { data: session, loading: loadingS, error: errorS, fetchData: fetchSession } = useFetch();
  const { data: semester, loading: loadingSem, error: errorSem, fetchData: fetchSemester } = useFetch();
  const { data: appeal, loading: loadingAppeal, error: errorAppeal, fetchData: fetchAppeal } = useFetch();
  const [dynamicPath, setDynamicPath] = useState('appeal-results/byStudent');
  const dynamicParams = "";
  const [requestInfo, setRequestInfo] = useState({
    course: '',
    session: '',
    semester: '',
    reason: '',

  });
  const [result, setResult] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const headers = [
    { label: 'Course', key: 'course.courseId.name' },
    { label: 'Session', key: 'session.name' },
    { label: 'Semester', key: 'semester.name' },
    { label: 'Status', key: 'status' },
    { label: 'Date', key: 'createdAt' },
  ];
  const buttons = [];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRequestInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    setResult([]);
    console.log('Form Submitted:', requestInfo);
    const { submitSuccess, responseBody } = await submitForm('appeal-results', requestInfo);
    if (submitSuccess) {
      console.log(responseBody);
      
      setMessage(responseBody.message);
      // Close the modal after submission
      setIsModalOpen(false);
    } else {
      setMessage('Submission failed');
    }
  };

  useEffect(() => {
    fetchSession('sessions');
    fetchSemester('semesters');
    fetchAppeal(dynamicPath)
  }, [dynamicPath]);

  return (
    <DashboardLayout sidebar="two">
      <Box borderRadius={11} px={2} py={5} width={['120%', '100%']}>
        <Box bg="white" borderRadius={11} mt={2} p={10} width={['200', '100%']} height={['100%', '100%']}>
            <Box display="flex" justifyContent={'flex-end'}>
            <Button color="white"
                background="darkblue"
                fontFamily="Kumbh Sans"

                fontSize="12"
                 onClick={() => setIsModalOpen(true)}>
              Create Appeal
            </Button>
            </Box>
          <Box>
           
          <DataTable
            data={appeal?.data ? appeal?.data : []}
            headers={headers}
            buttons={buttons}
            page={1} // Replace with your actual page state
            onPageChange={(newPage) =>  setDynamicPath(`/appeal-results/byStudent/${newPage}`)}
            pageSize={appeal?.totalPages} // Replace with your actual page size state
            totalItems={appeal?.totalItems} // Replace with your actual total items state
            loading={isSubmitting} // Replace with your actual loading state
            // search={true}  // Adjust as needed
            // exports={true}  // Adjust as needed
            // pagination={true}  // Adjust as needed
          />
          </Box> 
        </Box>
      </Box>

      {/* Modal for the Appeal Form */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'12'}>Appeal Form</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            
          <FormControl id="session">
            <FormLabel  fontFamily="Kumbh Sans"
            fontSize="13">Session</FormLabel>
            <Select
              type="text"
              name="session"
              fontFamily="Kumbh Sans"
              fontSize="13"
              onChange={handleChange}
              placeholder="Enter session"
            >
              {session && session.map((prog) => (
                <option key={prog?._id} value={prog?._id}>
                  {prog.name}
                </option>
             ))}
            </Select>
          </FormControl>

          
          <FormControl id="semester">
            <FormLabel  fontFamily="Kumbh Sans"
            fontSize="13">Semester</FormLabel>
            <Select
              type="text"
              name="semester"
              fontFamily="Kumbh Sans"
              fontSize="13"
              onChange={handleChange}
              placeholder="Enter semester"
            >
              {semester && semester.map((prog) => (
                <option key={prog?._id} value={prog?._id}>
                  {prog.name}
                </option>
             ))}
            </Select>
          </FormControl>
          <FormControl id="reason">
            <FormLabel  fontFamily="Kumbh Sans"
            fontSize="13">Reason</FormLabel>
            <Textarea
              type="text"
              name="reason"
              fontFamily="Kumbh Sans"
              fontSize="13"
              onChange={handleChange}
            ></Textarea>
            </FormControl>



          </ModalBody>
          <ModalFooter>
          <Text color="red.500">{submitError}</Text>
            <Text color="green.500">{message}</Text>
            
              <Button
                color="white"
                background="darkblue"
                fontFamily="Kumbh Sans"
                width={'50%'}
                fontSize="12"
                onClick={handleSubmit}
              >
                {isSubmitting ? '...Submitting' : 'Submit'}
              </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DashboardLayout>
  );
};

export default AppealResult;
