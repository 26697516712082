import React, { useState, useEffect } from 'react';
import { Input, Button, FormControl, FormLabel, Box, Text, Select, Grid} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import useFormSubmission from '../hooks/useFormSubmission';
import useFetch from '../hooks/useFetch';
import { useSelector } from 'react-redux';

const AcademicCalendarForm = () => {
  const configs = useSelector((state)=>state.auth.config)
  const [formData, setFormData] = useState({});
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const [message, setMessage] = useState();
  const { data: sessions, loading: sessionLoading, error: sessionError, fetchData: fetchSession } = useFetch("Auth API");
  const { data: semesters, loading: loadingS, error: errorS, fetchData:fetchSemesters} = useFetch();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  
  const handleSubmit = async(e) => {
    e.preventDefault();
    const { submitSuccess, responseBody } = await submitForm('configs/update', formData, 'json');
    
    
    if (submitSuccess) {
      setMessage(responseBody?.data?.message)
    } else {
      console.error(submitError || 'Form submission failed');
    }
  };
  useEffect(() => {
    fetchSession('sessions'); // Adjust the API endpoint accordingly
    fetchSemesters('semesters'); // Adjust the API endpoint accordingly
  }, []);

  return (
    <DashboardLayout sidebar={"two"}>
    <Box 
     borderRadius={11} px={2} py={5} 
     width={["120%", '100%' ]}>
     <Box 
       bg="white" 
       borderRadius={11} 
       mt={2} 
       p={20}  
       width={['200','100%']}
       height={'100%'}
   
     >
      <Text  fontFamily="Kumbh Sans"
            fontSize="16px"
            fontWeight={700}
            mb={4}
            >Academic Calendar</Text>
    <form onSubmit={handleSubmit}>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
      <FormControl>
        <FormLabel  fontFamily="Kumbh Sans"
            fontSize="13px" >Current Semester:</FormLabel>
        <Select type="text" name="currentSemester"  fontFamily="Kumbh Sans"
            fontSize="13px"  onChange={handleInputChange}>
              <option value="">Select Semester</option>
            {semesters && semesters.map((semester) => (
              <option key={semester?._id} value={semester?._id}>
                {semester.name}
              </option>
            ))}
          </Select>
      </FormControl>

      <FormControl>
        <FormLabel  fontFamily="Kumbh Sans"
            fontSize="13px" >Current Semester Start Date:</FormLabel>
        <Input type="date"  fontFamily="Kumbh Sans"
            fontSize="13px"  name="currentSemesterStartDate"  onChange={handleInputChange} />
      </FormControl>

      <FormControl mt={2}>
        <FormLabel  fontFamily="Kumbh Sans"
            fontSize="13px" >Current Semester End Date:</FormLabel>
        <Input type="date"  fontFamily="Kumbh Sans"
            fontSize="13px"  name="currentSemesterEndDate"  onChange={handleInputChange} />
      </FormControl>
      <FormControl mt={2}>
      <FormLabel  fontFamily="Kumbh Sans"
            fontSize="13px" >Current Session</FormLabel>
      <Select type="text"  fontFamily="Kumbh Sans"
            fontSize="13px"  name="currentSession"  onChange={handleInputChange}>
              <option value="">Select Session</option>
            {sessions && sessions.map((session) => (
              <option key={session?._id} value={session?._id}>
                {session.name}
              </option>
            ))}
          </Select>
      </FormControl>


      <FormControl mt={2}>
        <FormLabel  fontFamily="Kumbh Sans"
            fontSize="13px" >Current Session Start Date:</FormLabel>
        <Input type="date"  fontFamily="Kumbh Sans"
            fontSize="13px"  name="currentSessionStartDate"  onChange={handleInputChange} />
      </FormControl>

      <FormControl mt={2}>
        <FormLabel  fontFamily="Kumbh Sans"
            fontSize="13px" >Current Session End Date:</FormLabel>
        <Input type="date"  fontFamily="Kumbh Sans"
            fontSize="13px"  name="currentSessionEndDate"  onChange={handleInputChange} />
      </FormControl>

      <FormControl mt={2}>
        <FormLabel  fontFamily="Kumbh Sans"
            fontSize="13px" >Registration Start Date:</FormLabel>
        <Input type="date"  fontFamily="Kumbh Sans"
            fontSize="13px"  name="registrationStartDate" onChange={handleInputChange} />
      </FormControl>
      </Grid>
      <Text color="red.500">{submitError}</Text>
            <Text color="green.500">{message}</Text>
      
      
      <Button type="submit" mt={2}  fontFamily="Kumbh Sans"
            fontSize="13px" 
            bg={`${configs?.primaryBg}`}
            color={`${configs?.primaryColor}`}
            float="right">
          {isSubmitting ? '...Submitting' : 'Submit'}
      </Button>
    </form>
    </Box>
    </Box>
    </DashboardLayout>
  );
};

export default AcademicCalendarForm;
