import React from 'react';
import { Box, SimpleGrid, Text, Button, Image } from '@chakra-ui/react';
import ApplyButton from './ApplyButton';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const ResponsiveGrid = () => {
  const configs = useSelector((state) => state.auth.config);

  return (
    <Box p={{ base: 0, md: 4}}>
      <SimpleGrid  columns={{ base: 1, md: 4 }} spacing={4}
        >
        {configs?.programmeCards && configs?.programmeCards.map((card, index) => (
          <Box key={card.id} bg={configs.primaryColor}>
            {index % 2 !== 1 && (
              <Box fontSize="xl" fontWeight="bold"
                  mb={4} 
                  bg="#EBEEF3"
                  // px="3"
                  py="6"
                  borderRadius="6.323px"
                  >
                <Text 
                    textAlign="center"
                    fontSize="21.077px"
                    fontWeight="600"
                    color="rgba(38, 38, 38, 1), rgba(184, 194, 206, 1)"
                    mb={4} 
                    textTransform="uppercase"
              >{card.title}</Text>
                <Text 
                textAlign="center"
                fontSize="10.538px"
                fontWeight="600"
                lineHeight="normal"
                mb={4}
                px="4"  
                >{card.description}</Text>
              </Box>
            )}
            <Image src={configs?.api+card.uploadsrc} alt="Card Image" mb={2} 
            // height={200}
            // width={"100%"}
            />
            {index % 2 === 1 && (
              <Box fontSize="xl" fontWeight="bold" 
              mb={4} 
              bg="#EBEEF3"
              px="2"
              py="6"
              borderRadius="6.323px"
              >
            <Text 
                textAlign="center"
                fontSize="21.077px"
                fontWeight="600"
                color="rgba(38, 38, 38, 1), rgba(184, 194, 206, 1)"
                textTransform="uppercase"
                mb={4} 
                >
                  {card.title}</Text>
                <Text
                   textAlign="center"
                   fontSize="10.538px"
                   fontWeight="600"
                   lineHeight="normal"
                   mb={4}
                   px="4" 
                >{card.description}</Text>
              </Box>
            )}
            <Link to={`/programmes/${card.title}`}>
            <ApplyButton buttonText={card.buttonText}/>
            </Link>
          
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default ResponsiveGrid;
