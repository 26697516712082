// LandingPage.js
import React, { useEffect } from 'react';
import { Box, Text, Button, Flex, useMediaQuery } from '@chakra-ui/react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Cards from '../components/Cards';
import CardsApply from '../components/CardApply';
import NewsSection from '../components/NewsSection';
import Spacer from '../components/Spacer';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useFetch from '../hooks/useFetch';

const LandingPage = () => {
const { data, loading, error, fetchData } = useFetch();
const configs = useSelector((state) => state.auth.config);

const [isMobile] = useMediaQuery("(max-width: 767px)");

  useEffect(()=>{
    fetchData('news/latest')
  },[])
      
  return (
    <Box>
      <Header config={configs?.api+configs?.logoDark ? configs?.api+configs?.logoDark: '/images/loading.png' } bannerSrc={configs?.api+configs?.homePageBg ? configs?.api+configs?.homePageBg: "/images/banner.png"}  buttonText='Login'>
      <Box
        direction={"column"} // Stack on small screens, align horizontally on medium screens and above
        justify="center"
        align="center"
        textAlign="center"
        >
        <Text
          mb="2"
          style={{
            fontFamily: "Kumbh Sans",
            fontSize: isMobile ? "19px" : "54px",
            fontWeight: 600,
            lineHeight: isMobile ? "20px" : "79px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "white",
          }}
        >
          { configs?.homePageHeader ? configs?.homePageHeader : 'Ignite Your Future' }
        </Text>
        <Text
        
          px={{ base: 5, md: 300 }} // Take full width on small screens, half on medium screens and above
          mb={{ base: "8", md: "0" }} // Add margin bottom only on small screens
          color="white"
          fontFamily="Kumbh Sans"
          fontSize={["12px", "18px"]}
          fontWeight={500}
          lineHeight={["12px", "24.8px"]}
          letterSpacing="0em"
        >
          { configs?.homePageCaption ? configs?.homePageCaption : 'We believe in the transformative power of education. Our commitment is to provide an unparalleled learning experience that empowers individuals to excel in a rapidly evolving world' }
        </Text>
        <Link to='/register'>
        <Button background={`${configs?.primaryBg}`} textAlign="center" mt={5} color={`${configs?.primaryColor}`}>
            Register
        </Button>
        </Link>
    </Box>


    </Header>
      <Spacer gap="500"/>
     <Box px={20}  >
     {/* <Box 
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="10px"
        >
        <img src="/images/latest.svg" />
        <Text 
        color={`${configs?.primaryBg}`}
        fontSize="36px"
        fontWeight="600"
        lineHeight="normal"
        mb={10}
        >Why School Portal</Text>
      </Box>
      <CardsSection /> */}
      <Spacer gap="500"/> 
      <Cards />
      <Spacer gap="500"/>
      <Box 
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="10px"
        >
      
        <Text 
        // color={`${configs?.primaryBg}`}
        fontSize="36px"
        fontWeight="600"
        lineHeight="normal"
        mb={5}
        >Management Team</Text>
      </Box>
      <CardsApply cards={configs?.applyCards}/>
      <Spacer gap="500"/>
      <Box 
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="10px"
        >
        <img src="/images/latest.svg" />
        <Text 
        // color={`${configs?.primaryBg}`}
        fontSize="36px"
        fontWeight="600"
        lineHeight="normal"
        mb={5}
        >Latest News</Text>
      </Box>
      <NewsSection newsData={data} loading={loading} home={true}/>
      </Box>
      <Footer config={configs?.logoDark} />
    </Box>
       );
};

export default LandingPage;