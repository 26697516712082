import React, { useState, useEffect} from 'react';
import {
  Box,
  Select,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Textarea,
  Text,
  Input,
  
} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import useFormSubmission from '../hooks/useFormSubmission';
import DataTable from '../components/DataTable';
import useFetch from '../hooks/useFetch';
import { useSelector } from 'react-redux';
const SendMessagePage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: broadcast, loading, error, fetchData } = useFetch();
  const [dynamicPath, setDynamicPath] = useState('/broadcast');
  const configs = useSelector((state)=> state.auth.config)
  
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const [message, setMessage] = useState([]);

  const [formData, setFormData] = useState({
    role: '',
    content: '',
    subject: '',
  });

  const handleSendMessage = async() => {
    
    const { submitSuccess, responseBody } = await submitForm('broadcast', formData);
    if (submitSuccess){
        setMessage(responseBody.message)
    }
    onClose()
    // setIsAddCourseModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const headers = [
    { label: 'Date', key: 'createdAt' },
    { label: 'Receipient', key: 'to' },
    { label: 'Subject', key: 'subject' },
    
  ];
  useEffect(() => {
    fetchData(dynamicPath);
  }, [dynamicPath]);

  const buttons = [];



  return (
    <DashboardLayout sidebar="two">
    <Box borderRadius={11} px={2} py={5} width={['120%', '100%']}>
      <Box
        bg="white"
        borderRadius={11}
        mt={2}
        p={10}
        width={['200', '100%']}
        height={['100%', '100%']}
      >
     <Box display={'flex'} justifyContent={'flex-end'}>

        <Button  fontFamily="Kumbh Sans"
              fontSize="12px" 
              bg={`${configs?.primaryBg}`}
              color={`${configs?.primaryColor}`}
              float="right" 
              onClick={onOpen}>
          Send Message
        </Button>
        </Box>
      <Text   fontFamily="Kumbh Sans"
            fontSize="12" 
            mb={4}>Send Message</Text>

    

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={12}>Send Message</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <FormControl mb={4}>
        <FormLabel   fontFamily="Kumbh Sans"
            fontSize="12" >Select Recipient Type</FormLabel>
        <Select    fontFamily="Kumbh Sans"
            fontSize="12"  name={'role'} onChange={handleInputChange}>
          <option value="all">All Users</option>
          <option value="Student">Students</option>
          <option value="Applicant">Applicants</option>
          <option value="Staff">Staff</option>
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel fontFamily={"Kumbh Sans"} fontSize={12}>Subject</FormLabel>
        <Input name="subject" fontSize={12} onChange={handleInputChange} />
      </FormControl>
      <Textarea
          name="content"
          fontFamily="Kumbh Sans"
            fontSize="12" 
          onChange={handleInputChange}
          placeholder="Enter broadcast content here..."
        />
         <Text color="red.500">{submitError}</Text>
            <Text color="green.500">{message}</Text>
          </ModalBody>
          
          <ModalFooter>
         
            <Button bg={`${configs?.primaryBg}`}
          color={`${configs?.primaryColor}`}
          fontSize={12}
          mr={3} onClick={handleSendMessage}
       >
              {isSubmitting ? 'Submitting': 'Send'}
            </Button>
            <Button
            fontSize={12}
            onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box mt={4}>
      <DataTable
                data={broadcast?.data || []}
                headers={headers}
                buttons={buttons}
                page={broadcast?.currentPage}
                onPageChange={(newPage) =>  setDynamicPath(`/students/enrolled/${newPage}`)}
                pageSize={broadcast?.totalPages}
                totalItems={broadcast?.totalItems}
                loading={loading}
                search={true}
                exports={true}
                pagination={true}
              />
      </Box>
    </Box>
    </Box>
    </DashboardLayout>
  );
};

export default SendMessagePage;
