// Dashboard.js
import React from 'react';
import { SimpleGrid, Box} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import ApplicationLink from '../components/ApplicationLink';

const TranscriptManagement = () => {
return (
  <DashboardLayout sidebar={"two"}>
     <Box borderRadius={11} px={2} py={5} width={["120%", '100%' ]}>
    <Box 
      bg="white" 
      borderRadius={11} 
      mt={2} 
      p={5}  
      width={['200','100%']}
      height={['100%', '100%']}
      display={'flex'}
    >
      <SimpleGrid
        height={['100%', '100%']}
        mt={5}
        flexDirection={['row','column']}
        columns={[1, 4]} // Set the number of columns based on your design
        spacing={4}
        gap={5}
        px={{ base: 10, md: 50 }}
        justifyContent={'center'}
      >
         
          <ApplicationLink
            link={'/transcript/admin'}
            icon={'menu-card-coin.svg'}
            title='Transcript History'
            ></ApplicationLink>

            <ApplicationLink
              link={'/transcript-settings'}
              icon={'menu-shield-slash.svg'}
              title='Transcript Settings'
              ></ApplicationLink>
           
      
        </SimpleGrid>
      </Box>
  </Box>  
  </DashboardLayout>

  );
};

export default TranscriptManagement;
