import React, {useState} from 'react';
import {
  Box,
  Flex,
  Image,
  Input,
  Button,
  VStack,
  Text,
  useMediaQuery,
  FormLabel,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import useFormSubmission from '../hooks/useFormSubmission'; // Import the useFormSubmission hook
import { useSelector } from 'react-redux';
const RegisterPage = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const { config } = useSelector((state) => state.auth);

  const [success, setSuccess] = useState()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });

  // Initialize the useFormSubmission hook with the desired API name
  const { isSubmitting, submitError, submitForm } = useFormSubmission('Auth API');


  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  // Handle form submission
  const handleRegister = async () => {
    try {
      setSuccess("");
      // Call the submitForm function from the hook
      const result = await submitForm('auth/register', formData, 'json');

      if (result.submitSuccess) {
        // Handle successful registration, e.g., redirect or show a success message
        setSuccess(result.responseBody.message);
        console.log('Registration successful:', result.responseBody);
      } else {
        // Handle registration failure, show an error message
        console.error('Registration failed:', result.responseBody);
      }
    } catch (error) {
      console.error('Error during registration:', error);
    }
  };
  return (
    <Flex h="100vh" align="center" justify="center">
      {isLargerThan768 && (
         <Box w="50%">
         {/* Right side image */}
         <Image src="/images/register.png" alt="LoginImage" 
         position={'absolute'}
         top={0}
         objectFit="cover" w="50%" h="100%"
         
         />
         <Box position={'absolute'} background={config?.primaryBg+85} 
         top={0}
         width={'50%'} height={'100%'}></Box>
       </Box>
      )}

      <Box w={{ base: '100%', md: '50%' }} 
      // p={4}
      // display="flex"
      // padding="4"
      flexDirection="column"
      // alignItems="center"
     
      >
        {/* Left side Register box */}
        <VStack 
        // spacing={4} 
        align="stretch" 
        p={10}
        // px={["20px 27px", "170px 500px"]}
        flexDirection="column"
        // alignItems="center"
        // gap="15px"
        
        >
          <Box 
          display={'flex'}
          justifyContent={'center'}
          alignItems="center">
          <img src={config?.api+config?.logoDark} width={"100px"} />
          </Box>
          <Text textAlign="center" color="#87898E" mb={5} fontWeight={600}>
           Register page
          </Text>
          <FormLabel color="#87898E" my={1} 
          fontSize="14px"
          fontStyle="normal"
          fontWeight="500"
          lineHeight="17.997px"
          >First Name</FormLabel>
          <Input type="text" 
          name="firstName"
          onChange={handleInputChange} 
          // placeholder="Username" 
          />

          <FormLabel color="#87898E" my={1}
           fontSize="14px"
           fontStyle="normal"
           fontWeight="500"
           lineHeight="17.997px"
          >Last Name</FormLabel>
          <Input type="text" 
          name="lastName"
          onChange={handleInputChange} 
          // placeholder="Username" 
          />

          <FormLabel color="#87898E" my={1}
           fontSize="14px"
           fontStyle="normal"
           fontWeight="500"
           lineHeight="17.997px"
           >Email</FormLabel>
          <Input type="text" 
          name={"email"}
          onChange={handleInputChange} 
          // placeholder="Username" 
          />

          <FormLabel color="#87898E" my={1}
          
          fontSize="14px"
          fontStyle="normal"
          fontWeight="500"
          lineHeight="17.997px">Password</FormLabel>
          <Input type="password" 
          name="password"
          onChange={handleInputChange} 
          // placeholder="Password" 
          />
        {success && <Text color="green.500" fontSize={12}>{success}</Text>}
        {submitError && <Text color="red.500" fontSize={12}>{submitError}</Text>}
           <Button
            borderRadius="7.261px"
            background={`${config?.primaryBg}`}
            color={`${config?.primaryColor}`}
            mt={6}
            size="lg"
            onClick={handleRegister} // Call handleRegister when the button is clicked
            disabled={isSubmitting} // Disable the button during submission
          >
            {isSubmitting ? 'Registering...' : 'Register'}
          </Button>
          <Link to='/login'>
          <Text fontSize={12}>Already register? Sign in</Text>
          </Link>
        </VStack>
      </Box>
    </Flex>
  );
};

export default RegisterPage;
