import React, { useState, useEffect } from 'react';
import { Input, Button, FormControl, FormLabel, Box, Text, Grid } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import useFormSubmission from '../hooks/useFormSubmission';
import { useSelector } from 'react-redux';
import useFetch from '../hooks/useFetch';
const SiteInformationForm = () => {
  const { data: congiData, loading, error, fetchData } = useFetch();
  const [formData, setFormData] = useState({});
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const [message, setMessage] = useState();
  const configs = useSelector((state)=> state.auth.config)

  useEffect(() => {
    fetchData('configs');
  }, []);

  const handleInputChange = (e) => {
    
    const { name, value, files } = e.target;

    // If the input is a file input, use the files property
    const fileValue = files ? Array.from(files) : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: fileValue,
    }));
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Create a new FormData object
    const formDataObject = new FormData();
  
    // Append each form field to the FormData object
    Object.entries(formData).forEach(([key, value]) => {
      console.log(key, value)
      if (Array.isArray(value)) {
        value.forEach((file) => {
          formDataObject.append(key, file);
        });
      } else {
        formDataObject.append(key, value);
      }
    });
  
    // Call the submitForm function with the FormData object
    const { submitSuccess, responseBody } = await submitForm('configs/update', formDataObject, 'multipart');
  
    if (submitSuccess) {
      setMessage(responseBody?.message);
    } else {
      console.error(submitError || 'Form submission failed');
    }
  };
  

 
  console.log(congiData)

  
  return (
    <DashboardLayout sidebar={"two"}>
    <Box 
     borderRadius={11} px={2} py={5} 
     width={["120%", '100%' ]}>
     <Box 
       bg="white" 
       borderRadius={11} 
       mt={2} 
       p={20}  
       width={['200','100%']}
       height={'100%'}
   
     >
      <Text  fontFamily="Kumbh Sans"
            fontSize="16px"
            fontWeight={700}
            mb={4}
            >Site Information</Text>
    <form onSubmit={handleSubmit}>
    <Grid templateColumns={['1fr', '2fr 2fr']} gap={4}>
      <FormControl>
        <FormLabel  
        fontFamily="Kumbh Sans"
            fontSize="13px">School Name:</FormLabel>
        <Input fontFamily="Kumbh Sans"
            fontSize="13px" type="text" name="schoolName"  onChange={handleInputChange}
            value={formData?.schoolName ? 
              formData?.schoolName : congiData?.schoolName}
            />
      </FormControl>

      <FormControl>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px">Phone Number:</FormLabel>
        <Input fontFamily="Kumbh Sans"
            fontSize="13px" type="number" name="phoneNumber"  onChange={handleInputChange} 
            value={formData?.phoneNumber ? 
              formData?.phoneNumber : congiData?.phoneNumber}
            />
      </FormControl>

      <FormControl mt={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px">Email:</FormLabel>
        <Input fontFamily="Kumbh Sans"
            fontSize="13px" type="email" name="email"  onChange={handleInputChange} 
            value={formData?.email ? 
              formData?.email : congiData?.email}
            />
      </FormControl>

      <FormControl mt={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px">Contact Address:</FormLabel>
        <Input fontFamily="Kumbh Sans"
            fontSize="13px" type="text" name="contactAddress"  onChange={handleInputChange}
            value={formData?.contactAddress ? 
              formData?.contactAddress : congiData?.contactAddress}
             />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px">Homepage Banner Heading</FormLabel>
        <Input fontFamily="Kumbh Sans"
            fontSize="13px" type="text" name="homePageHeader"  onChange={handleInputChange}
            value={formData?.homePageHeader ? 
              formData?.homePageHeader : congiData?.homePageHeader}
            />
      </FormControl>
      
    
      <FormControl mt={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px">Homepage Banner Description</FormLabel>
        <Input fontFamily="Kumbh Sans"
            fontSize="13px" type="text" name="homePageCaption"  onChange={handleInputChange}
            value={formData?.homePageCaption ? 
              formData?.homePageCaption : congiData?.homePageCaption}
            />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px">Primary Background:</FormLabel>
        <Input fontFamily="Kumbh Sans"
            fontSize="13px" type="text" name="primaryBg"  onChange={handleInputChange} 
            value={formData?.primaryBg ? formData?.primaryBg : congiData?.primaryBg}
            />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px">Primary Color:</FormLabel>
        <Input fontFamily="Kumbh Sans"
            fontSize="13px" type="text" name="primaryColor"  onChange={handleInputChange}
            value={formData?.primaryColor ? formData?.primaryColor : congiData?.primaryColor}
            />
      </FormControl>

      <FormControl mt={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px">Home Page Background:</FormLabel>
        <Input fontFamily="Kumbh Sans"
            fontSize="13px" type="file" name="homePageBg"  onChange={handleInputChange}
            
            />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px">Logo Dark:</FormLabel>
        <Input fontFamily="Kumbh Sans"
            fontSize="13px" type="file" name="logoDark"  onChange={handleInputChange} 
            
            />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px">Logo White:</FormLabel>
        <Input fontFamily="Kumbh Sans"
            fontSize="13px" type="file" name="logoWhite"  onChange={handleInputChange} />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px">Contact Page Background:</FormLabel>
        <Input fontFamily="Kumbh Sans"
            fontSize="13px" type="file" name="contactPageBg" onChange={handleInputChange} 
            
            />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px">News Page Header:</FormLabel>
        <Input fontFamily="Kumbh Sans"
            fontSize="13px" type="file" name="othersBg" onChange={handleInputChange} />
      </FormControl>


      <FormControl mt={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px">Others Header:</FormLabel>
        <Input fontFamily="Kumbh Sans"
            fontSize="13px" type="file" name="othersBg" onChange={handleInputChange} />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel fontFamily="Kumbh Sans"
            fontSize="13px">Footer Background:</FormLabel>
        <Input fontFamily="Kumbh Sans"
            fontSize="13px" type="text" name="footerBg"  onChange={handleInputChange}
            value={formData?.footerBg ? formData?.footerBg : congiData?.footerBg}
            />
      </FormControl>
    </Grid>
    <Text color="red.500">{submitError}</Text>
    <Text color="green.500">{message}</Text>
      <Button  
      fontFamily="Kumbh Sans"
            fontSize="13px" 
            bg={`${configs?.primaryBg}`}
            color={`${configs?.primaryColor}`}
            float="right"
            type="submit" mt={4} >
        { isSubmitting ? '...Submitting' :'Submit'}
      </Button>
    </form>
    </Box>
    </Box>
    </DashboardLayout>
  );
};

export default SiteInformationForm;
