import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import DataTable from '../components/DataTable';  // Replace with the actual path
import useFormSubmission from '../hooks/useFormSubmission';
import useFetch from '../hooks/useFetch';
import { useSelector } from 'react-redux';

const CreateCourse = () => {
  const [isAddCourseModalOpen, setIsAddCourseModalOpen] = useState(false);
  const configs = useSelector((state)=> state.auth.config)
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const { data: courses, loading, error, fetchData } = useFetch();
  const [message, setMessage] = useState();
  const [isEditCourseModalOpen, setIsEditCourseModalOpen] = useState(false); 
  const [selectedCourse, setSelectedCourse] = useState();
  const [dynamicPath, setDynamicPath] = useState('/courses');
  const dynamicParams = "";
  const [formData, setFormData] = useState({
    name: '',
    code: '',
    description: '',
  });
 
  const handleAddCourse = async() => {
    
    const { submitSuccess, responseBody } = await submitForm('courses', formData);
    if (submitSuccess){
        setMessage(responseBody.message)
    }
    setFormData({
      name: '',
      code: '',
      description: '',
    })
    setIsAddCourseModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleEditCourse = (id) => {
    // Fetch the details of the selected course by its ID and set the editFormData state.
      setIsEditCourseModalOpen(true);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [id]: id,
      }));
  };
  const handleUpdateCourse = async () => {
    // Call your API to update the course details based on editFormData.
    // You can use a similar approach as handleAddCourse.
    const { submitSuccess, responseBody } = await submitForm(`courses/available-courses/${selectedCourse}`, formData);
    if (submitSuccess) {
      
        setFormData({
          name: '',
          code: '',
          description: '',
        })

      setMessage(responseBody.message);
      setIsEditCourseModalOpen(false);
    }
  };


  useEffect(() => {
    fetchData(dynamicPath, dynamicParams);
  }, [dynamicPath]);

  const headers = [
    { label: 'Title', key: 'name' },
    { label: 'Course Code', key: 'code' },
    { label: 'Description', key: 'description' },
  ];

  const buttons = [
    {
      label: 'Edit',
      onClick: (id) => handleEditCourse(id),
      color: 'darkblue'
    },
  ];

  return (
    <DashboardLayout sidebar="one">
      <Box borderRadius={11} px={2} py={5} width={['120%', '100%']}>
        <Box
          bg="white"
          borderRadius={11}
          mt={2}
          p={10}
          width={['200', '100%']}
          height={['100%', '100%']}
        >
            <Text>Add a new Course</Text>

            <Box 
            display={'flex'}
            justifyContent="right">
            <Button
                 
                 bg={`${configs?.primaryBg}`}
                 color={`${configs?.primaryColor}`}
                 mt={5}
                 
                 _hover={{ bg: '#1E40AF' }}
                 fontSize={12}
                onClick={() => setIsAddCourseModalOpen(true)}
                >
                Add Course
                </Button>
                </Box>
          <Box mt={2}>
                

            <DataTable
              data={courses?.data || []}
              headers={headers}
              buttons={buttons}
              page={courses?.currentPage}
              onPageChange={(newPage) =>  setDynamicPath(`/courses/${newPage}`)}
              pageSize={courses?.totalPages}
              totalItems={courses?.totalItems}
              loading={loading}
              search={true}
              exports={true}
              pagination={true}
            />
          </Box>
        </Box>
      </Box>

      <Modal isOpen={isAddCourseModalOpen} onClose={() => setIsAddCourseModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={13}>Add New Course</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <FormControl>
              <FormLabel fontSize={13}>Course Name</FormLabel>
              <Input
                type="text"
                placeholder="Course Name"
                name="name"
                fontSize={13}
                onChange={handleInputChange}
              />
    
            </FormControl>

            <FormControl mt={4}>
              <FormLabel fontSize={13}>Description</FormLabel>
              <Input
                type="text"
                name="description"
                fontSize={13}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel fontSize={13}>Course Code</FormLabel>
              <Input
                type="text"
                name="code"
                fontSize={13}
                onChange={handleInputChange}
              />
            </FormControl>
            <Text color='green.500' fontSize={13}>{message}</Text>
          </ModalBody>
          <ModalFooter>
            <Button 
             bg={`${configs?.primaryBg}`}
                 color={`${configs?.primaryColor}`}
                   
                 _hover={{ bg: '#1E40AF' }}
                 fontSize={12} mr={3} onClick={handleAddCourse}>
              Submit
            </Button>
            <Button  fontSize={12} onClick={() => setIsAddCourseModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <Modal isOpen={isEditCourseModalOpen} onClose={() => setIsEditCourseModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={13}>Edit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <FormControl>
              <FormLabel fontSize={13}>Course Name</FormLabel>
              <Input
                type="text"
                placeholder="Course Name"
                name="name"
                fontSize={13}
                onChange={handleInputChange}
              />
    
            </FormControl>

            <FormControl mt={4}>
              <FormLabel fontSize={13}>Description</FormLabel>
              <Input
                type="text"
                name="description"
                fontSize={13}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel fontSize={13}>Course Code</FormLabel>
              <Input
                type="text"
                name="code"
                fontSize={13}
                onChange={handleInputChange}
              />
            </FormControl>
            <Text color='green.500' fontSize={13}>{message}</Text>
          </ModalBody>
          <ModalFooter>
            <Button  bg={`${configs?.primaryBg}`}
                 color={`${configs?.primaryColor}`}
                   
                 _hover={{ bg: '#1E40AF' }}
                 fontSize={12} mr={3} onClick={handleUpdateCourse}>
              Submit
            </Button>
            <Button  fontSize={12} onClick={() => setIsEditCourseModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DashboardLayout>
  );
};

export default CreateCourse;
