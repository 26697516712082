import { useEffect } from 'react';
import useFetch from './useFetch';

const useSchoolInfo = () => {
  const { data: sessions, loading: sessionsLoading, fetchData: fetchSessions } = useFetch('Auth API');
  const { data: programmes, loading: programmesLoading, fetchData: fetchProgrammes } = useFetch('Auth API');
  const { data: departments, loading: departmentsLoading, fetchData: fetchDepartments } = useFetch('Auth API');
  const { data: levels, loading: levelsLoading, fetchData: fetchLevels } = useFetch('Auth API');
  const { data: semesters, loading: semestersLoading, fetchData: fetchSemesters } = useFetch('Auth API');

  useEffect(() => {
    fetchSessions('/sessions/all'); 
    fetchProgrammes('/programmes/all'); 
    fetchDepartments('/courses/major/all'); 
    fetchLevels('/levels/all'); 
    fetchSemesters('/semesters/all')
  }, []);

  return {
    sessions,
    sessionsLoading,
    programmes,
    programmesLoading,
    departments,
    departmentsLoading,
    levels,
    levelsLoading,
    semesters,
    semestersLoading
  };
};

export default useSchoolInfo;
