import React, { useState, useEffect } from 'react';
import {Select, Box, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Input, FormControl, FormLabel } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import DataTable from '../components/DataTable';  // Replace with the actual path
import useFetch from '../hooks/useFetch';
import useFormSubmission from '../hooks/useFormSubmission';
import { useSelector } from 'react-redux';
const ApprovalPermissions = () => {
  const config = useSelector((state)=>state?.auth?.config)
  const { data, loading, error, fetchData } = useFetch('Auth API');
  const { data: roles, loading: rolLoad, error: roleErr, fetchData: fetchRoles } = useFetch('Auth API');
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const [dynamicPath, setDynamicPath] = useState("clearance/perm");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen2, setModalOpen2] = useState(false);
  const [message,setMessage] = useState("");
  const [formData, setFormData] = useState({});

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModal2 = () => {
    setModalOpen2(true);
  };

  const handleCloseModal2 = () => {
    setModalOpen2(false);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Update the state with the new form data
    if (name === "Permission") {
        const index = event.target.getAttribute("data-index"); // Get the index of the stage
        const updatedPermission = [...formData.Permission]; // Copy the existing Permission array
    
        // Update the stage object at the specified index
        updatedPermission[index] = { ...updatedPermission[index], value: value };
    
        // Update the state with the updated Permission array
        setFormData((prevFormData) => ({
          ...prevFormData,
          Permission: updatedPermission,
        }));
      } else {
        // For other fields, update the state as usual
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
  };
  const headers = [
    { label: 'Name', key: 'name' }
  ];
  const handleSubmit2 = async () => {
    
    const { submitSuccess, responseBody } = await submitForm(`clearance/perm/${formData?.id}`, formData);
    
    
    if (submitSuccess) {
      setMessage(responseBody?.data?.message)
   };
  }
  const handleSubmit = async () => {
    
    const { submitSuccess, responseBody } = await submitForm('clearance/perm', formData);
    
    
    if (submitSuccess) {
      setMessage(responseBody?.message)
   };
  }
  const handleEdit = (item) =>{
    setFormData((prevFormData) => ({
      ...prevFormData,
      id: item._id,
      name: item.name,
    
    }));
    
    handleOpenModal2()
  }
  const buttons = [
    {
      color: 'darkblue',
      label: 'Edit',
      onClick: (item) => handleEdit(item),
    }
  ];
  // Fetch roles when the component mounts
  useEffect(() => {
    fetchData(dynamicPath);
    fetchRoles('roles/all')
  }, [dynamicPath]);

  return (
    <DashboardLayout sidebar="two">
      <Box borderRadius={11} px={2} py={5} width={["120%", '100%']}>
        <Box
          bg="white"
          borderRadius={11}
          mt={2}
          p={5}
          width={['200', '100%']}
          height={['100%', '100%']}
        >
          <Text fontFamily="Kumbh Sans" fontSize="16px" fontWeight="500" lineHeight="20px" letterSpacing="0em" textAlign="left" color="#374151" mb={4}>
            Clearance Permission
          </Text>
          <Box display={'flex'} justifyContent={'flex-end'}>
          <Button onClick={handleOpenModal} fontSize={13} bg={config?.primaryBg}
          color={config?.primaryColor}
          >Create New</Button></Box>
          <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader fontSize={13}>Add Permission</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl>
                  <FormLabel fontSize={13}>Clearance Permission</FormLabel>
                  <Input 
                      name="name"
                      onChange={handleChange}
                      fontSize={13}
                />
                </FormControl>
                {submitError && <Text fontSize={13} color="red">{submitError}</Text>}
                {message && <Text fontSize={13} color="green">{message}</Text>}
              </ModalBody>
              <ModalFooter>
                <Button fontSize={13} mr={3} onClick={handleCloseModal}>
                  Close
                </Button>
                <Button onClick={handleSubmit} fontSize={13} bg={config?.primaryBg}
                  color={config?.primaryColor}>
                  Submit
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <DataTable
            data={data?.data}
            headers={headers}
            buttons={buttons}
            page={data?.currentPage}
            onPageChange={(newPage) => setDynamicPath(`clearance/perm/${newPage}`)}
            pageSize={data?.totalPage}
            totalItems={data?.totalItems}
            loading={loading}
            search={true}
            exports={true}
            pagination={true}
          />
        </Box>
        <Modal isOpen={isModalOpen2} onClose={handleCloseModal2}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader></ModalHeader>
              <ModalCloseButton />
              <ModalBody>
              <FormControl>
                  <FormLabel fontSize={13}>Name</FormLabel>
                <Input
                  fontSize={13}
                  value={formData?.name}
                  onChange={handleChange}
                />
                </FormControl>
                {submitError && <Text fontSize={13} color="red">{submitError}</Text>}
                {message && <Text fontSize={13} color="green">{message}</Text>}
              </ModalBody>
              <ModalFooter>
                <Button 
                fontSize={13}
                mr={3} onClick={handleCloseModal2}>
                  Close
                </Button>
                <Button onClick={handleSubmit2} 
                fontSize={13} bg={config?.primaryBg}
                color={config?.primaryColor}
                >
                  {isSubmitting ? '...isSubmitting' : 'Submit'}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
      </Box>
    </DashboardLayout>
  );
};

export default ApprovalPermissions;
