// NewsDetails.js
import React, { useEffect } from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Spacer from '../components/Spacer';

const NewsDetails = () => {
  const { data, fetchData } = useFetch();
  const configs = useSelector((state) => state.auth.config);
  const { id } = useParams();

  useEffect(() => {
    fetchData(`news/${id}`);
  }, [id]);

  return (
    <Box>
      <Header
        config={configs?.api + configs?.logoDark || '/images/loading.png'}
        bannerSrc={configs?.newsBg ? configs?.api + configs?.newsBg : configs?.api + '/uploads/image.png'}
        buttonText='Login'
        
        height="350px"
      >
        <Box
        width={'100%'}
        ml={['10px', "200px"]}
          
          justifyContent="center"
          alignItems="center"
          textAlign="left"

        >
          <Text
            color={`${configs?.primaryColor}`}
            fontSize="14"
            fontWeight="400"
            lineHeight="140%"
            mb={3}
            justifySelf={'center'}
          >
            {data?.data?.category?.name} {' '}    |   {' '}
            {new Date(data?.data?.createdAt)?.toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'long',
              year: 'numeric'
            })}
          </Text>
          <hr  justifySelf={'center'} style={{ background: '#3A4553', width: '40%', height: '0.887px' }}></hr>

          <Box justifySelf={'center'}>
            <Text
              mt={3}
              color={`${configs?.primaryColor}`}
              fontFamily="Kumbh Sans"
              fontSize="50px"
              fontWeight="600"
              lineHeight="64px"
              letterSpacing="-0.03em"
            >
              {data?.data?.title}
            </Text>
          </Box>
        </Box>
      </Header>

      <Spacer gap="500" />

      <Box px="25%">
        <Image 
        px={10}
        width={530}
        height={400}
        src={configs?.api + data?.data?.image} alt={data?.data?.title} justifySelf="center" display="flex" />

        {/* Use dangerouslySetInnerHTML to render HTML content */}
        <Box mt={5}> {data?.data?.content }</Box>
      </Box>

      <Footer config={configs?.logoDark} />
    </Box>
  );
};

export default NewsDetails;
