// ImageUpload.js
import React, { useState, useRef } from 'react';
import { Box, Text, Avatar, Button } from "@chakra-ui/react";
import { FaCloudUploadAlt } from 'react-icons/fa';

const ImageUpload = ({ setFormData }) => {
  const [profileImage, setProfileImage] = useState(null);
  const fileInputRef = useRef(null);
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    console.log(event.target.files)
    setProfileImage(file);

    // Create a new FormData object and set the "image" field
    const formData = new FormData();
    formData.append('image', file);

    // Update the form data using the provided setFormData function
    setFormData(formData);
  };

  const handleClickAvatar = () => {
    // Trigger the file input when the avatar is clicked
    fileInputRef.current.click();
  };

  return (
    <Box spacing={4} align="center" justifyContent={'center'} textAlign={'center'}>
      <Text fontSize="16px" fontWeight="500" color="#374151">Allowed Types: PDF, PNG,  JPEG, Max size: 2mb</Text>

      <Avatar
        size="2xl"
        src={profileImage ? URL.createObjectURL(profileImage) : ""}
        onClick={handleClickAvatar}
        cursor="pointer"
        mt={5}
      />

      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
        name="image" // Make sure the name matches the field name in your backend
      />

      <Text color="#929EAE" fontSize={12} mt={5}>Click on the photo to upload a new one</Text>

     
    </Box>
  );
};

export default ImageUpload;
