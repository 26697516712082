// SearchableSelect.js
import React, { useState } from 'react';
import { Select } from '@chakra-ui/react';

const SearchableSelect = ({ name, placeholder, data, onChange }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    
      <Select
        name={name}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        fontSize={13}
      >
        {filteredData.map((item) => (
          <option key={item.id} value={item?._id}>
            {item.name}  {item.code && (`(${item?.code})`)}
          </option>
        ))}
      </Select>
      
  );
};

export default SearchableSelect;
