// Dashboard.js
import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import ApplicationLink from '../components/ApplicationLink';

const Result = () => {
  
  return (
    <DashboardLayout sidebar={"two"}>
       <Box borderRadius={11} px={2} py={5} width={["120%", '100%' ]}>
      <Box 
        bg="white" 
        borderRadius={11} 
        mt={2} 
        p={5}  
        width={['200','100%']}
        height={['100%', '80vh']}
        display={'flex'}
      >
        <SimpleGrid
          height={['100%', '50%']}
          mt={5}
          flexDirection={['row','column']}
          columns={[1, 4]} // Set the number of columns based on your design
          spacing={4}
          gap={5}
          px={{ base: 10, md: 50 }}
          justifyContent={'center'}
        >
            <ApplicationLink
              link={'/result/view'}
              icon={'menu-document-text.svg'}
              title='View Result'
              ></ApplicationLink>
                <ApplicationLink
              link={'/result/appeal'}
              icon={'menu-receipt-edit.svg'}
              title='Appeal Result'
              ></ApplicationLink>
              
        
          </SimpleGrid>
        </Box>
    </Box>  
    </DashboardLayout>
  );
};

export default Result;
