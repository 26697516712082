import React from 'react';
import { GridItem, SimpleGrid, Box, Text, Image, Progress } from '@chakra-ui/react';
import { MdChevronRight } from 'react-icons/md';
import { useSelector } from 'react-redux';

const CustomCard = ({ title, description, progress, value }) => {
  const configs = useSelector((state)=>state.auth.config)
  return (
    <GridItem bg={configs?.primaryColor} borderRadius="8.56px" padding={5}>
      <SimpleGrid 
          columns={2} alignContent="center" 
          justifyContent="center" display="flex"
          gap={0}
        >
        <Box
          bg="#F5F7F9"
          borderRadius="50%"
          p={3}
          w="75px"
          h="50px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Image src="/icons/document-unfilled.svg" width="250px" />
        </Box>
        <Box textAlign="left" justifyContent="center" display="flex" flexDirection="column" pl={2}>
          <Text color="#1B212D" fontWeight={700} lineHeight={4} fontSize={16}>
            {title}
          </Text>
          <Text color="#929EAE" fontSize={10} lineHeight={2}>
            {description}
          </Text>
        </Box>
      </SimpleGrid>

      <Box>
        <SimpleGrid columns={2} display="flex" justifyContent="space-between">
          <Text fontSize={15} fontWeight={500} alignItems="flex-start" mt={2} ml={2}>
            N{value?.toLocaleString()} 
          </Text>
          <Box display="flex" alignItems="center">
            <MdChevronRight color="#9AA9BB" size="35px" />
          </Box>
        </SimpleGrid>

        {/* <Progress value={progress} borderRadius={10} size="md" colorScheme="teal" /> */}
      </Box>
    </GridItem>
  );
};

export default CustomCard;
