import React, { useState } from 'react';
import { Box, Button, Grid, Spinner, Text } from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import Step1 from '../components/Step1';
import useFormSubmission from '../hooks/useFormSubmission';
import { useSelector } from 'react-redux';

const StudentBiodata = () => {
  const [formData, setFormData] = useState({});
  const [success, setSuccess] = useState();
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const configs = useSelector((state)=> state.auth.config)
  const handleSubmit = async () => {

    const submissionResult = await submitForm("applications/biodataByUser", formData, "json");
    
    if (submissionResult.submitSuccess) {
      setSuccess(submissionResult.responseBody.message)
    }
  };

  return (
    <DashboardLayout sidebar={"two"}>
      <Box borderRadius={11} px={2} py={5} width={["120%", '100%']}>
        <Box
          bg="white"
          borderRadius={11}
          mt={2}
          p={10}
          width={['200', '100%']}
          height={'100%'}
        >
          <Step1 formData={formData} setFormData={setFormData} />
          <Grid templateColumns={["1fr","repeat(3, 1fr)" ]} gap={4}>
            <Box>
              {submitError && <Text color="red.500">{submitError}</Text>}
              {success && <Text color="green.500">{success}</Text>}
            
              {isSubmitting && <Spinner />}
            </Box>
            <Box textAlign="right" justifyContent={'flex-end'} alignItems={'flex-end'}>
              <Button
                // display="flex"
                bg={`${configs?.primaryBg}`}
                fontSize={14}
                mt={5}
                // flexDirection="row"  // Adjusted to 'row'
                color={`${configs?.primaryColor}`}
                onClick={handleSubmit}
              >
                Update
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default StudentBiodata;
