import { useState } from 'react';
import useApiUrls from './useApiUrls';
import { useSelector } from 'react-redux'; // Import useSelector from react-redux

const useFetch = (apiName) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getUrlByName } = useApiUrls();
  const token = useSelector((state) => state.auth.token); // Retrieve the token from Redux state

  const defaultApiName = 'Auth API';
  const baseURL = getUrlByName(apiName || defaultApiName);
 
  const fetchData = async (path = '/', params = {}) => {
    const url = new URL(path, baseURL);

    if (Object.keys(params).length > 0) {
      Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
    }

    try {
      const response = await fetch(url.toString(), { headers: {'Authorization': `Bearer ${token}`}});
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setData(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchData };
};

export default useFetch;
