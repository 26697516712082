// Dashboard.js
import React, {useEffect, useState} from 'react';
import { Box, SimpleGrid, FormLabel, FormControl, Select, Button, Center, Text } from '@chakra-ui/react';
import TopBar from '../components/TopBar';
import useFormSubmission from '../hooks/useFormSubmission';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import useFetch from '../hooks/useFetch';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
const NewApplication = () => {
  const navigate = useNavigate(); // Create a navigate function
  const configs = useSelector((state)=> state.auth.config)
  const { isSubmitting, submitError, submitForm } = useFormSubmission("Auth API");
  const { data: programmesData, loading: programmesLoading, error: programmesError, fetchData: fetchProgrammes } = useFetch("Auth API");
  const { data: sessionData, loading: sessionLoading, error: sessionError, fetchData: fetchSession } = useFetch("Auth API");
  const [coursesData, setCoursesData] = useState();
  const [selectedProgramme, setSelectedProgramme] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSession, setSelectedSession] = useState('');

  useEffect(() => {
    // Fetch programmes when the component mounts
    fetchProgrammes('programmes/all');
    fetchSession('sessions/all');
    
  }, []);

  const handleProgrammeChange = async (programmeId) => {

    const selectedProgrammeData = programmesData?.find((programme) => programme?._id === programmeId);
    setCoursesData(selectedProgrammeData?.availableCourses)
    setSelectedProgramme(programmeId); 

  };


  const handleFormSubmit = async () => {
    
    const formData = {
      programme: selectedProgramme,
      course: selectedCourse,
      session: selectedSession
    };

    // Submit the form with the selected values
    const { submitSuccess, responseBody } = await submitForm('applications', formData, 'json');

    if (submitSuccess) {
      navigate(`/application/biodata/${responseBody?.id}`);
    } else {
      console.error(submitError || 'Form submission failed');
    }
  };

  return (
    <Box bg="#F6F6FE" height={'100vh'}>
      <TopBar type={"normal"} />
      <Center>
    
        <Box display={'flex'}
        minHeight={'70vh'} flexDirection={'column'} bg="white" 
        borderRadius={11} mt={17} width="75%">
       
          <form>
         
            <SimpleGrid
              display={'flex'}
              flexDirection={{ base: 'column', md: 'column' }}
              columns={[1, 1, 1]}
              spacing={4}
              px={{ base: 10, md: 50 }}
              justifyContent={'center'}
              mt={10}
            >
               <Box>
          <Link to='/dashboard/application' ml={10}> Back </Link>
          </Box>
             <FormControl mt={2}>
          <FormLabel fontSize={13}>Select Programme</FormLabel>
          <Select fontSize={13} onChange={(e) => handleProgrammeChange(e.target.value)}>
            <option value="">Select Programme</option>
            {programmesData &&
              programmesData?.map((programme) => (
                <option key={programme?._id} value={programme?._id}>
                  {programme?.name}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl mt={2}>
          <FormLabel fontSize={13}>Select Course</FormLabel>
          <Select fontSize={13} value={selectedCourse} onChange={(e) => setSelectedCourse(e.target.value)}>
            <option value="">Select Course</option>
            {coursesData &&
              coursesData?.map((course) => (
                <option key={course?._id} value={course?._id}>
                  {course?.name}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl mt={2}>
          <FormLabel fontSize={13}>Select Session</FormLabel>
          <Select fontSize={13} value={selectedSession} onChange={(e) => setSelectedSession(e.target.value)}>
            <option value="">Select Session</option>
            {sessionData &&
              sessionData?.map((session) => (
                <option key={session?._id} value={session?._id}>
                  {session?.name}
                </option>
              ))}
          </Select>
        </FormControl>
        <Text color="red" fontSize={12}>{submitError}</Text>
              <Box
                display={'flex'}
                justifyContent={'flex-end'}
                alignItems={'flex-end'}
              >
                <Button
                  width={["100%", "30%"]}
                  mt={5}
                  height={10}
                  fontSize={13}
                  bg={`${configs?.primaryBg}`}
                  color={`${configs?.primaryColor}`}
                  onClick={handleFormSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Submitting...' : 'Proceed'}
                </Button>
              </Box>
            </SimpleGrid>
          </form>
        </Box>
      </Center>
    </Box>
  );
};

export default NewApplication;
