// DashboardLayout.js
import React, { useState, useEffect } from 'react';
import { Box, Flex, useMediaQuery, useBreakpointValue, Image } from '@chakra-ui/react';
import Sidebar from './Sidebar';
import menuItems from './MenuItems';
import TopBar from './TopBar';
import SideCard from './SideCard';
import { useSelector } from 'react-redux';
import AdminSideCard from './AdminSideCard';
import usePercentageCompletion from '../hooks/getPercentage';
import { useNavigate } from 'react-router-dom';
import useFetch from '../hooks/useFetch';

const DashboardLayout = ({ children, sidebar }) => {

  const navigate = useNavigate()
  const configs = useSelector((state) => state.auth.config);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  
  const percentage1 = usePercentageCompletion(new Date(configs?.currentSession?.startDate), new Date(configs?.currentSession?.endDate));
  const percentage2 = usePercentageCompletion(new Date(configs?.currentSemester?.startDate), new Date(configs?.currentSemester?.endDate));
  const percentage3 = usePercentageCompletion(new Date(configs?.currentSemester?.startDate), new Date(configs?.registration));
 
  const { data: clearanceCount, loading, error, fetchData } = useFetch();
  const { data: clearanceCount2, loading: loading2, error: error2, fetchData: fetchData2 } = useFetch();

  

  const [isSmallerThan768] = useMediaQuery('(max-width: 768px)');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const userRole = useSelector((state) => state?.auth?.user?.role?.name);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const isMobile = useBreakpointValue({ base: true, lg: false });
  
  useEffect(()=>{
    fetchData("clearance/counting/admission")
    fetchData2("clearance/counting/graduation")
  }, [])
  return (
  
    <Box bg="#F6F6FE" minHeight={["100%", "100vh"]} w={"100%"}>
   { isAuthenticated ?
      <Flex direction={isSmallerThan768 ? 'column' : sidebar =="two"? 'row' : 'flex'}>
        {isMobile ? (
          <>
            <TopBar onToggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} mb={100} />
            <Flex className='hidden-section'>
              {((sidebar == "one") || (sidebar == "two")) &&(<Sidebar isSidebarOpen={isSidebarOpen} menuItems={menuItems} />)}
            </Flex>
          </>
        ) : (
          <>{((sidebar == "one") || (sidebar == "two")) && userRole !=="Applicant" &&(<Sidebar isSidebarOpen={isSidebarOpen} menuItems={menuItems}  />)}</>
        )}
    
        <Box w={'-webkit-fill-available'} >  {!isMobile && (
          <TopBar onToggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
        )}
        <Box px={userRole ==="Applicant" &&("10%")} display={'flex'} flexDirection={['column', 'row']} justifyContent={userRole ==="Applicant" && "center"}>
        {!isMobile && ((sidebar == "one") || (sidebar == "two")) && userRole ==="Applicant" &&(<Sidebar isSidebarOpen={isSidebarOpen} menuItems={menuItems}  />)}
          <Box  
            p={isMobile ? '1rem' : '0.8rem 0.8rem 0.8rem'} 
            width={userRole ==="Applicant" ? "100%" : sidebar == "one" ? "100%" : isMobile ? "100%" : "75%"}>
            {children}
          </Box>

          {(!isMobile && sidebar == "two") && (
            <Box  className='hidden-section' bg="white" color="#111827" w={isSmallerThan768 ? '100%' : '25%'} p={1} >
              
              { userRole == "Student" ?  (<Box textAlign={'left'}>
                  
                  <SideCard 
                    title="Current session progress"
                    progress={percentage1?.percentage}
                    value={percentage1?.daysLeft}
                  />

                  <SideCard 
                    title="Current semester progress"
                    progress={percentage2?.percentage}
                    value={percentage2?.daysLeft}
                  />

                  <SideCard 
                    title="Registration deadline"
                    progress={percentage3?.percentage}
                    value={percentage3?.daysLeft}
                  />

                  {/* <Image src="/images/sidebar.png" p={2} /> */}

                </Box>) : (  <Box textAlign={'left'} p={4}>
                  <AdminSideCard src={'admin-enrolment.svg'} label={'Graduation Clearance'} count={clearanceCount2}/>
                  <AdminSideCard src={'admin-loader-outline.svg'} label={'Admission Clearance'} count={clearanceCount}/>
                  <AdminSideCard src={'admin-receipt-edit.svg'} label={'Student Complaint'} count={0}/>
                  <AdminSideCard src={'admin-messages.svg'} label={'New Messages'} count={0}/>
                  {/* <Image src="/images/sidebar.png"  /> */}
              </Box>)}
            </Box>
          )}
        </Box>
      </Box>
    </Flex>: navigate('/login')}
  </Box>
  );
};

export default DashboardLayout;
