import React from 'react';
import { Box } from '@chakra-ui/react';
import { MdChevronRight } from 'react-icons/md';
import CustomCard from './CustomCard';

const ScrollableCard = ({ data, loading}) => {
  return (
    <Box
      maxH="400px"
      overflowX="scroll"
      mt={10}
      // width="120%"
      whiteSpace="nowrap"
      display="inline-flex"
      flexDirection={['column', 'row']}
      gap={5}
      overflowY="hidden"
      snapType={['none', 'x mandatory']}
      alignItems="center"
      css={{ '::-webkit-scrollbar': { width: '12px' } }}
    >
      { data?.map((item)=>(
      <CustomCard
        title={item?.status}
        description={item.description}
        progress={30}
        value={item.amount}
      />))}
     
    </Box>
  );
};

export default ScrollableCard;
