import authService from './authService';

export const login = (credentials) => async (dispatch) => {
  try {
    const authServiceInstance = authService();
    
    const user = await authServiceInstance.login(credentials);
   console.log(user)
    const data = await authServiceInstance.user(user.access_token)
    console.log(data)
    dispatch(loginSuccess({ user: data, token: user.access_token}));
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};

export const config = () => async (dispatch) => {
  try {
    const authServiceInstance = authService();
    const config = await authServiceInstance.config();
    dispatch(configType(config));
  } catch (error) {
    // dispatch(loginFailure(error.message));
  }
}

export const logout = () => ({ type: 'LOGOUT' });

const loginSuccess = (user) => ({
  type: 'LOGIN_SUCCESS',
  payload: user,
});

const configType = (config) => ({
  type: 'NEUTRAL',
  config: config,
});
const loginFailure = (error) => ({
  type: 'LOGIN_FAILURE',
  payload: error,
});
