// Dashboard.js
import React from 'react';
import { Box, SimpleGrid, Text, Center, Image } from '@chakra-ui/react';
import TopBar from '../components/TopBar';
import ApplicationLink from '../components/ApplicationLink';
import { useSelector, useDispatch } from 'react-redux';

const ApplicationDashboard = () => {
  const firstName = useSelector((state) => state?.auth?.user?.firstName);

  return (
    <Box bg="#F6F6FE" minHeight={'100vh'}>
      <TopBar type={"normal"} />
      <Center height={'50vh'}>
        <Box display={'flex'}  height={'100%'} flexDirection={'column'} bg="white" borderRadius={11} mt={17}  width="75%" >
          <Text
            fontFamily="Kumbh Sans"
            fontSize="15px"
            mt={10}
            fontWeight="600"
            lineHeight="12px"
            letterSpacing="0em"
            mb={3}
            textAlign="center"
          >Welcome, {firstName}</Text>
          <Text
            fontFamily="Kumbh Sans"
            fontSize="12px"
            fontWeight="600"
            lineHeight="12px"
            letterSpacing="0em"
            mb={8}
            textAlign="center"
          >What would you like to do?</Text>
          <SimpleGrid display={'flex'} flexDirection={{ base: 'column', md:'row'}} columns={[1, 1, 3]} spacing={4} px={{base: 10, md: 50}} justifyContent={'center'} >
            
          <ApplicationLink
              link={'/new'}
              icon={'dashboard_refresh.svg'}
              title='Start a Fresh Application'
              ></ApplicationLink>
         
         <ApplicationLink
              link={'/applications-list'}
              icon={'dashboard_document.svg'}
              title='Continue With Old Application'
              ></ApplicationLink>

      <ApplicationLink
              link={'/programmes'}
              icon={'dashboard_teacher.svg'}
              title='View Available Programmes'
              ></ApplicationLink>

          </SimpleGrid>
        </Box>
      </Center>
    </Box>
  );
};

export default ApplicationDashboard;
